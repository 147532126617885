#creator_settings_body
  .page_body
    min-height 80vh
  .feed_container
    display flex
    flex-wrap wrap
    justify-content space-around
    max-width 1500px
    margin 50px auto 0
    width 80%

    @media screen and (max-width: 600px)
      margin 0
      width 100%

    feed_card(0px)

    .text
      .description
        color #4e5155

    @media screen and (max-width: 600px)
      .feed_card
        position relative
        margin-bottom 20px
        & + .feed_card
          &:before
            display block
            content ''
            margin-bottom 20px
            border-top 1px solid #eeeeee
        .text
          padding 0
          word-break keep-all
          .title
            margin 0 0 5px 0
            font-size 16px
            font-weight 500
          .description
            line-height 1.5

