#creator_settings_header
  padding 0 24px 0 24px
  border-bottom 1px solid #f2f2f2
  background-color white
  @media screen and (max-width: 1024px)
    padding 0 20px 0 20px
  @media screen and (max-width: 600px)
    padding 0 20px 0 17px

  .toggle-menu
    display none
    .btn
      position absolute
      top 50%
      left 0
      font-size 24px
      width 24px
      height 24px
      color #000
      transform translateY(-50%)

  .content
    display flex
    flex-direction row
    justify-content space-between
    align-items center
    @media screen and (max-width: 1024px)
      position relative
      justify-content center
      .toggle-menu
        display block

  #logo
    display flex
    align-items center
    height 67px
    div
      display flex
      align-items center
      gap 8px
      a
        display inline-flex
    h1
      display flex
      align-items center
      padding 0
      font-size 20px
      font-weight 500
      gap 8px
      a
        height 28px
        color #000
      i
        display block
        width 18px
        height 18px
        margin-right 10px
        img
          width 100%
    //@media screen and (max-width: 600px)
    //  height 50px

  .controls
    display flex
    flex-direction row
    justify-content flex-end
    align-items center
    z-index 1
    @media screen and (max-width: 1024px)
      position absolute
      top 50%
      right 0
      transform translateY(-50%)
    .remain-timer
      color #999
      .lnr
        display inline-block
        padding 0
        width 15px
        height 15px
        font-size 15px
        margin-right 10px
        background transparent
        border none
        transform rotate(-30deg)
        outline none
    > .divider
      width 1px
      height 14px
      background #f2f2f2
      margin 0 10px
    .dropdown.user
    .dropdown.lang
      .dropdown-anchor
        width 24px
        height 24px
        padding 0
        .user
          display flex
          justify-content center
          align-items center
          height 100%
          background #eee
          border-radius 50%
          overflow hidden
          .thumbnail
            width 100%
            height 100%
            img
              width 100%
              height 100%
              object-fit cover
          .initial
            height 15px
            vertical-align middle
            font-weight bolder
      .dropdown-list
        top calc(100% + 12px)
      .dropdown-list-item
        .flag
          margin-right 10px
