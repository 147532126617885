.pagination-mp
  display flex
  justify-content center
  align-items center
  margin 10px 0
  list-style none
  box-sizing initial !important
  *
    box-sizing initial !important
  .pagination-item
    a
      color #828282
      font-size 16px
      font-weight 600
      display block
      padding 9px 9px 6px
      height 18px
      min-width 15px
      text-align center
      &:hover
        z-index: 2;
        color #000
      &.disabled
        color: #d1d2d3;
        pointer-events: none;
        cursor: auto;
        background-color: rgba(0,0,0,0);
        border-color: rgba(24,28,33,0.06);
      &.current
        background-color: #f7f7f7;
        color: #000;
        border-radius 16.5px
    &[data-page_type]
      a
        background-repeat no-repeat
        background-size 8px 12px
        background-position center
    &[data-page_type="prev"]
      a
        background-image url("//s3.marpple.co/files/u_29089/2020/11/original/e3d6d615c8155f47af684cdedb4d87151d2f0e421.png")
        &.disabled
          background-image url("//s3.marpple.co/files/u_29089/2020/11/original/4ade5b4186472dd521691e2272c1bea38a856e621.png")
    &[data-page_type="next"]
      a
        background-image url("//s3.marpple.co/files/u_29089/2020/11/original/0aa2d8ac7a63447c139e0f5d040fddf1af70a2fc1.png")
        &.disabled
          background-image url("//s3.marpple.co/files/u_29089/2020/11/original/c583f178e87219dd57a044095f3f6b93e42b9efc2.png")
