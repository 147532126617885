#creator_settings_body
  display flex
  flex-direction column
  font-size 14px
  width calc(100% - 200px)
  padding 0 40px
  border-left 1px solid #f2f2f2;
  html[is_mobile="false"] &
    min-width 1280px

  @media screen and (max-width: 1480px)
    width calc(100% - 210px)
    padding 0 20px

  @media screen and (max-width: 1024px)
    width 100%
    min-height calc(100vh - 68px)
    justify-content space-between
    min-width unset !important

  .shopify &
    width 100%

  .settings-content
    display flex
    flex-direction column
    min-height: calc(100vh - 146px);
    @media screen and (max-width: 1024px)
      //min-height calc(100vh - 186px)
      min-height calc(100vh - 88px) // 푸터 패딩 값 추가
    > .header
      position relative
      margin 40px 0
      .real-pb-setting-title
        align-self flex-start
      &.flex
        display flex
        justify-content space-between
        align-items flex-end
        .right
          display flex
          align-items center
          > *
            margin-left 30px
      .icon-guide
        display inline-block
        margin-left 10px
        cursor pointer
        img
          width 25px
          vertical-align middle
          margin-bottom 5px
      p
        margin 0
        color #4c4c4c
      .btn
        .lnr
          display inline-block
          margin-right 10px
          transform translateY(1px)
      .options
        position absolute
        right 0
        bottom 0

      @media screen and (max-width: 1024px)
        margin 30px 0 20px 0
        &.flex
          .right
            > *
              margin-left 20px
    @media screen and (max-width: 600px)
      > .header
        &.flex
          //align-items flex-start
          //flex-direction column
          flex-wrap wrap
          .btn
            padding 7px 20px 5px
            font-size 12px
            font-weight 400
          .left
            width 100%
          .right
            margin-top 15px
            width 100%
            flex-direction row-reverse
            justify-content flex-end
            align-items center
            > *
              margin-left 0
            .btn
              margin-right 10px
    > .body
      flex 1
      margin 0
      .warning-suspended
        width 100%
        height 72vh
        display flex
        flex-direction column
        justify-content center
        align-items center
        text-align center
        background-color white
        img
          width 143px
        p
          width 100%
          margin 16px 0 0
          font-size 26px
          font-weight 600
          line-height 42px

  .settings-footer
    padding 33px 0 30px
    font-size 12px
    color #999
    display flex
    justify-content space-between
    align-items flex-end
    flex-wrap wrap
    .left
      span
        margin-right 20px
      span:last-child
        margin-top 10px
    .right
      position relative
      span
        white-space nowrap
    a₩
      font-weight bold
    @media screen and (max-width: 1024px)
      padding 40px 0 20px
      .language-selector
        position absolute
        bottom -12px
        right 60px
        .dropdown-anchor
          padding 10px
        .dropdown-list
          top auto
          bottom 100%
          right 10px
          .flag
            margin-right 10px
      .left
        margin-bottom 5px
        span
          display inline-block
        span:last-child
          margin-right 0
      .right
        width 100%
        margin-top 10px
    @media screen and (max-width: 600px)
      font-size 11px

  // 페이지별 스타일 임포트
  &.setting-products
  &.setting-custom-asset-products
  &.setting-custom-user-products
  &.setting-pb-products
  &.setting-digital-products
    @import './product.styl'

  &.setting-styles
    .settings-content
      >.body
        position relative
        border 1px solid #f2f2f2
        iframe
          width 100%
          height 100%
