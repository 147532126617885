@import 'helper.styl'

&
  .product-editor
    display flex
    min-height 100%
    border-bottom 1px solid #ededed
    background #fff

    .form-item
      > label + label
        margin-left 20px
      input[type="radio"]
      input[type="checkbox"]
        margin 0 10px 0 0

    @media screen and (max-width 1024px)
      flex-direction column
      input
        appearance: auto;
        -moz-appearance: auto;
        -webkit-appearance: auto;

  .form
    width 45.609756097560976%;
    min-width 620px
    min-height 600px
    padding 0
    margin 30px 40px
    background #ffffff
    color #000000

    @media screen and (max-width 1024px)
      width auto
      min-width 0
      margin 0 20px 20px

    &[data-editable="false"]
      .form-item
        input
        .customer_price
          color #999999
          background-color #f5f5f5
        &.price-list .table table
          background-color #f5f5f5
          th
          td
            color #999999

    .form-item
      margin-top 0
      &[data-item_name="primary_thumb"] > label
      &[data-item_name="public"] > label
      &[data-item_name="shipping_date_visibility"] > label
      &[data-item_name="limited_edition"] > label
      &[data-item_name="is_quantity_public"] > label
        display inline-flex
        justify-content center
        align-items center
        > input[type="radio"]
        > input[type="checkbox"]
          width 14px
          height 14px
          margin-left 1px
          margin-top 0
          margin-bottom 0
        @media screen and (max-width 1024px)
          > input[type="radio"]
          > input[type="checkbox"]
            margin-left 0
            transform translateY(-1px)
          & + label
            margin-left 20px
      [name="description"]
        resize vertical
      @media screen and (max-width 1024px)
        &.names .title,
        &[data-item_name="colors"] .title
          flex-wrap wrap
          small
            margin-left 0
        &:not(:first-child)
          margin 40px 0
        &[data-item_name="colors"]
          .title
            margin-bottom 20px
          .color-option-list
            margin-top -24px
        &[data-limited_sale_type="limited_edition"]
          margin 40px 0 20px
        &[data-limited_sale_type="period"]
          margin 30px 0 20px
        &[data-limited_sale_type="quantity"]
          margin 20px 0


      &.names
        input
          width 32%
          margin-right 2%
          display inline-block
          vertical-align top
          &:last-child
            margin-right 0
        @media screen and (max-width 1024px)
          input
            width 100%
            margin-right 0
            display block
            vertical-align top
            padding-left 35px
            background-position 9px center
            background-repeat no-repeat
            background-size 18px
          input[name="name"]
            flag_kr()
          input[name="name_en"]
            flag_en()
          input[name="name_jp"]
            flag_jp()
          input + input
            margin-top 10px

      select
        margin-bottom 6px

      .terms
        plain-list()
        list-style-type disc
        padding 10px 20px 10px 30px
        border 1px solid #ededed
        border-radius 2px
        margin-bottom 12px
        line-height 1.57
        & + label
          display flex
          justify-content flex-start
          align-items center
          cursor pointer
          [name="agreed_to_terms"]
            margin 0
            margin-right 4px
      .align-center-flex
        display flex
        align-items center
    .buttons
      position relative
      margin-bottom 30px
      display flex
      align-items center
      justify-content space-between
      @media screen and (max-width 1024px)
        position fixed
        bottom 0
        left 0
        width 100%
        margin-bottom 0
        z-index 10
        &:before
          display none
          content ''
          position fixed
          left 0
          top 0
          right 0
          bottom 0
          background rgba(0, 0, 0, 0.3)
          z-index 5
        &[is_open="true"]:before
          display block
        .more-buttons
          position fixed
          top 50%
          left 50%
          right auto
          width 90%
          max-width 295px
          padding 0
          transform translate(-50%, -50%)
          > a, > button
            padding 16px 20px
            width 100%
            height 48px
            font-size 14px
            font-weight normal
            text-align center

      .mp-shop-product-status__item
        width: 112px
        height 40px
        font-size 14px
        display flex
        justify-content center
        align-items center
        border-radius 2px
        .mp-shop-product-status-tooltip
          display none
        &:before
          content ''
          display block
          background-repeat no-repeat
          background-size 20px 20px
          width 20px
          height 20px
          margin-top -1px
          margin-right 6px
        &[data-status="public"]
          box-shadow 0 0 0 1px #5eda42 inset
          color #5eda42
          &:before
            background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/0cdf2841d33902b37040f20e3bcc57cadb2968441.png)
        &[data-status="private"]
          box-shadow 0 0 0 1px #b2b2b2 inset
          color #b2b2b2
          &:before
            background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/c4278bcd4c48c7b216f7af22a13e3a7416f71bf31.png)
        &[data-status="not_use_product"]
        &[data-status="prohibited"]
        &[data-status="not_confirmed"]
          box-shadow 0 0 0 1px #ff8970 inset
          color #f86041
          &:before
            background-image url(//s3.marpple.co/files/u_29089/2020/11/original/cebbdc422aa6a293f8c327f16cf1132f1e5c1fec1.png)
        &[data-status="checking"]
          box-shadow 0 0 0 1px #3c89f9 inset
          color #3c89f9
          &:before
            background-image url(//s3.marpple.co/files/u_1093672/2021/3/original/fbd009d20a6e7e9fab8e067f4d7cab53569d15dd1.png)
      > .btn-clone
        width 98px
        height 40px
      .btn-go-admin
        display flex
        width 157px
        height 40px
        padding 0
        justify-content center
        align-items center
        line-height 1
        font-size 14px
        font-weight 600
        > img
          width 66px
          margin-right 4px
      .btn-export
        display flex
        align-items center
        > img
          width 66px
          margin-right 4px

      .btn-submit
        border-radius 2px
        &.has-icon
          height 40px
          display flex
          justify-content center
          align-items center
          line-height 1
          @media screen and (max-width 1024px)
            flex 1
            margin 0
            border-radius 0
            html.safe_area_inset_bottom &
              box-sizing content-box
              padding-bottom 34px
          > img
            width 12px
            margin-right 10px

      .btn-group-right
        display flex
        align-items center
        .btn-reset
          margin-right 11px
        .btn-submit
          margin-right 10px
        @media screen and (max-width 1024px)
          position fixed
          left 0
          bottom 0
          right 0
          padding 30px 20px 20px
          justify-content space-between
          background rgb(255,255,255)
          background linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.95) 70%, rgba(255,255,255,0.7) 94%, rgba(255,255,255,0) 100%)
          .btn-reset
            border: solid 1px #d5dbe0;
          .btn-reset, .btn-submit
            flex 1
            height 40px
            margin-right 10px
          .btn-more
            width 42px
            img
              height 40px
              width auto

    .title
      i
        font-weight normal
        font-style normal
        margin-left 6px
        color #666
        font-size 12px

    .form-group[data-group_name="price"],
    .form-group[data-group_name="profit_share"]
      display flex
      justify-content space-between
      align-items center
      margin-bottom 20px
      .form-item + .form-item
        margin-top 0
      .form-item .title
        padding 0
      > *
        width calc(50% - 5px)
        margin-bottom 0
    .form-group--vertical[data-group_name="price"]
      display flex
      flex-direction column
      align-items start
      gap 24px/
      > *
        width 100%
        margin 0

    .title.has-tooltip
      position relative
      display flex
      align-items center

      .tooltip-icon
        background-image url("//s3.marpple.co/files/u_1093672/2020/9/original/7b88b4654d6545661c60af72c10d3804749854221.png")
        background-size contain
        width 16px
        height 16px
        margin-left 5px
        &:hover
          background-image url("//s3.marpple.co/files/u_1093672/2020/9/original/8ab18cdaad2bce5011f094ece4787e8c7107c8ac2.png")

    .title.has-link
      position relative
      display flex
      justify-content space-between
      align-items center

      .title-link
        color inherit
        text-decoration underline

    .read_only_price
    .customer_price
      width 100%
      height: 40px;
      padding 9px 10px
      border-radius: 2px;
      border: solid 1px #ededed;
      background-color: #f5f5f5;

    .price-list
      .table
        border: solid 1px #ededed;
      table
        width 100%
        background-color: #ffffff;
        border-radius 2px
        text-align center
        padding 9px 10px

        th
        td
          font-size: 12px;
          font-weight: 400;
          line-height 1.83;
          letter-spacing: -0.5px;
          color: #000000;

        thead > tr > th
          font-weight: 600;
          padding-bottom 8px

        tbody > tr > *
          line-height 2

        tr > *
          width 28.902627511591963%;
          &:nth-child(1)
            width 13.292117465224111%

        &[data-target="company"]
          tr > *
            width auto;

      @media screen and (max-width 1024px)
        .table
          overflow-x auto

        table
          th, td
            white-space nowrap
            padding 0 10px
    .add-option
      position relative
      button
        padding 0.3rem .6rem;
        font-size 12px
      #add_option_button
        position absolute
        right 0
        top -42px
    .add-option-inputs
      position relative
      .add-option-product-input
        position absolute
        top -42px
        left -36px
        right 0
        border 1px solid #eee;
        border-radius 5px
        padding 24px
        background #ddd
        box-shadow 0 10px 30px 0 rgba(24,28,33,0.2)
        z-index 1
    .product_options
      margin-top 14px
      .product_option
        display flex
        justify-content space-around
        align-items center
        margin-bottom 8px
        padding 8px 12px
        background #fff
        border 1px solid #eee;
        border-radius 5px
        .sorting
          width 18px
          height 15px
          margin-right 12px
          cursor grab
          img
            width 100%
        .option-name
          flex-grow 1
          font-size 12px
        &[data-is_sold_out="true"]
          text-decoration line-through
        span
          margin-right 4px

  .color-selector
    position relative
    width 100%
    height 40px
    border-radius 2px
    border solid 1px #ededed
    .selected-item
      display flex
      align-items center
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      z-index: 0;
      padding 0 9px
      cursor pointer
      .icon-dropdown
        width 18px
      .color-name
        flex-grow 1

    select
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      opacity 0;
      z-index 1
      cursor pointer


  .sales-periods
    plain-list()
    padding 4px 0 0
    width 384px
    > *
      margin-bottom 6px

    .sales-period-item
      display flex
      width 100%
      height 26px
      justify-content space-between
      align-items center
      label:after
        content ' 23:59:59'
      .btn
        font-size 12px
        line-height 1
        padding 4px 8px

    & + .btn-create-period
      display block
      background #fff
      border-radius 50%
      border 0 none
      font-size 20px
      margin 0 0 0 122px
      width 20px
      height 20px
      padding 0
      line-height 0
      color #666
      outline none

  .sales-period-form
    position relative
    width 335px
    margin-top -20px
    padding 12px
    border 1px solid #cccccc
    border-radius 4px
    background-color #ffffff
    html[lang="en"] &
      width 356px
    html[lang="jp"] &
      width 370px
    > p
      font-size 12px
      margin 6px 0 0
      padding 0
      color #666
    >.body
      width 100%
      display inline-flex
      justify-content space-between
    &[data-mode="edit"]
      margin 4px 0 4px 18px

    .form-item
      width 200px
      text-align center
      margin-bottom 0
      > *
        width 100%
    input
      height 30px

    input
    .btn-cancel
    .btn-save
      text-align center
      line-height 1
      height 32px !important
    .btn-cancel
    .btn-save
      padding-left 10px
      padding-right 10px
  .setting-thumbnail
    flex-grow 1
    position relative
    text-align center
    background-color #f6f6f6 !important
    @media screen and (max-width 1024px)
      background-color transparent !important

    .pb-thumbnail-upload
      position relative
      display flex
      width 360px
      height 480px
      margin 0 auto
      border 1px solid #cccccc
      border-radius 4px

      .pb-thumbnail-upload-button
      .pb-thumbnail-upload-input
        position absolute
        width 100%
        height 100%
        left 0
        top 0

      .pb-thumbnail-upload-button
        z-index 1
        width 100%
        display flex
        flex-direction column
        justify-content center
        align-items center

        i
          margin-bottom 12px
          font-size 80px

      .pb-thumbnail-upload-input
        opacity 0
        z-index 2
        cursor pointer

    .thumbnail-slider
      display inline-block
      position absolute
      top 50%
      left 0
      right 0
      margin auto
      max-width 80%
      width 568px
      transform translateY(-50%)

      @media screen and (max-width 1024px)
        display flex
        flex-direction column-reverse
        position static
        max-width 100%
        width 100%
        height auto
        transform none
        > button
          display none !important
        > .form-item
          margin 20px 20px 0

      .slider-items
        plain-list()
        height: 100%
        @media screen and (max-width 1024px)
          margin auto
          width 100vw
          height 100vw

      .slider-items > *
        position relative
        width 100%
        height 100%
        display none
        &[data-current="true"]
          display inline-block

        .product-thumbnail-editor
          > button
            width 64px
            height 32px
            margin 8px
            line-height 1.0

        &:hover .product-thumbnail-editor
          position absolute
          width 90%
          height 90%
          display flex
          flex-direction column
          justify-content center
          align-items: center
          z-index: 10
          background-color rgba(0,0,0,0.5)
          top 5%
          left 5%

        &:not(:hover) .product-thumbnail-editor
          display none

        .wrap-image
          position relative
          height 100%
          margin 24px auto
          max-width 520px
          max-height 520px
          .product-status
            display none

        @media screen and (max-width 1024px)
          .form-item
            top -30px
            margin-bottom 0
          .wrap-image
            margin 0
            max-width 100%
            max-height 100%
            .mp-product-img
              max-width 80%
              max-height 80%

      .thumbnail-pagination
        plain-list()
        position absolute
        display flex
        justify-content center
        width 100%
        bottom 100%
        left 50%
        transform translateX(-50%)

        @media screen and (max-width 1024px)
          position static
          width auto
          transform none
          justify-content center
          margin-left -8px
          margin-top -8px

        > *
          width 80px
          height 80px
          margin-right 12px
          margin-bottom 12px
          @media screen and (max-width 1024px)
            width 44px
            height 44px
            margin 8px 0 0 8px

          &:nth-child(5)
          &:last-child
            margin-right 0

          .thumbnail-pagination-item
            position relative
            width 100%
            height 100%
            border 2px solid #eeeeee
            cursor pointer
          &[data-current="true"] .thumbnail-pagination-item
            border solid 2px #3c89f9;

      & > [data-direction]
        position absolute
        bottom 50%
        cursor: pointer
        background-size: 14px 30px
        width: 14px
        height: 30px
        color transparent
        border 0 none
        outline none
        background-image: url(//s3.marpple.co/files/u_18050/2018/9/original/f_5884_1537364162968_t4bt0Qto0aL6M3zIeGyA.png)
        background-color transparent
      & > [data-direction="prev"]
        left 0
        transform translate(-100%, 50%) rotate(180deg)
      & > [data-direction="next"]
        right 0
        transform translate(100%, 50%)

  [data-group_name="price"]
    @media screen and (max-width 1024px)
      .form-item
        padding-bottom 25px
      .title
        &.has-tooltip
          position static
        small
          position absolute
          bottom 0
          left 0
          font-size 12px
          font-weight 600
          line-height normal
          letter-spacing -0.5px
          color #b2b2b2
          margin-left 0

  .form .form-item
    .limited-labels
      display flex
      .limited-label
        margin-right 27px
        display flex
        align-items center



