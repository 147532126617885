@require "../../../../../../../www/css/creator/crew/crew.banner.mixins.styl"

// frame
.editor-image-settings-modal[is_modal="true"]
  frame_modal_default_header()
  frame_modal_full_height()
  & > .don_wrapper
    overflow hidden
  .don_page > .don_wrapper > .header > .don_hide_frame
    top 10px

.editor-image-setting
  padding 40px
  padding-top 76px

  &__form
    margin-bottom 40px

  &__form_title
    font-size 18px
    font-weight 600
    margin-bottom 10px

  &__form_desc
    color #4c4c4c
    margin-bottom 20px
    line-height 1.54
    letter-spacing -0.2px
    font-size 13px

  &__footer
    width 100%
    display flex
    justify-content space-between
    margin-top auto
  &__footer_button
    flex-grow 1
    margin-right 5px
    &:last-child
      margin-right 0

.editor-thumb
  display flex
  gap 20px
  margin-bottom 15px

  &__preview
    width 394px
    margin-bottom 10px

  &__forms
    flex 1 1 auto

.editor-title, .editor-desc
  position relative
  display flex
  align-items center
  margin-bottom 20px

  &__lang
    margin-right 10px

  &__area_box
    flex 1 1 auto
    countryIcon()

  &__area
    resetTextarea()
    inputStyle(100%, 60px)
    padding 9px 13px 9px 38px

.editor-cover
  display flex
  margin-bottom 60px
  gap 40px
  &__forms
    flex 1 1 auto
    .btn-submit
      margin 0

.editor-cover-preview
  margin-bottom 15px
  position relative

  &--large
    width 576px

  &--small
    width 375px

  &__image
    width 100%

  &__empty
    width 576px
    aspect-ratio 576/324
    border 1px solid black

  &__content
    position absolute
    top 0
    width 100%
    height 100%
    padding 40px

.editor-cover-preview--large
  .editor-cover-preview__category
    font-size 18px

  .editor-cover-preview__text
    font-size 32px

.editor-cover-preview--small
  .editor-cover-preview__category
    font-size 16px

  .editor-cover-preview__text
    font-size 24px

