.don_frame[frame_name="maker.image_color"]
  .swiper-slide
    box-sizing border-box
  .image_color_item
    width 55px
    height 55px
    margin 7px
    text-align center
    position relative
    img
      width auto
      height auto
      max-width 100%
      max-height 100%
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
    &.selected
      background #eee
