#maker_frame
  &[data-is_vector="true"]
    #marpple_layer
      display none
  #marpple_layer
    >.head
      min-width 112px
    &[data-has_info_option='true']
      >.head
        min-width 235px
    >.head
      box-sizing border-box
      position relative
      &::after
        content ''
        background transparent url(//s3.marpple.co/file/u_15/2018/7/original/f_5274_1531137521497_VF6f4lRDkA4DTihI1tX.svg) no-repeat 50% 50%
        background-size 6px
        width 10px
        height 10px
        position absolute
        top 50%
        transform translateY(-50%) rotateZ(270deg)
        right 11px
    *
      user-select: none; /* CSS3 (little to no support) */
      -ms-user-select: none; /* IE 10+ */
      -moz-user-select: none; /* Gecko (Firefox) */
      -webkit-user-select: none; /* Webkit (Safari, Chrome) */
    &[data-is_hidden='true']
      border-bottom-left-radius 0
      border-bottom-right-radius 0
      >.head
        &::after
          transform translateY(-50%) rotateZ(90deg)
      >.design_list_wrapper
        display none
    position absolute
    left 40px
    background #fff
    box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.1)
    transition opacity 0.3s
    border-radius 8px
    .info
      padding 2px 8px
    .from_start_info
      font-size 12px
      text-align left
      b
        background #FC713E
        border-radius 3px
        padding 1px 3px 0
        display inline-block
    .rotation_info
      position initial
      padding 0
      color color_black
      background transparent
      font-size 12px
      opacity 1
      min-width 110px
      text-align left
    >.head
      padding-left 16px
      display flex
      align-items center
      height 34px
      border-bottom 1px solid #ebeef0
    &[designs_length="0"]
      display none
    .design
      display flex
      align-items center
      width 100%
      //border-bottom 1px solid #d5dbe0
      border-bottom 1px solid #ebeef0
      box-sizing border-box
      cursor pointer
      &[is_overflow="true"]
        background #fea022 !important
      &:last-child
        border-bottom none
        border-bottom-right-radius 8px
        border-bottom-left-radius 8px
      .img
        width 62px
        height 38px
        position relative
        background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_13838_1542357399878_Ac3B1xZ5oRbvi6yvep7W.png) no-repeat 50% 50%
        background-size cover
        img
          position absolute
          top 50%
          left 50%
          max-width 40px
          max-height 32px
          transform translate(-50%, -50%)
      .options
        margin 0 5px
      .options button
        width 20px
        height 30px
        border 1px solid transparent
      .name
        font-size 12px
        color color_black
        text-align center
        width 50px
        margin-left 8px
      .hide
        background url(//s3.marpple.co/files/u_18052/2018/10/original/f_5949_1539147971785_f3A7yOB7l0fn4pk9yM2sBZ.png) no-repeat 50% 50%
        background-size 12px 12px
        outline none
      .locking
        outline none
        background url(//s3.marpple.co/files/u_18052/2018/10/original/f_5950_1539147971785_Z7e5XP2Vbl0A9XTzT0wrT.png) no-repeat 50% 50%
        background-size 12px 12px
    .design.selected
      background-color rgb(232, 240, 254)
      cursor initial
      .name
        color color_active
    .design[is_locked="true"]
      .locking
        background url(//s3.marpple.co/files/u_18052/2018/10/original/f_5948_1539147971785_Dx0clcpwC8T0C0J7KJgL.png) no-repeat 50% 50%
        background-size 12px 12px
    .design[visible="false"]
      .hide
        background url(//s3.marpple.co/files/u_18052/2018/10/original/f_5951_1539147971785_Mbw8o0hQhfokX4S2x8V2T.png) no-repeat 50% 50%
        background-size 12px 12px
