
// default
.label
  display inline-block
  padding .25em .4em
  font-size 1rem
  font-weight 500
  line-height 1
  white-space nowrap
  vertical-align baseline
  border-radius .25rem
  transition color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
  background-color rgba(24,28,33,0.1)
  min-width 3rem
  text-align center
  color #4E5155

  &.label-primary
    background-color #26B4FF
    color #fff
  &.label-secondary
    background-color #8897AA
    color #fff
  &.label-save
  &.label-ok
  &.label-success
    background-color #02BC77
    color #fff
  &.label-info
    background-color #28c3d7
    color #fff
  &.label-warning
    background-color #FFD950
    color #665720
  &.label-danger
    background-color #d9534f
    color #fff
  &.label-dark
    background-color rgba(24,28,33,0.9)
    color #fff

// outline
.label
  &.label-outline-default
    background-color transparent
    box-shadow 0 0 0 1px rgba(24,28,33,0.1) inset
    color #4E5155
  &.label-outline-primary
    background-color transparent
    box-shadow 0 0 0 1px #26B4FF inset
    color #26B4FF
  &.label-outline-secondary
    background-color transparent
    box-shadow 0 0 0 1px #8897AA inset
    color #8897AA
  &.label-outline-success
    background-color transparent
    box-shadow 0 0 0 1px #02BC77 inset
    color #02BC77
  &.label-outline-info
    background-color transparent
    box-shadow 0 0 0 1px #28c3d7 inset
    color #28c3d7
  &.label-outline-warning
    background-color transparent
    box-shadow 0 0 0 1px #FFD950 inset
    color #fbc02d
  &.label-outline-danger
    background-color transparent
    box-shadow 0 0 0 1px #d9534f inset
    color #d9534f
  &.label-outline-black
    background-color transparent
    box-shadow 0 0 0 1px rgba(24,28,33,0.9) inset
    color rgba(24,28,33,0.9)

@import "chart.styl"

@import "table.styl"

.input_common
  width: 100%
  font-size: 14px
  border: solid 1px #d5dbe0
  padding: 8px 16px
  box-sizing: border-box
  display: block
  background-color: #fff
  outline: none
  -webkit-appearance: none
  border-radius: 2px

// frame modal header

.don_frame[is_modal=true]
  .frame_modal
  .page_modal
    > .don_wrapper
      height auto
      top 10px
      bottom 10px
      padding-top 56px
      >.header
        position absolute
        top 0
        left 0
        right 0
        height 56px
        background #fff
        border-bottom 1px solid #dfdfdf
        z-index 3
        .title
          text-align center
          height 56px
          line-height 56px
          font-size 18px

// 스위치
.switcher
  position relative
  display inline-block
  margin-right 0.75rem
  margin-bottom 0
  border-radius 60rem
  vertical-align middle
  font-weight normal
  cursor default
  padding-left 2.375rem
  min-height 1.375rem
  font-size 0.894rem
  line-height 1.4

  .switcher-input
    position absolute
    z-index -1
    margin 0
    padding 0
    opacity 0

  .switcher-indicator
    width 2.375rem
    height 1.375rem
    font-size 0.625rem
    line-height 1.375rem
    position absolute
    top 0
    left 0
    overflow hidden
    border-radius 60rem
    background rgba(24, 28, 33, 0.1)
    color rgba(24, 28, 33, 0.3)
    font-weight bold
    cursor default
    transition-duration 0.2s
    transition-property left, right, background, box-shadow
    -webkit-user-select none
    -moz-user-select none
    -ms-user-select none
    user-select none
    &:after
      content ""
      position absolute
      left 0
      top 0
      display block
      border-radius 999px
      background #fff
      box-shadow 0 0 0 1px rgba(24, 28, 33, 0.04), 0 1px 6px rgba(24, 28, 33, 0.09)
      transition-duration 0.2s
      transition-property left, right, background
      margin 0.25rem 0 0 0.25rem
      width 0.875rem
      height 0.875rem

  .switcher-input:checked ~ .switcher-indicator
    background #26b4ff
    color #fff
    .switcher-yes
      left 0
    .switcher-no
      left 100%
      color transparent
    &:after
      left 1rem

  .switcher-no,
  .switcher-yes
    position absolute
    top 0
    width 100%
    height 100%
    text-align center
    transition-duration 0.2s
    transition-property left, right

  .switcher-yes
    padding-right 1.125rem
    padding-left 0.25rem
    left -100%

  .switcher-no
    padding-right 0.25rem
    padding-left 1.125rem

  .switcher-label
    top .0617rem
    position: relative;
    display: inline-block;
    padding-left: .5rem;
    color: #4E5155;
    font-weight: 400;
    cursor: default;

input-box()
  display: block;
  width: 100%;
  padding 9px 10px
  font-weight: 500;
  font-size 14px
  line-height: 1.57;
  color: #000000;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out
  outline none
  &:focus
    border-color: #3c97fe;
  &.inline
    display inline-block
    vertical-align top
    width auto
  &.danger
  &.invalid
    border-color #d9534f
  &[disabled]
    background-color #efefef

// dropdown
.cp-dropdown
  input-box()
  appearance none
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image url(//s3.marpple.co/files/u_1093672/2020/9/original/e71d8ec0fcd9087bd4c8790242b155b503785d734.png)
  background-repeat no-repeat
  background-size auto 50%
  background-position top 50% right .7em
  &:-ms-expand
    display none

.custom-dropdown
  input-box()
  width auto
  position relative
  padding-right 30px
  appearance none
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image url(//s3.marpple.co/files/u_1093672/2020/9/original/e71d8ec0fcd9087bd4c8790242b155b503785d734.png)
  background-repeat no-repeat
  background-size auto 30%
  background-position top 50% right .7em
  line-height 1em
  cursor pointer
  &:-ms-expand
    display none
  &.dropdown-transparent
    border 0

  .dropdown-options
    position absolute
    right 0
    top 100%
    border-radius 2px
    box-shadow 0 1px 2px 0 rgba(0, 0, 0, 0.3)
    border solid 1px #e5e5e5
    display none
    text-align center
    white-space nowrap
    background-color white
    z-index 1

    .dropdown-option
      padding 13px 70px
    .dropdown-option-input
      display none

  &:focus
    .dropdown-options
      display block
      .dropdown-option:hover
        background-color #3c89f9
        color white

