.container
  width 100%
  margin auto
  background-color white
  text-align center
  padding 50px 0

  .tip_header
    text-align left
    max-width 1000px
    margin 0 auto

  @import "../feed/editor.styl"
  .editor
    .title
      font-size 30px
      font-weight bold
    .date
      font-size 14px
    margin 100px 0
    max-width 1000px
    display inline-block

