.maker_we_guide_pop_up
  z-index 100
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  display flex
  justify-content center
  align-items center
  .blank
    background #000
    opacity 0.5
    position absolute
    top 0
    left 0
    right 0
    bottom 0
  .img
    position absolute
    opacity 0
    button
      width 800px
      height 40px
      position absolute
      opacity 0
      bottom 40px
      left 50%
      transform translateX(-50%)
