color_black = #000
color_gray1 = #333
color_gray2 = #666
color_gray3 = #868e96
shopify_color_gray3 = #b2b2b2
color_gray4 = #a5aab0
//color_gray5 = #868e96
color_gray5 =
  color #000
  opacity 0.5

//color_active = #0157ff
color_black066 = #000066

transform-center =
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)

transform-vertically-center =
  position absolute
  top 50%
  transform translateY(-50%)

transform-horizontally-center =
  position absolute
  left 50%
  transform translateX(-50%)

flex-center =
  display flex
  align-items center
  justify-content center

flex-center-between =
  display flex
  align-items center
  justify-content space-between

flex-center-right =
  display flex
  align-items center
  justify-content flex-end

flex-top-left =
  display flex
  align-items flex-start
  justify-content flex-start

flex-center-left =
  display flex
  align-items center
  justify-content flex-start

flex-center-center =
  display flex
  align-items center
  justify-content center

flex-left-center =
  display flex
  align-items flex-start
  justify-content center

flex-right-between =
  display flex
  align-items flex-end
  justify-content space-between


svg-checkbox-active = url("//s3.marpple.co/files/u_47936/2018/10/original/f_12362_1540955330689_Pnrd2m5Tq2EW9hf1l4hyu.svg")
svg-checkbox = url("//s3.marpple.co/files/u_47936/2018/10/original/f_6292_1540536042973_WyzW2G7TeuZaHPDDR.svg")
png-heart = url("//s3.marpple.co/file/guest/2018/7/original/f_5373_1532333075070_zT4bT1d7nmPW1Nrxlap.png")
svg-heart = url("//s3.marpple.co/file/u_15/2018/7/original/f_5326_1531818936488_U8k6CohC8lz4f9Mqc1mEM.svg")
svg-modal-close-arrow = url("//s3.marpple.co/file/u_18054/2018/6/original/f_5197_1528685538799_pNXOFBubzyFN5VT0B.svg")
svg-select-down-arrow = url("//s3.marpple.co/file/u_18054/2018/6/original/f_5198_1528685590719_XFq4iBIP5lw9I7s0vgD3O.svg")
svg-select-up-arrow = url("//s3.marpple.co/file/u_15/2018/7/original/f_5270_1530864311509_XPvN9ZgNSQlAEen4g.svg")
svg-plus = url("//s3.marpple.co/file/guest/2018/2/original/f_1656_1518152961135_TZW9pZVTRLJ0fqb3s8b.svg")
svg-minus = url("//s3.marpple.co/file/guest/2018/2/original/f_1655_1518152961135_on1UHreUT7bSKd8i9uO.svg")
svg-plus-active = url("//s3.marpple.co/file/guest/2018/2/original/f_1658_1518153049211_VF8rV0k4fLm1P5OOlS6gC.svg")
svg-minus-active = url("//s3.marpple.co/file/guest/2018/2/original/f_1657_1518153049211_C3BGXRkNe8d3XlFtSB.svg")
svg-close = url("//s3.marpple.co/file/guest/2018/1/original/f_1545_1516248771840_L2S9K1VAIq4HP4go7cweM.svg")
svg-down-arrow = url("//s3.marpple.co/files/u_18054/2018/9/original/f_5859_1537256167853_nml7n1JS2DaZntt8i6wI.svg")
svg-down-arrow2 = url("//s3.marpple.co/file/u_15/2018/7/original/f_5268_1530864115648_X2nQ3VKQX1CpON4T0af7e.svg")
svg-up-arrow = url("//s3.marpple.co/file/u_15/2018/7/original/f_5269_1530864115648_TXPkLkyt8rB4o5z5Udy.svg")

//svg-paypal = url("//s3.marpple.co/files/u_18054/2018/9/original/f_5860_1537256393666_IXOqPeXDVdt6a2Set.svg")
png-paypal-logo = url("//s3.marpple.co/files/u_18054/2018/10/original/f_5959_1539159713198_wdXWhIaScJnpoRD.png")
png-paypal-word = url("//s3.marpple.co/files/u_18054/2018/10/original/f_5955_1539159713198_l1Co0puSClo1d1Na4JSy.png")
svg-pen = url("//s3.marpple.co/files/u_18054/2018/10/original/f_5961_1539161275666_WzxVW6gC1CS9c8v7Z6JDH.svg")
svg-success = url("//s3.marpple.co/files/u_18054/2018/10/original/f_6012_1539261901179_XbZdZSqxkAzKqrd.svg")

svg-right-arrow = url("//s3.marpple.co/files/u_18054/2018/9/original/f_5900_1537512813849_oINwy1Q8Q4ks5A1z2T2ecb.svg")
svg-left-arrow = url("//s3.marpple.co/files/u_18054/2018/10/original/f_6065_1539333261284_E0TScLUwr1f9E0ZR1vyH.svg")

svg-preview-left-arrow = url("//s3.marpple.co/files/u_47936/2018/11/original/f_12482_1542009201349_xW9APhq7JP5IMWUxFa.svg")
svg-preview-right-arrow = url("//s3.marpple.co/files/u_47936/2018/11/original/f_12483_1542009205386_Cu9KvchD4TqpRBVT1S.svg")

svg-empty-order = url("//s3.marpple.co/files/u_18054/2018/10/original/f_6095_1539518038568_sl9yT2i0f6o0fTxI5vVL8c.svg")
png-email = url("//s3.marpple.co/files/u_18053/2018/10/original/f_6003_1539247856227_I2T3xMm1t5wHzmr3sQSq.png")
png-password = url("//s3.marpple.co/files/u_18053/2018/10/original/f_6002_1539247856227_PwUfLn9zS2C9Veyu1RC.png")

sticker-folder-hover = url("//s3.marpple.co/files/u_18053/2018/10/original/f_6067_1539333281684_DTV1f8c7dEox8BGLe0Vy.png")
sticker-group-hover = url("//s3.marpple.co/files/u_18053/2018/10/original/f_6093_1539337463344_sUzCgUI6KpO9ER2IM4k.png")
m-sticker-folder-active = url("//s3.marpple.co/files/u_14355/2018/12/original/f_18087_1545039269083_o7HHp8G6WGfkSp5pQMG.jpg")

color_active = #3c89f9

checkout-order-active-border =
  outline none
  border solid 1px #3c89f9

checkout-order-input-look =
  padding 14px 16px 12px 16px
  border-radius 4px
  border solid 1px #dddddd

span.creator_del_ai
  display none

$bebas-font-family = 'Bebas Neue Pro'
