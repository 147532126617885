&
  .product-filters
    @media screen and (max-width: 600px)
      &.right
        flex-direction row !important
        justify-content flex-end !important
    .dropdown
      &-anchor
        display flex
        flex-direction row
        justify-content center
        align-items center
        padding-right 0
        cursor pointer
        > img
          width 9px
          margin-left 10px
          transition transform 0.2s
      &-list
        text-align center
        &-item
          justify-content center
      &.opened
        .dropdown-anchor
          > img
            transform rotate(180deg)
    &.flex-column
      flex-direction column !important
      @media screen and (max-width: 1024px)
        align-items flex-start !important
    &__filter
      display flex
      @media screen and (max-width: 1024px)
        justify-content flex-end
        width 100%
        font-size 12px
    &__button
      margin-bottom 30px
      padding 12px 24px 11px
      border-radius 2px
      border 0
      background-color #3c89f9
      letter-spacing -0.5px
      color #fff
      @media screen and (max-width: 1024px)
        margin-bottom 10px !important
        font-size 13px !important
        paddng 10px 16px !important
  .products
    list-style none
    margin 0 0 0 -16px
    padding 0
    display flex
    flex-wrap wrap
    > li
      width calc(20% - 16px)
      margin-bottom 16px
      margin-left 16px
      vertical-align top
      cursor pointer
    &[data-custom_level="ASSET"]
    &[data-custom_level="USER"]
      .mp-shop-product-item
        .mp-product-img-bg-wrap
          aspect-ratio 3/4
    @media screen and (max-width: 1024px)
      margin 0 0 0 -10px
      > li
        width calc(50% - 10px)
        margin-bottom 10px
        margin-left 10px
    @media screen and (max-width: 360px)
      margin 0
      > li
        min-height 300px
        width 100%
        margin-bottom 10px
        margin-left 0

    .btn-create-product
      position relative
      text-align center
      border 1px dashed rgba(0, 0, 0, 0.15)
      border-radius 10px
      min-height 250px
      //min-height calc((100vw - 160px) / 5 * 1.447368421052632)

      .wrap-content
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
        width 100%

      .plus-icon
        display flex
        justify-content center
        align-items center
        background-color #3c89f9
        border-radius 50%
        width 66px
        height 66px
        margin 0 auto 20px
        & > img
          width 30px

      .description
        color #4c4c4c
        font-size 14px
        line-height 1.5
        letter-spacing -0.5px
        text-align center
        padding 0 20px
        word-break keep-all
        @media screen and (max-width: 360px)
          font-size 12px

    @media screen and (max-width: 1024px)
      .mp-shop-product
        &-item
          margin 0 0 20px
        &-image
          margin-bottom 10px
        &-name
          font-size 14px
          font-weight 600
        &-price
          margin-top 6px
          font-size 13px
        &-limited
          font-size 10px
          margin-top: 7px;
      .mp-shop-product-cate-item-name
        margin-top 5px
        font-size 12px
        letter-spacing -0.5px
        color #828282


  .products[data-is_store_deleted="true"]
    > li
      cursor not-allowed
    .btn-create-product
      display none

  .setting-pb-products__temp-area
    position fixed
    left 300px + 210px
    right 300px
    bottom 60px
    text-align center
    opacity 0
    @media screen and (max-width: 1024px)
      left 20px
      right 20px
      bottom 0

  .setting-pb-products__btn-temp-save
    display inline-flex
    align-items center
    justify-content center
    height 60px
    padding 20px 80px 18px
    font-size 15px
    font-weight 600
    line-height 1
    letter-spacing -0.5px
    color #fff
    border-radius 10px
    box-sizing border-box
    box-shadow 0 8px 20px 0 rgba(0, 0, 0, 0.15)
    border 0 none
    background-color #3c89f9
    @media screen and (max-width: 1024px)
      width 100%
      height 56px
      font-size 14px
      padding 20px 20px 18px
    .setting-pb-products__btn-temp-save-ico
      margin-right 10px
      margin-bottom 2px
      width 22px
      height 22px
      background url("//s3.marpple.co/files/u_218933/2023/3/original/abad9ba9a59d6386d00a4aad33db6b4d2661611c1.png") no-repeat
      background-size 22px 22px
    .setting-pb-products__btn-temp-save-arrow
      margin-left 10px
      margin-bottom 2px
      width 18px
      height 18px
      background url("//s3.marpple.co/files/u_218933/2023/3/original/397f6e3f133e9456e6e840c096e06bdf0c8ae3dc1.png") no-repeat
      background-size 18px 18px


    //position relative
    //top 1px
    //list-style none
    //padding 0
    //margin 0 12px 0 0
    //display inline-block
    //> *
    //  display inline-block
    //  margin-right 12px
    //  cursor pointer
    //  > *
    //    cursor inherit
    //    vertical-align middle
    //    font-size 14px
    //  input
    //    position relative
    //    top -1px
    //    margin-right 4px
    //  :last-child
    //    margin-right 0
