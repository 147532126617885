.impossible-invoice
  .modal_content_body
    width 60%
    margin 0 auto 40px
    .modal-content_line
      word-break keep-all
      font-size 14px
      line-height 1.71
      letter-spacing -0.5px
      text-align center
      color #000000
      margin 0 auto
  .btn
    display: inline-block;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 1px solid;
    padding: 9px 1.125rem;
    line-height: 1.54;
    border-radius: .15rem;
    transition: all 0.2s ease-in-out;
    border-color: rgba(24,28,33,0.1);
    background: #fff;
    color: #000;
    margin 0 3px
    &.btn-icon
      border none
      outline none
      background transparent
      padding 0
    &:first-child
      margin-left 0
    &:last-child
      margin-right 0
    &.btn-primary
      color #fff;
      background-color #3c89f9
      border-color #3c89f9
    &.btn-default
      border: solid 1px #d9d9d9;
      background-color: #f5f5f5;

.setting-bank_account
  .guide-wrapper
    & + .form-item
      margin-top 80px
      @media screen and (max-width: 600px)
        margin-top 30px
    & + .guide-wrapper
      margin-top 40px
    .guide
      padding 10px 14px 10px 14px
      margin 0 0 10px
      border 1px solid #ededed
      border-radius 2px
      &-item
        position relative
        line-height 1.64
        list-style-type disc
        margin-left 20px
        padding-left 3px
        word-break keep-all
        &::marker
          font-family Helvetica, Arial, sans-serif
        a
          color #000
          font-weight bold
      &.number
        .guide-item
          list-style-type decimal
    .btn
      padding 9px 24px
      margin 0
      .lnr
        font-weight bold
      & + .btn
        margin-left 10px

  .body.bank_account
    .btn
      padding 9px 10px
      min-width 80px

  .form-container
    > .buttons
      margin-bottom 70px
      .btn
        width 120px

  .condition-actions
    margin-top 70px
    .btn
      width 120px

  .warning-box
    text-align center
    margin 0 auto
    background-color #fff176
    p
      color  #616161
      margin 0
      padding 0.7em
      font-size 1.3rem

  .form.bank_settings
    margin-top 30px
    p
      margin-bottom 1.5em
      line-height 1.5em

  .form-group
    margin-bottom 80px
    @media screen and (max-width: 600px)
      margin-bottom 40px

  .form-item
    margin-bottom 0
    .form-control
      position relative
      label
        margin auto
      & > *:not(:first-child):not(.fx-auto-complete-list)
        margin-left 10px
    input[type="text"]
    input[type="file"]
      min-width 130px
    input[type="file"]
      display none
    input[type="checkbox"]
    input[type="radio"]
      margin-left 0
    .btn
      cursor pointer
    .flex-box
      display flex
    .term_agree:first-child
      margin-top 10px
    .term_agree:last-child
      margin-bottom 0

  .fx-auto-complete-list
    top auto
    bottom 0
    transform translate(0, 100%)
    max-height 300px
    overflow-y scroll


  .spinner {
    color: #02bc77;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    vertical-align: text-bottom;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border 0.75s linear infinite;
    animation: spinner-border 0.75s linear infinite;

    position fixed
    right 50%
    top 50%
  }

  .input-spliter
    font-weight bold
    font-size 1rem

  *[data-show="false"]
    display none

  .prev_term > *:last-child
    margin-bottom 30px

  .parent-cert-btn
    width 230px
    text-align center
  .parent-cert-btn__icon
    margin-right 10px

  .view-example
    cursor pointer


@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
