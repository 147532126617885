
@media screen and (min-width 1025px)
  .verify_frame
    .don_page
      min-height auto
    .verify_email
      padding-top 120px
      width 992px
      margin 0 auto
      min-height 300px
      text-align center
      h1
        margin 0 0 70px
        padding 0 0 0 5px
      .txt1
        margin 0
        padding 104px 0 0
        background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12420_1541076556776_n3QDDUQC7hh7n2Bi2c9Tq.png) no-repeat
        background-size 80px auto
        background-position 50% 0
        font-size 32px
        color color_black
        html[lang="en"] &
          font-family CircularStd
      .txt2
        margin 50px 0 0
        padding 0
        font-size 14px
        line-height 1.71
        color color_black
      .buttons
        position relative
        margin 80px 0 160px
        height 48px
        display flex
        button
          font-size 14px
          text-align center
          width 488px
          height 48px
          margin 0 5px
          border-radius 2px
          &.btn_back
            color color_black
            border solid 1px #dcdcdc
            background-color #f5f5f5
          &.btn_change_email
            color color_black
            border solid 1px #d5dbe0
            background-color #fff
          &.btn_resend_verify_email
            color #fff
            border solid 1px #3c89f9
            background-color #3c89f9



@media screen and (max-width 1024px)
  .verify_frame
    .verify_email
      padding-top 10px
      padding-bottom 40px
      min-height 300px
      text-align center
      h1
        margin 0 0 50px
        padding 0
        img
          min-height 18px
          max-height none
          max-width 70%
      .txt1
        margin 0
        padding 65px 20px 0
        background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12420_1541076556776_n3QDDUQC7hh7n2Bi2c9Tq.png) no-repeat
        background-size 60px auto
        background-position 50% 0
        font-size 20px
        color color_black
        html[lang="en"] &
          font-family CircularStd
      .txt2
        margin 40px 28px 0
        padding 0
        font-size 14px
        line-height 1.71
        color color_black
        b
          display block
      .buttons
        margin 80px 4%
        button
          font-size 14px
          text-align center
          width 100%
          height 48px
          border-radius 2px
          &.btn_back
            color color_black
            border solid 1px #dcdcdc
            background-color #f5f5f5
          &.btn_change_email
            margin-top 8px
            color color_black
            border solid 1px #d5dbe0
            background-color #fff
          &.btn_resend_verify_email
            margin-top 8px
            color #fff
            border solid 1px #3c89f9
            background-color #3c89f9









