.editor
  width 100%
  background-color white
  padding 0
  position relative
  text-align left
  box-sizing border-box

  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.2px;

  &:focus
    outline none

  & > h2 {
    {$kr-32-b}
    margin: ptr(16) 0;
  }

  & > h3 {
    {$kr-24-b}
    margin: ptr(16) 0;
  }

  a {
    color $blue-100
    text-decoration underline
  }

  @import "editor.embed.styl"

  // image
  .img_container
    margin ptr(16) 0;
    user-select none
    text-align center
    line-height 0
    position relative

    .caption
      margin-top ptr(8)
      color: #6B6B6B;
      /* KR-14/Medium */
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.2px;
      text-align left


    .image_size_info
      display none

    .resize_wrapper
      width 100%
      min-width 200px
      max-width 100%
      display inline-flex
      flex-wrap wrap
      align-items flex-start
      column-gap ptr(16)

      .image
        width 100%
        max-width 100%
        flex-grow 1
        flex-shrink 1
        flex-basis 22%
        min-width 0
        display block
        border-radius ptr(24)
        @media screen and (max-width: 480px)
          flex-basis 42%


  p
    margin-top 0
    margin-bottom 0
    word-break keep-all

  blockquote
    border-left 6px solid black
    margin-left -10px
    padding-left 20px

  .align-center
    text-align center

  .align-left
    text-align left

  &:after
     margin 0
    padding 0

  &.editable
    .img_container .resize_wrapper
      border 0
      &:hover
        outline 2px solid #bdbdbd
      .image:hover
        opacity 0.8
        border 0

    // insert plugin
    .insert_buttons
      position absolute
      user-select none
      display none
      margin-left -2.8em
      margin-top -1.5em
      z-index 2

      .insert_button
        position absolute
        border 1px solid #bdbdbd
        border-radius 50%
        width 1.5em
        height 1.5em
        line-height 1.5em
        display inline-flex
        justify-content center
        align-items center
        cursor pointer
        background-color white
        color #bdbdbd
        vertical-align top
        img
          width 13px

      .open_btn
        span
          font-size 1.3em
          margin-top -0.18em

      .feature_btns
        position absolute
        top 40px
        vertical-align top
        border 1px solid #d9d9d9
        border-radius 4px
        padding 6px 0
        min-width 30px
        white-space nowrap
        background-color white

        .feature_btn
          display flex
          align-items center
          color #999999
          letter-spacing -0.5px
          cursor pointer
          height 34px
          padding 0 41px 0 10px
          &:hover
            background-color #3c89f9
            color white
            i
              color white

          .feature-name
            font-size 12px
          label
            display flex
            cursor pointer
          i
            color #bdbdbd
            font-size 1em
            margin-right 10px

      input[name="insert-image"]
        width 0
        height 0
        position absolute

    // resizable custom
    .ui-resizable-handle
      font-size 1em
      width 1.5em
      display flex
      align-items center
      justify-content center
      &:after
        content ''
        height 10%
        min-height 40px
        width 0.2em
        min-width 3px
        background-color black
        opacity 0.8
        border-radius 3px

    .ui-resizable-e
      right 0
    .ui-resizable-w
      left 0

    .custom-toolbar
      top -5px
      left 50%
      transform translate(-50%, -100%)

  &.preview
    .insert_buttons
      display none!important

.popup-store-products-embed-modal
  $flex(space-between, stretch)
  margin ptr(-16)
  min-height ptr(600)
  &__products
    flex-shrink 0
    width 50%
    padding ptr(16)
    background #fff
    > div
      max-width 100%
  &__texts
    flex-shrink 0
    width 50%
    $flex(space-between, stretch, column)
    padding ptr(40)
    background var(--Gray-5, #F5F5F5)
  &__inputs
    $flex(flex-start, stretch, column)
    gap ptr(24)
  &__buttons
    $flex(flex-end, center)
    gap ptr(8)
    margin-top ptr(10)
    button
      $flex(center, centers)
      gap ptr(8)
      width ptr(132)
      padding ptr(13)
      border-radius ptr(2)
      {$font-body-b3-b}
    .add-products-cancel-btn
      background #fff
      border 1px solid var(--Gray-300, #DDD)
    .add-products-confirm-btn
      color #fff !important
      background: var(--SS-Sky, #3C89F9);
