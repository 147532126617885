@media screen and (min-width: 1025px)
  #maker_frame .decoration_menu
    display none
@media screen and (max-width: 1024px)
  #maker_frame
    html[collabo_type="line"] &
      .decoration_menu
        .marpple_icon
          display none !important
    .decoration_menu.decoration_mode
      .marpple_icon > .icon
        background-color #000 !important
      .cross
        transform rotate(45deg) !important
        >div:nth-child(1)
          border-bottom 1px solid #fff !important
        >div:nth-child(2)
          border-right 1px solid #fff !important
    .decoration_menu
      .marpple_icon
        .icon
          transition background-color 1s
    .super_menu
      position absolute
      bottom 90px
      html.safe_area_inset_bottom &
        bottom 100px
      width 66px
      opacity 1
      transform translateY(0px)
      transition transform 0.3s
      ul
        margin 0
        padding 0
      .main_icon
        position absolute
      >.body
        position absolute
        > li
          .icon
            opacity 1
            &:active
              background-color #ced4da
          .text
            opacity 0
            transition opacity 0.5s
            &.is_show
              opacity 1
          position absolute
          display none
          transform translateY(0)
    .product_menu
      left 6px
    .decoration_menu
      right 6px
      .cross
        position absolute
        width 15px
        height 15px
        transition transform 0.5s
        transform rotate(0deg)
        left 15px
        top 15px
        >div
          position absolute
          transition border-bottom 0.5s, border-right 0.5s
        div:nth-child(1)
          width 15px
          height 0
          border-bottom 1px solid black
          top 7px
        div:nth-child(2)
          width 0
          height 15px
          border-right 1px solid black
          left 7px
    .decoration_menu >.body
      .circle_icon:active
        .icon
          background-color color_active
        .text
          color color_active
        &.add_text
          .icon
            background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6027_1539320766618_Z3v3DaKq2J8d7DG5IvSJ1t.png)
            background-size 22px
            background-position 11px 11px
        &.add_sticker2
          .icon
            background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6035_1539321513991_Q9z1GilA4tCp6s8ZZV8NO.png)
            background-size 24px
            background-position 11px 11px
        &.my_lib
          .icon
            background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6021_1539320766618_aw6XSZ6lt4rIfy2Topt.png)
            background-size 24px
            background-position 11px 11px
        &.add_img
          .icon
            background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6030_1539320766618_f2nd5py5kkc3Mb4w1TpCh.png)
            background-size 24px
            background-position 11px 11px
        &.open_cv_background_frame .icon
          background-image url(//s3.marpple.co/files/u_193535/2022/1/original/862c950efd6afd90150266f79d1214fc0c0f7cbf2.svg)
          background-size 24px
          background-position 11px 11px
  html.prev_product_matcher
  html.maker_page:not(.maker_editor_mode)
  html.maker_page.all_faces_mode
  body.maker_page .mp_maker.cv_object_selected
  body.maker_page .mp_maker.pinch_mode
    .super_menu
      transform translateY(180px) !important
