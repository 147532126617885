&
  margin-top auto
  display flex
  align-items center
  justify-content center
  width 100%
  font-weight 600
  background-color #f5f5f5
  html.creator-settings &
    display none
  &-body
    width 100%
    padding 34px 20px 40px
    box-sizing border-box
    display flex
    flex-direction column
    letter-spacing -0.4px
  &-cs
    width 100%
    height 60px
    margin-bottom 40px
    display flex
    flex-direction column
    justify-content space-between
    .logo
      margin 0 0 1rem 0
      max-height 20px
      max-width 150px
      @media screen and (max-width: 1024px)
        max-width 150px
        margin .3rem 0 0 0
    &-major
      width 100%
      height 32px
      display flex
      align-items center
      justify-content space-between
      &-faq_link
        font-size 12px
        display flex
        align-items center
        p
          margin 0
          padding 1px 0 0
        img
          margin-left 10px
          vertical-align middle
      &-lang
        justify-content flex-end
        margin 10px 0 0
        padding 0
        height 22px
        display flex
        flex 1
        list-style none
        align-items flex-start
        @media screen and (max-width: 1024px)
          display none
        > li:not(:last-child)
          margin-right 14px
        a
          opacity 0.2
        a[data-active="true"]
          opacity 1
          text-decoration underline
      &-tel
        display none
      html[lang="kr"] & &-tel
        display inline-block
        font-size 20px
    &-minor
      width 100%
      height 22px
      font-size 12px
      display flex
      align-items center
      justify-content space-between
      &-email:link
        text-decoration underline
      &-time
        display none
      html[lang="kr"] & &-time
        display inline-block
      *[data-pc-only]
        display none
  &-info
    width 100%
    font-size 12px
    &-nav
      flex-grow 1
      display flex
      align-items center
      margin-bottom 10px
      ul
        margin 0
        padding 0
        list-style none
        display flex
        flex-direction column
        align-items flex-start
        li
          display flex
          text-decoration underline
        > li:not(:last-child)
          margin 0 0 14px
        //li.logout
        //  html[is_temp="true"] &
        //  html[is_session="false"] &
        //    display none
        //li.login
        //  html[is_temp="false"] &
        //  html[is_session="true"] &
        //    display none
        li.seller_studio
          html[is_seller="false"] &
            display none
        li.my_store
          display none
          html[is_seller="false"] &
            display inline-block
        .company_info
          .detail_toggle_btn
            max-width 18px
            max-height 18px
            margin-left 6px !important
            &.active
              transform rotate(180deg)
    &-detail
      display none
      margin 10px 0 0 0
      opacity 0.2
      p
        display flex
        flex-direction column
      .txt1
        font-size 10px
        line-height 15px
        @media screen and (max-width: 374px)
          word-spacing -1px
          letter-spacing -1px
    &-detail.active
      display block
    &-escrow
      display none
    &-lang
      height 22px
      padding 0
      margin 40px 0 0 0
      display none
      list-style none
      align-items center
      @media screen and (max-width: 1024px)
        display flex
      > li:not(:last-child)
        margin-right 14px
      a
        opacity 0.2
      a[data-active="true"]
        opacity 1
        text-decoration underline
    &-copy
      display inline-block
      height 22px
      line-height 22px
      opacity 0.2
      text-transform uppercase
      @media screen and (max-width: 1024px)
        margin-top 6px


@media screen and (min-width: 1025px)
  &
    &-body
      max-width 1120px
      padding 60px 0
      flex-direction row
      justify-content space-between
      width 96%
    &-cs
      width auto
      height auto
      margin 0
      &-major
        width auto
        height auto
        flex-direction column
        flex-grow 1
        justify-content space-between
        align-items flex-start
        margin-bottom 11px
        &-tel
          margin-bottom 4px
        &-faq_link
          font-size: 14px
      &-minor
        width auto
        height auto
        font-size 14px
        flex-direction column
        align-items flex-start
        justify-content flex-start
        &-email
          order 2
          margin-top 4px
        &-time
          display none
        html[lang="kr"] & &-time
          display inline-block
          order 1
          margin-bottom 10px
          span, span[data-pc-only]
            display block
    &-info
      width auto
      height auto
      font-size 14px
      display flex
      flex-direction column
      &-nav
        order 1
        align-items flex-start
        justify-content flex-end
        margin 0
        ul
          flex-direction row
          > li:not(:first-child)
            margin 0 0 0 20px !important
      &-detail
        order 2
        display block
        visibility hidden
        height 0
        overflow hidden
        text-align right
        margin 10px 0 0 0
        .txt1
          font-size 12px
          line-height 17px
        p
          flex-direction row
          justify-content flex-end
          span:not(:last-child)
            margin-right 10px
        p:first-child
          margin-top 0
        html[lang="kr"] & p
          &:last-child
            flex-direction column
            span
              margin 0
      &-detail.active
        visibility visible
        height auto
        overflow visible
      &-escrow
        display block
        position relative
        height 36px
        order 3
        text-align right
        padding 0
        margin 30px 0
        a.escrow
          position absolute
          top 0
          right 44px
          width 40px
          height 40px
        a.kb_escrow
          position absolute
          top 0
          right 0
          width 40px
          height 40px
      &-lang
        order 3
        justify-content flex-end
        padding 0
        margin 40px 0 12px 0
      &-copy
        order 4
        text-align right
