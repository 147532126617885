#muji_editor
  position relative
  .faces_box
    width 2000px
    .faces
      display inline
      .image
        padding 10px
        width 250px
        display inline
        img
          height 300px
#maker2
  position fixed
  background-color #eee
  top 50px
  left 50px
  right 50px
  bottom 50px
  border 1px solid #000
  div
  .header
    position relative
    >div
      display inline-block
    top 0
    left 0
    right 0
    height 50px
    border-bottom 1px solid #000
    h3
      padding 15px
      margin 0
    .product_info
      position absolute
      top 10px
      right 440px
      >div
        display inline-block
      select
        height 26px
        margin 0 15px 0 5px
        width 80px
    .buttons
      position absolute
      top 10px
      right 10px
      button
        width 100px
        height 30px
        font-size 12px
        background-color white
  .body
    position absolute
    top 51px
    left 0
    right 0
    bottom 0
    > div
      position absolute
      top 0
      bottom 0
    .left
      left 0
      width 194px
      overflow scroll
    .center
      left 210px
      right 350px
      width 800px
      #undo, #redo
        position absolute
        z-index 1
        top 10px
        padding 5px
        font-size 13px
        font-weight bold
      #undo
        right 100px
      #redo
        right 50px
      #save_test
        position absolute
        z-index 1
        top 50px
        right 70px
        padding 5px
      #reset
        position absolute
        z-index 1
        top 20px
        left 20px
        padding 5px


    .right
      right 0
      overflow scroll
      .tool_bar
        button
          width 70px
          height 50px
          font-size 12px
      .tool_bar_opt
        .shape_opt
          background white
          padding 10px
        button
          font-weight bold
          font-size 30px
          color dimgrey
          margin 5px

    .faces
      .face
        position relative
        width 190px
        border 1px solid grey
        margin 5px
        .name
          font-weight bold
        .img
          position relative
          width 90px
          height 90px
          margin auto
          img
            position absolute
            top 0
            right 0
            bottom 0
            left 0
            margin auto
            max-width 90%
            max-height 90%

        .thumbnail
          .img, .name, .price
            display inline-block


    .canvas-wrapper
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      margin auto
      width 800px
      height 800px
      border 2px solid darkgrey
      #canvas
        width 800px
        height 800px

    .left
      padding 5px
      ul.faces
        list-style none
        padding 0
      ul.layers
        list-style none
        padding-left 0px
        .layer
          padding-left 10px
        .layer > div
          display inline-block
          margin-left 5px
          vertical-align middle
        .img_wrapper
          width 40px;
          height 30px
          margin 3px
          img
            max-width 40px
            max-height 30px
            margin auto;






//.right > div
//  width 360px


.tool_bar
  padding 10px

.crop_bar
  position: absolute
  z-index: 1


#working-bar
  padding 10px
  font-size 13px
  background-color #eee

  >div>div
    border-bottom 1px solid darkgrey
    padding 5px 5px 10px 5px

  .name
    font-weight bold
    margin 7px 0 3px 0
  label.name
    display: inline-block

  div.settings
    margin-left 70px

  input[type=number]
    width 50px
  input[type=range]
    width 100px


  div.basic
    border-bottom 1px solid black
  div.extra
    border-top 1px solid black


.brush_editor
  position fixed
  top 0px
  left 0px
  right 0px
  bottom 0px
  margin auto
  z-index 999
  border 2px solid black
  width 600px
  height 600px
  background #fff
  display none
  .add
    font-size 14px
    padding 10px

#cont {
  position: relative;
  width: 600px;
  height: 600px;
}

.brush_editor canvas {
  border: 1px solid;
}

#cont canvas, #cont .brush_editor .canvas-container {
  position: absolute!important;
  left: 0!important;
  top: 0!important;
  width: 100%!important;
  height: 100%!important;
}

#cursor {
  pointer-events: none!important;
}

