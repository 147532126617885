body.setting-products
body.setting-custom-asset-products
body.setting-custom-user-products
  &.mp-maker-body-fixed
    .mp-maker-body-fixed__close-bg
      position fixed
      top 0
      left 0
      right 0
      bottom 0
    &::before
      content ''
      display block
      position fixed
      top 0
      left 0
      right 0
      bottom 0
      z-index 2
    &-dimmed
      &::before
        opacity 0.4
        background #000
  .hide_maker_page
    .don_page[page_name='maker.marpple_shop_page']
      visibility hidden
      canvas
        visibility hidden !important
  #maker_frame
    .open_chooser
      display none
    #marpplizer
      .product_editor
        .price
        .size.section
          display none
  .mp_product_detail
    .product_color
      .original_price
        display none
  .don_page[page_name="/modules/Creator/MobileMaker/S/Mui/page.js"]
    .fake_mp_maker
      padding-top 56px
    .bp_option_group[data-is_virtual="true"]
      display none
    .bp_option_groups[data-is_designed="true"]
      .bp_option_group[data-is_virtual="true"][data-id="199"]
        display block
    .maker_go_next
      display flex !important
    .maker_to_cart
      display none !important
  .don_page[page_name="maker.marpple_shop_page"]
    .bp_option_group[data-is_virtual="true"]
      display none
    .bp_option_groups[data-is_designed="true"]
      .bp_option_group[data-is_virtual="true"][data-id="199"]
        display block
    .maker_go_next
      display block !important
    .maker_to_cart
      display none !important

@import "../all/product.detail.styl"
.bp_option_groups
  .select_box
    [data-is_public='false']
      display none !important
      html[mp_worker_policy="true"] &
        display flex !important
  select
    [data-is_public='false']
      display none !important
      html[mp_worker_policy="true"] &
        display block !important
.vector-editor__single-editor
  .right_container
    background #fff
  .pannel_container
    background #fff
