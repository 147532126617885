@import 'helper.styl'

html.creator_store_home .store-tab li:nth-child(1)
html.creator-community .store-tab li:nth-child(2)
  border-bottom-color #000


html.creator_store_home .store-tab
html.creator-community .store-tab
  display block


.store
  .swiper-pagination-bullet
    border-radius 50%

.store
  display flex
  flex-direction column
  min-height 100vh

  @media screen and (max-width: 1024px)
    padding-top 80px
    min-height calc(100vh - 80px)
  &-header
    @import 'header.styl'

  &-body
    position relative
    padding-bottom 140px
    display flex
    flex-direction column
    align-items center
    width 100%

    .warning-suspended
      display flex
      width 72%
      height 30vh
      flex-direction column
      justify-content center
      align-items center
      padding-top 140px
      text-align center

      img
        width 113px
      p
        width 100%
        margin 8px 0 0
        font-size 16px
        font-weight 600
        line-height 1.63

    @media screen and (min-width: 1025px)
      min-height 700px

      .warning-suspended
        width 100%
        height 100%

        img
          width 143px
        p
          margin 16px 0 0
          font-size 26px
          font-weight 600
          line-height 42px

  &-banner
    width 100%
    position relative
    padding 0
    margin 0
    overflow hidden
    background-size cover
    background-position center
    @media screen and (min-width: 1025px)
      margin 0
      max-width 1120px
      width 96%
    .store-banner-img
      width 100%
      font-size 0
      img
        width 100%
        border-radius 2px

  &-tab
    display none
    margin 12px 0 26px
    @media screen and (min-width: 1025px)
      margin 30px 0
      ul
        li
          margin 0 40px !important
          font-size 23px !important
          border-bottom-width 3px !important
    ul
      display flex
      justify-content center
      align-items stretch
      margin 0
      padding 0
      list-style none
      li
        display flex
        align-items stretch
        margin 0 17px
        padding 0
        font-size 14px
        font-weight bold
        line-height 1.7
        letter-spacing -0.5px
        color #000
        box-sizing border-box
        border-bottom 2px solid #fff
        &:hover
        &:active
          border-bottom-color #000
        a
          display flex
          align-items center

    &-contents
      display none !important
      width 100%
      left 0
      right 0
      max-width 1120px
      margin 0 auto
      position absolute
      bottom 48px
      @media screen and (min-width: 1025px)
        width 96%

    &-title
    &-description
      margin 0
      color white

    &-title
      font-size 24px
      margin-bottom 16px

    &-description
      font-size 16px

  &-description
    display none
    text-align center
    margin-bottom 40px
    font-size 16px
  &-footer
    @import "footer.styl"

html.creator_store
  .textLauncherIcon
    display none !important
