.mps-tooltip-icon
  display inline-block
  width ptr(20)
  height ptr(20)
  vertical-align sub
  color $black
  &[aria-expanded="true"]
    color $blue
  &--question-hover
    display none

.mps-tooltip-icon + div[data-tippy-root], .mshop-cart div[data-tippy-root]
  & > .tippy-box
    background-color #EBF3FE99
    border-radius 16px
    overflow hidden
    backdrop-filter blur(20px)
    -webkit-backdrop-filter blur(20px)

.mps-tooltip-container
  box-sizing border-box
  color $blue-100
  border none
  padding 16px
  position relative
  text-align left
  box-shadow none

  .mps-tooltip-icon + & {
    position absolute
    width 0
    height 0
    display none
  }

  h4
    color $blue-100
    margin-bottom 8px
  p
    color $blue-100
    padding 0
    margin-bottom 4px
    display flex
    column-gap 8px

