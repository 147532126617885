$kr-48-b =
  font-family: Pretendard
  font-size: pxToRem(48px);
  font-style: normal;
  font-weight: 700;
  line-height: pxToRem(60px); /* 139.286% */
  letter-spacing: pxToRem(-0.2px);

$kr-36-m =
  font-family: Pretendard
  font-size: pxToRem(36px);
  font-weight: 500;
  line-height: pxToRem(50px); /* 138.889% */
  letter-spacing: pxToRem(-0.2px);

$kr-32-b =
  font-family: Pretendard
  font-size: pxToRem(32px);
  font-weight: 700;
  line-height: pxToRem(45px); /* 140.625% */
  letter-spacing: pxToRem(-0.2px);

$kr-32-m =
  font-family: Pretendard
  font-size: pxToRem(32px);
  font-weight: 500;
  line-height: pxToRem(45px); /* 140.625% */
  letter-spacing: pxToRem(-0.2px);

$kr-28-b =
  font-family: Pretendard
  font-size: pxToRem(28px);
  font-style: normal;
  font-weight: 700;
  line-height: pxToRem(39px); /* 139.286% */
  letter-spacing: pxToRem(-0.2px);

$kr-28-m =
  font-family: Pretendard
  font-size: pxToRem(28px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(39px); /* 139.286% */
  letter-spacing: pxToRem(-0.2px);

$kr-28-r =
  font-family: Pretendard
  font-size: pxToRem(28px);
  font-weight: 400;
  line-height: pxToRem(39px); /* 139.286% */
  letter-spacing: pxToRem(-0.2px);

$kr-24-b =
  font-family: Pretendard
  font-size: pxToRem(24px);
  font-weight: 700;
  line-height: pxToRem(34px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$kr-24-m =
  font-family: Pretendard
  font-size: pxToRem(24px);
  font-weight: 500;
  line-height: pxToRem(34px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$kr-24-r =
  font-family: Pretendard
  font-size: pxToRem(24px);
  font-weight: 400;
  line-height: pxToRem(34px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$kr-20-b =
  font-family: Pretendard
  font-size: pxToRem(20px);
  font-weight: 700;
  line-height: pxToRem(28px); /* 140% */
  letter-spacing: pxToRem(-0.2px);

$kr-20-m =
  font-family: Pretendard
  font-size: pxToRem(20px);
  font-weight: 500;
  line-height: pxToRem(28px); /* 140% */
  letter-spacing: pxToRem(-0.2px);

$kr-20-r =
  font-family: Pretendard
  font-size: pxToRem(20px);
  font-weight: 400;
  line-height: pxToRem(28px); /* 140% */
  letter-spacing: pxToRem(-0.2px);

$kr-16-b =
  font-family: Pretendard
  font-size: pxToRem(16px);
  font-weight: 700;
  line-height: pxToRem(24px); /* 150% */
  letter-spacing: pxToRem(-0.2px);

$kr-16-m =
  font-family: Pretendard
  font-size: pxToRem(16px);
  font-weight: 500;
  line-height: pxToRem(24px); /* 150% */
  letter-spacing: pxToRem(-0.2px);

$kr-16-r =
  font-family: Pretendard
  font-size: pxToRem(16px);
  font-weight: 400;
  line-height: pxToRem(24px); /* 150% */
  letter-spacing: pxToRem(-0.2px);

$kr-16-r--underline =
  font-family: Pretendard
  font-size: pxToRem(16px);
  font-weight: 400;
  line-height: pxToRem(24px); /* 150% */
  letter-spacing: pxToRem(-0.2px);
  text-decoration-line: underline;

$kr-14-b =
  font-family: Pretendard
  font-size: pxToRem(14px);
  font-weight: 700;
  line-height: pxToRem(22px); /* 157.143% */
  letter-spacing: pxToRem(-0.2px);

$kr-14-m =
  font-family: Pretendard
  font-size: pxToRem(14px);
  font-weight: 500;
  line-height: pxToRem(22px); /* 157.143% */
  letter-spacing: pxToRem(-0.2px);

$kr-14-r =
  font-family: Pretendard
  font-size: pxToRem(14px);
  font-weight: 400;
  line-height: pxToRem(22px); /* 157.143% */
  letter-spacing: pxToRem(-0.2px);

$kr-14-r--underline =
  font-family: Pretendard
  font-size: pxToRem(14px);
  font-weight: 500;
  line-height: pxToRem(22px); /* 157.143% */
  letter-spacing: pxToRem(-0.2px);
  text-decoration-line: underline;

$kr-12-b =
  font-family: Pretendard
  font-size: pxToRem(12px);
  font-weight: 700;
  line-height: pxToRem(17px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$kr-12-m =
  font-family: Pretendard
  font-size: pxToRem(12px);
  font-weight: 500;
  line-height: pxToRem(17px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$kr-12-r =
  font-family: Pretendard
  font-size: pxToRem(12px);
  font-weight: 400;
  line-height: pxToRem(17px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$kr-10-m =
  font-family: Pretendard
  font-size: pxToRem(10px);
  font-weight: 500;
  line-height: pxToRem(14px); /* 140% */
  letter-spacing: pxToRem(-0.2px);

$kr-10-r =
  font-family: Pretendard
  font-size: pxToRem(10px);
  font-weight: 400;
  line-height: pxToRem(14px); /* 140% */
  letter-spacing: pxToRem(-0.2px);

$en-60-bebas-b =
  font-family: "Bebas Neue Pro";
  font-size: pxToRem(60px);
  font-style: normal;
  font-weight: 700;
  line-height: 90%; /* 54px */

$en-40-bebas-b =
  font-family: "Bebas Neue Pro";
  font-size: pxToRem(40px);
  font-style: normal;
  font-weight: 700;
  line-height: 90%; /* 36px */
  text-transform: uppercase;

$en-24-bebas-b =
  font-family: "Bebas Neue Pro";
  font-size: pxToRem(24px);
  font-style: normal;
  font-weight: 700;
  line-height: pxToRem(24px); /* 100% */
  letter-spacing: pxToRem(-0.2px);
  text-transform: uppercase;

$en-24-unica-b =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(24px);
  font-style: normal;
  font-weight: 700;
  line-height: pxToRem(34px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$en-24-unica-m =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(24px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(34px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$en-24-unica-r =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(24px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(34px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$en-20-unica-b =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(20px);
  font-style: normal;
  font-weight: 700;
  line-height: pxToRem(28px); /* 140% */
  letter-spacing: pxToRem(-0.2px);

$en-20-unica-m =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(20px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(28px); /* 140% */
  letter-spacing: pxToRem(-0.2px);

$en-20-unica-r =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(20px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(28px); /* 140% */
  letter-spacing: pxToRem(-0.2px);

$en-16-unica-b =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(16px);
  font-style: normal;
  font-weight: 700;
  line-height: pxToRem(22px); /* 137.5% */
  letter-spacing: pxToRem(-0.2px);

$en-16-unica-m =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(16px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(22px); /* 137.5% */
  letter-spacing: pxToRem(-0.2px);

$en-16-unica-r =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(16px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(22px); /* 137.5% */
  letter-spacing: pxToRem(-0.2px);

$en-14-unica-b =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(14px);
  font-style: normal;
  font-weight: 700;
  line-height: pxToRem(20px); /* 142.857% */
  letter-spacing: pxToRem(-0.2px);

$en-14-unica-b--underline =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(14px);
  font-style: normal;
  font-weight: 700;
  line-height: pxToRem(20px); /* 142.857% */
  letter-spacing: pxToRem(-0.2px);
  text-decoration-line: underline;

$en-14-unica-m =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(14px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(20px); /* 142.857% */
  letter-spacing: pxToRem(-0.2px);

$en-14-unica-r =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(14px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(20px); /* 142.857% */
  letter-spacing: pxToRem(-0.2px);

$en-12-unica-b =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(12px);
  font-style: normal;
  font-weight: 700;
  line-height: pxToRem(17px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$en-12-unica-m =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(12px);
  font-style: normal;
  font-weight: 500;
  line-height: pxToRem(17px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);

$en-12-unica-r =
  font-family: "Neue Haas Unica Pro";
  font-size: pxToRem(12px);
  font-style: normal;
  font-weight: 400;
  line-height: pxToRem(17px); /* 141.667% */
  letter-spacing: pxToRem(-0.2px);