@media screen and (min-width: 1045px) and (min-height: 800px)
  html.prev_product_matcher
    .prev_product_matcher >.bottom
      .options
        margin 26px 0 !important
      >.header
        margin 24px auto !important

@media screen and (min-width: 1025px)
  html[id="dream_factory"]
    #marpplizer
      .product_editor
        .options
          display flex
          gap 6px
          .open_chooser
            display none
  html.maker_page.projection:not(.can_product_change)
    #marpplizer
      .product_editor
        .one_size
        .color
          display none
    .decoration_menu_for_pc
      .open_chooser
        display none
  html.all_faces_mode
    #marpplizer:after
      z-index 2
      opacity 0.85
  html.maker_page#dream_factory
    #marpplizer
      .buying
        display none !important
  body.maker_page
    #tooltip_canvas
    .msg.pinch
    .fake_mp_maker
    .footer_buttons
    .more_btn
    .pinch_btn
    .super_menu
      display none
    #maker_frame
      width 100% !important
    .maker_menu:after
    #marpplizer:after
      z-index -1000
      content ''
      display block
      background #fff
      opacity 0
      position absolute
      top 0
      left 0
      right 0
      bottom 0
      transition opacity 0.2s
    #maker
      .btn_tip
        cursor pointer
        &.how_to_custom
          top 113px
          .icon
            top 19px
            left 32px
            img
              width 26px
        &.enter_marpple_shop
          top calc(113px + 68px + 16px)
          .icon
            left 37px
            top 25px
            img
              width 18px
          html.creator &
          html#dream_factory &
          html[collabo_type="line"] &
            display none
        position absolute
        left 0
        width 200px
        height 68px
        overflow hidden
        padding-left 74px
        border-top-right-radius 100px
        border-bottom-right-radius 100px
        box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.05)
        background-color #fff
        box-sizing border-box
        transition width 0.4s
        &[is_empty="true"]
          display none !important
        .icon
          position absolute
          cursor pointer
        .txt1
          position absolute
          top 26px
          left 74px
          color color_black
          font-size 14px
          font-weight bold
          opacity 1
          transition-property opacity
          transition-duration 0.2s
          transition-delay 0.2s
        .btn_close
          display none
        .is_open
          opacity 0
        &.tip_open
          width 380px
          transition width 0.3s
          .txt1
            opacity 0
            transition opacity 0s
          .is_open
            opacity 1
            transition-property opacity
            transition-duration 0.2s
            transition-delay 0.2s
          .txt2
            position absolute
            top 18px
            left 74px
            width 250px
            color color_black
            font-size 14px
            font-weight bold
            cursor pointer
          .txt3
            position absolute
            top 39px
            left 74px
            width 250px
            color color_black
            font-size 12px
            cursor pointer
          .btn_close
            position absolute
            top 10px
            right 30px
            opacity 1
            transition-property opacity
            transition-duration 0.6s
            transition-delay 0.4s
            cursor pointer
            img
              width 8px
    .mp_maker
      overflow hidden
      position relative
      background #f8f9fa !important
  html.prev_product_matcher
    .prev_product_matcher
      height 332px
      display block !important
      .new_bp_name
        position absolute
        top 2px
      .options
        margin 10px 0
        justify-content center
        button
          width 260px
          height 50px
      .design
        width 104px
        height 104px
        box-sizing border-box
      .bottom
        position absolute
        bottom 2px !important
        right 1px !important
        .header
          margin 12px auto
    .select_face
      display none !important
  html.all_faces_mode
    .decoration_menu_for_pc
    #marpple_layer
      display none
.maker-color-picker
  display flex
  flex-direction column
  justify-content center
  &__title
    text-align center
    margin 5px 0 16px 0
  &__list
    display flex
    flex-wrap wrap
  &__item
    display flex
    justify-content center
    cursor pointer
    margin 6px
    border-radius 100%
    span
      box-sizing border-box
      width 24px
      height 24px
      border-radius 100%
      margin 1px
      &.too_bright
        border 1px solid #CED4DA
    &.original_tag
      span
        background url(//s3.marpple.co/files/u_1187078/2023/10/original/4b0a81febbcf4ebb28d05033ce040cb71b9e32e71.png) center / 27px no-repeat
        border none
        border-radius 0
      &.selected
        box-shadow unset
    &.selected
      box-shadow: 0 0 0 1px #ff6b00
    &.used
      &::before
        content ""
        background-color #555
        width 4px
        height 4px
        border-radius 100%
        margin-top -8px
