.embed
  height 0
  visibility hidden
  overflow hidden

.embed_show
  text-align center
  margin ptr(16) 0;
  position relative

  a {
    color black
    text-decoration none
  }


  @media screen and (max-width: 374px)
    iframe.instagram-media
      margin-left calc((326px - 100%) / 2 * -1) !important
  iframe
    margin 0 auto !important

  .twitter-tweet
    margin auto

  .renewal_marpple_embed_preview
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: ptr(16);
    row-gap: ptr(16);
    &.horizontal
      grid-template-columns: unset;
      row-gap: ptr(8);

.marpple_embed
  display flex
  justify-content space-between
  flex-wrap wrap

  .embed_option
    position absolute
    top 0
    right 0
    width 40px
    z-index 1
    cursor pointer
    background-color transparent
    padding 0
    border 0
    &:focus
      outline none
    img
      width 40px
    .options
      display none
      position absolute
      right 0
      border-radius 2px
      box-shadow 0 1px 2px 0 rgba(0, 0, 0, 0.3)
      border solid 1px #e5e5e5
      background-color #ffffff
      .option
        padding 13px 29px
        white-space nowrap
        &:hover
          background-color #3c89f9
          border-radius 2px
          color white
    &.opened
      .options
        display block

.og_embed.og_simple_card
  width 80%
  min-width 500px
  height 162px;
  display flex
  border 1px solid rgb(222, 222, 222)
  text-align left
  font-size 0.8em
  margin 0 auto 1.25em
  box-sizing border-box

  .content
    padding 1.3em 1em
    display inline-flex
    justify-content center
    flex-direction column
    flex-grow 1
    box-sizing border-box

    p
      margin-bottom 15px

    .og_title
      font-size 1.1em

    .og_description
      font-size 0.9em

    .site
      display flex
      align-items center
      color #9e9e9e
      font-size 14px
    .icon
      margin-right 8px
      height 18px

  .og_image
    overflow hidden
    width 160px
    height 160px
    display inline-flex
    align-items center
    border-right 1px solid rgb(222, 222, 222)
    flex-shrink 0

    img
      display block
      max-width 160px

  .og_title
    font-weight 700

.og_embed.og_card
  width 50%
  font-size 0.7em
  margin 0 auto 1.25em
  text-align left
  border 1px solid #dedede
  box-sizing border-box
  position relative
  @media screen and (max-width: 480px)
    width 90%
  .og_image
    width 100%
    img
      width 100%
      display block
  .content
    width 100%
    border-top 1px solid #dedede
    padding 1.1em
    box-sizing border-box
    .og_title
      font-size 1.1em
      font-weight bold
      margin-bottom 10px
    .og_description
      margin-bottom 13px
    .site
      display flex
      align-items center
    .icon
      height 18px
      margin-right 1em
    .og_sitename
      color #9e9e9e

.embed_container
  height 1.5em
  margin-bottom 1em
  position relative
  .embed_input
    width 100%
    border 0
    margin 0
    padding 0
    height 1.5em
    font-size 1em
    color #9e9e9e
    font-style italic
    &:focus
      outline none
    &::placeholder
      color #81d4fa

&.editable
  .embed_show
    &:hover
      outline 1px solid #bdbdbd
      > *
        opacity 0.8
      .custom-toolbar
        opacity 1
    &:before
      content ''
      width 100%
      height 100%
      position absolute
      left 0
      z-index 1

.marpple_embed_show
  text-align left

.popup-store-products-preview
  display flex
  flex-direction column
  align-items center
  gap ptr(40)
  padding ptr(80) ptr(32)
  background var(--Black, #141414)
  &__text
    $flex(center, stretch, column)
    gap ptr(24)
    text-align center
    color $white
  &__title
    color var(--White, #FFF)
    text-align center
    font-family "Bebas Neue Pro"
    font-size ptr(40)
    font-style normal
    font-weight 700
    line-height 90%
    text-transform uppercase
  &__description
    color var(--White, #FFF)
    text-align center
    font-family Pretendard
    font-size ptr(24)
    font-style normal
    font-weight 700
    line-height ptr(34)
    letter-spacing ptr(-0.2)
  &__products
    $flex(center, center)
    padding 0 ptr(32)
    max-width ptr(1440)
  &__list
    $flex(flex-start, flex-start)
    gap ptr(24)
    max-width 100%
    flex-wrap nowrap
    overflow-x auto
    overflow-y hidden
  &__item
    width ptr(326)
    flex-shrink 0
    a
      color $white !important

