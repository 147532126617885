@import 'helper.styl'

&
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 40px;
  @media screen and (max-width 1024px)
    padding: 30px 20px 40px;

  .category-items
    plain-list()
    margin-left -10px

    @media screen and (max-width 1024px)
      display flex
      justify-content flex-start
      flex-wrap wrap

    > *
      width calc(25% - 12px)
      display inline-block
      cursor pointer
      margin-left: 10px;
      position relative

      @media screen and (max-width 1024px)
        flex-basis calc(33.33333% - 10px)
        display block
        cursor pointer
        margin-bottom 20px
        br
          display none


      img
        width 100%
        @media screen and (max-width 1024px)
          margin-bottom 10px
          border-radius 4px

      [data-name]
        display block
        font-size 16px
        font-weight 500
        text-align center
        word-break keep-all
        @media screen and (max-width 1024px)
          display block
          text-align center
          font-size 11px
          line-height 1.27
          font-weight normal
          letter-spacing -0.5px
          color #000000

      [data-count]
        display none
      /* TODO 카테고리 하드코딩
          --- 기획상품 */
      &[data-cate_item_id="68"]
        display none
        html[mp_worker_policy="true"] &
          display inline-block
