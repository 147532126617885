.don_tab[tab_name="/modules/Creator/MobileMaker/S/Mui/tab.js"]
  .mp_maker
    .product_menu
      display none
  .detail_wrapper
    .size_list
      display none
  .for_line
    display none !important
  .enter_marpple_shop
    display none
