#maker_frame
  &[data-is_digital_product="true"]
    #marpplizer
      .product_editor
        .color.section
        .price
          display none
  &[data-is_vector="true"]
    #marpplizer
      #to_vector_editor
        display block !important
    .btn_tip
    .decoration_menu_for_pc
    .maker_menu
      display none
      html#dream_factory &
        display block
  #marpplizer
    color #212529
    font-size 14px
    position absolute
    right 0
    top 1px
    bottom 2px
    box-sizing border-box
    width 460px
    background #fff
    .general_option_buttons
      html[id="dream_factory"] &
        display none
      .border_box
        position absolute
        top 9px
        left 7px
        right 7px
        height 36px
        color #fff
        .border
          height 32px
          opacity 0.2
          border dashed 1px #ffffff
        >.deco
          width 3px
          height 3px
          background-color color_active
          border solid 1px #ffffff
          position absolute
          opacity 0.6
          border-radius 20%
        .text
          position absolute
          height 17px
          top 50%
          left 50%
          transform translateX(-50%) translateY(-50%)
        .one
          top -1px
          left -1px
        .two
          top -1px
          right -1px
        .three
          bottom -1px
          left -1px
        .four
          bottom -1px
          right -1px
      html[marpplizer_mode="short"] &
        #to_vector_editor
        #to_next
          height 42px
        .border_box
          position absolute
          top 7px
          left 10px
          right 10px
          height 30px
          .border
            height 28px
          .one
            top -1px
            left -1px
          .two
            top -1px
            right -1px
          .three
            bottom -1px
            left -1px
          .four
            bottom -1px
            right -1px
    .activity
      overflow hidden
    .marpplizer_footer
      z-index 2
    .marpplizer_wrapper
      z-index 1
      .activity
        z-index 2
    .maker_range_wrapper
      >.body
        height 40px
        display flex
      .range_wrapper
        flex-grow 76
      .blank
        flex-grow 4
      .number_wrapper
        flex-grow 20
    .head
      font-size 14px
      margin-bottom 8px
      font-weight bold
    .iscroll_wrapper
      box-sizing border-box
      overflow hidden
    >.iscroll_wrapper
      height 100%
    .marpplizer_back_btn_styl
      position absolute
      top 0
      left 0
      display block
      padding 0
      border none
      width 19px
      height 19px
      cursor pointer
      background url(//s3.marpple.co/files/u_14355/2018/11/original/f_12438_1541409323249_q9vQ8BNlU4U9lrAq6ER5V.png) no-repeat
      background-size 19px auto
      background-position 50%
      &:focus
        outline none
      &:active
        background-color #ced4da
    html#dream_factory &
      padding-bottom 0
    .marpplizer_wrapper
      >.home
        background #fff
      .main_head
        height 24px
        font-size 20px
        >.option
          position relative
          top -23px
      .activity
        opacity 1
        height: 100%
        position absolute
        background #fff
        top 0
        .section
          padding-bottom 32px
          &.size
            padding-bottom 32px
        .cv_text_editor
          .section
            padding-bottom 24px
          .controls
            padding-bottom 16px
        .cv_image_editor
          .controls
            padding-bottom 24px
          .marstagram_editor
            display none !important
            html[mp_worker_policy="true"] &
              display block !important
          .image_editor
          .marstagram_editor
            display block
            margin-top 30px
            .title
              font-weight bold
              height 17px
              font-size 14px
            .buttons
              display flex
              gap 8px
              justify-content space-between
              button
                margin-top 12px
                flex-grow 1
                position relative
                height 40px
                background-color #fff
                border solid 1px #d5dbe0
              img
                position absolute
                left 12px
                width 16px
                height 16px
              span
                margin-left 8px
        .dosu
          button
            padding 10px 16px
            justify-content center
            align-items center
            border-radius 100px
            border 1px solid black !important
            background transparent
            *
              color black
              font-family: Pretendard;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
            &:focus
              outline none
            &:active
              background-color #ced4da

    .activity >.product_editor
        position relative
        .head
          font-size 14px
          margin-bottom 8px
        .color.section
          >.head
            span
              margin-left 7px
              font-weight normal
              &:before
                content "-"
                margin-right 5px
        .product
          >.body
            margin 0
            .name
              margin-bottom 8px
          >.options
            button
              background-color #ffffff
              border solid 1px #d5dbe0
              text-align center
              font-size 13px
        .open_chooser
          margin-top 20px
          background-size 16px
          padding 5px
          &:focus
            outline none
          &:active
            background-color #ced4da
        .size
          .one_size
            display none
          position relative
          ul
            position relative
            margin 0
            padding 0
            list-style none
            display flex
            flex-wrap wrap
          li
            box-sizing border-box
            text-align center
            cursor pointer
            margin-right 8px
            border solid 1px #d5dbe0
            font-size 14px
            color color_black
            height 40px !important
            line-height 40px !important
            margin-bottom 8px
            &.selected
              color color_active
              border-color color_active
          &[more_than_one="false"]
            li
              flex-grow 1
          li.sold_out
            position relative
            font-size 12px !important
            line-height 26px !important
            &:before
              content "일시 품절"
              html[lang="en"] &
                content "Sold Out"
              html[lang="jp"] &
                content "品切れ"
              color #878b8f
              width 100%
              position absolute
              top 70%
              left 0
              font-size 10px
              line-height 0
              text-align center
          &[length="1"]
            li
              &.selected
                color color_black
                border 0 none
                min-width 69px !important
                text-align left
              &.sold_out
                &:before
                  text-align left
        .main_head
          display none
        .product.section
          >.body
            font-size 20px
            .review_summary
              display none
      .activity >.cv_background
        .main_head
          margin-bottom 40px
      .activity >.cv_image_editor
        .main_head
          margin-bottom 40px
        > div:not(.main_head)
          >.body >.head
            margin-bottom 8px
        .change_img
          display none
          html[collabo_type="line"] &
            display block
          >button
            border 1px solid #d5dbe0
            background #fff
            padding 4px 9px
            &:focus
            &:hover
              background #000
              color #fff
        >.section
          &.image_color >.body
            >.head
              display flex
              .color_name
                font-weight normal
                margin-left 4px
                html[lang="kr"] &
                  &:before
                    content "-"
                    margin-right 4px
            .image_color_item
              display inline-block
              position relative
              cursor pointer
              width 40px
              margin-right 8px
              height 40px
              padding 2px
              box-sizing border-box
              background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_13771_1542338784965_Nb0oHE4U7Ox4GuPzLlk.png) no-repeat 50% 50%
              background-size 40px
              &:nth-of-type(8n)
                margin-right 0
              &.selected
                border 1px solid color_active
              img
                position absolute
                top 0
                left 0
                right 0
                bottom 0
                margin auto
                max-width 80%
                max-height 80%
      .activity >.cv_text_editor
        .main_head
          margin-bottom 32px
        .press_color_list
          span.color_name
            font-weight 400
            width auto
            padding-left 7px
            &:before
              content "-"
              margin-right 5px
              font-weight 200
              width 5px
              height 3px
      .activity >.size_face
        .selected
          border 1px solid blue
        [data-checked]
          display none
        [data-checked = "true"]
          display block
        .main_head
          margin-bottom 32px
        .marpplizer_flex_v
          display flex
          justify-content space-around
          margin-bottom 16px
#maker_frame:not(.from_library)
  #marpplizer
    .marpplizer_wrapper
      .activity
        .cv_image_editor
          .marstagram_editor
            display none
@media screen and (min-width: 1025px)
  html.maker_page[marpplizer_mode="basic"]
    #marpplizer
      width 460px !important
      .activity
        >.body
          width 460px !important
          padding 0 40px 0
          box-sizing border-box
          >.main_head
            text-align center
            padding-top 40px
          >.product.section
            padding-top 40px
    .maker_menu
    #maker_frame .board
      right 462px !important
    #maker_frame .select_face
      right 500px !important
    #maker_frame .loc_optimization_indicator
      right 630px !important
    #marpplizer .cv_text_editor .style button
      width 52px
      height 38px
    #marpplizer .cv_text_editor .visibility_hidden
      display block
    #marpplizer
      .color_picker.mkf
        .color_item
          width 28px !important
          height 28px !important
          margin 0 11px 8px 0 !important
          span
            width 24px !important
            height 24px !important
        .color_item:nth-child(10n)
          margin 0 0 8px 0 !important
      .image_color_item
        width 40px
        height 40px
        margin-right 8px
  html.maker_page[marpplizer_mode="short"]
    #maker_frame
      #marpplizer
        width 320px !important
        .activity
          >.body
            width 320px !important
            padding 0 30px !important
            box-sizing border-box
            >.main_head
              text-align center
              padding-top 40px
            >.product.section
              padding-top 40px
      .maker_menu
      .board
        right 322px !important
      .select_face
        right 360px !important
      .loc_optimization_indicator
        right 480px !important
      #marpplizer .cv_text_editor .style button
        width 36px
        height 36px
      #marpplizer .cv_text_editor .visibility_hidden
        display none
      #marpplizer
        .color_picker.mkf
          .color_item
            width 26px !important
            height 26px !important
            margin 0 7px 7px 0 !important
            span
              width 22px !important
              height 22px !important
          .color_item:nth-child(8n)
            margin 0 0 7px 0 !important
        .image_color_item:nth-of-type(8n)
          margin-right 8px !important
        .image_color_item:nth-of-type(5n)
          margin-right 0 !important
@media screen and (min-width: 1025px) and (max-height: 760px)
  html.maker_page[marpplizer_mode="basic"]
    #maker_frame .select_face
      right 580px !important
  html.maker_page[marpplizer_mode="short"]
    #maker_frame
      .select_face
        right 440px !important
