
@media screen and (min-width 1025px)
  body.product.detail
  body.maker_page
    .bp_info
      .bp_info_pop_buttons
        width 992px
        margin 70px auto 50px
        text-align center
        > span
          display inline-block
          padding 13px
          width 50%
          font-size 14px
          cursor pointer
          border 1px solid #d5dbe0
          box-sizing border-box
          -webkit-user-select none
          -moz-user-select none
          -ms-user-select none
          user-select none
          &:nth-child(odd)
            border-right 0 none
          &:nth-child(1)
          &:nth-child(2)
            display none
            border-bottom 0 none
          &:active
            background-color #eee

@media screen and (max-width 1024px)
  html.product.detail
  body.maker_page
    .bp_info
      background-color #eee
      .bp_info_pop_buttons
        display none
        background-color #fff
        margin 8px auto 0
        text-align center
        > span
          display inline-block
          padding 10px
          width 50%
          font-size 12px
          cursor pointer
          border 1px solid #d5dbe0
          box-sizing border-box
          &:nth-child(odd)
            border-right 0 none
          &:nth-child(1)
          &:nth-child(2)
            border-bottom 0 none
          &:active
            background-color #eee


html.maker_page body.maker_page .toggle_cont.print_guide
  display block
html.maker_page body.maker_page .toggle_cont.guide
  display none

body.product.detail
body.maker_page
  .toggle_cont.print_guide
    display none

html.product.detail
html.maker_page
  .don_frame[frame_name="mp.product.detail"]
    >.don_wrapper >.header
      display none
  .maker_go_next
    display none
  .navigation.home
    z-index 1001
    background white
    position relative
  .detail_wrapper
    width 100%
    position relative
    overflow hidden
    ul
      margin 0
  .fake_mp_maker
    position relative
    .wrapper
      position absolute
      top 0
      left 0
      right 0
      bottom 0
    -webkit-touch-callout none
    -webkit-touch-select none
    -ms-user-select none
    -moz-user-select none
    -webkit-user-select none
    -user-select none
    -webkit-tap-highlight-color rgba(0,0,0,0)
    >.left, >.right
      position absolute
      width 41px
      height 60px
      top 50%
      border none
      transform translateY(-50%)
      &:active
        opacity 0.3
    .left
      left 0
      background transparent url(//s3.marpple.co/files/u_193535/2018/10/original/f_11767_1540780825772_yl3V9ZHEgJKtbVURT.png) no-repeat 16px 20px
      background-size 9px 20px
    .right
      right 0
      background transparent url(//s3.marpple.co/files/u_193535/2018/10/original/f_11765_1540780825772_Ec5FSJl4q4V9M8mlLHn0R.png) no-repeat 16px 20px
      background-size 9px 20px
    .dots
      position absolute
      bottom 18px
      transform translateX(-50%)
      left 50%
      display flex
      &[faces_length="1"]
        display none
      .dot
        width 6px
        height 6px
        opacity 0.9
        background-color #d8d8d8
        margin 0 3px
        border-radius 50%
        transition transform 0.2s
      .dot.selected
        background-color #495057
        transform scale(1.2)
        transform-origin 50% 50%
      .dot._m
        transform scale(0.6)
        transform-origin 50% 50%
      .dot._s
        transform scale(0.3)
        transform-origin 50% 50%

  .product_detail_modal
    .buttons
      display flex
      >#add_to_cart
        width 100%
        background-color black
        text-align center
        color #ffffff
      >#buy
        background-color #ffffff
        border solid 1px #d5dbe0
        width 100%
        margin-right 8px

  .detail_wrapper
    .base_product
      .bp_info
        .custom_guide
          display none
  @media screen and (min-width 1025px)
    .detail_wrapper
      .product_color
        display none
        + .options
          display none
    .base_product.product_info_detail
      .bp_info
        background-color #fff
        margin-bottom 120px
      .bp_default_info
        padding-top 40px
        .text_info
          width 992px
          margin 76px auto 40px
          color color_black
          h2
            margin 0 0 6px
            padding 0
            font-size 24px
          .price
            margin-bottom 36px
            font-size 24px
            font-weight normal
          p
            margin 0
            padding 0
            font-size 14px
            html:not([lang="jp"]) &
              line-height 1.57
      .toggle_cont_img
        img
          max-width 100%
        span.a_left
          display block
          text-align left
          img
            width auto
        span.a_center
          display block
          text-align center
          img
            width auto
        span.a_right
          display block
          text-align right
          img
            width auto
      .product_info
      .print_guide
      .guide
        .tg_head
          margin-bottom 20px
        .product_info_text
        .print_guide_text
        .guide_text
          font-size 14px
          .task-list-item
            display none
          html:not([lang="jp"]) &
            line-height 1.57
          li
            padding 0 0 8px
          ul
          ol
            margin 12px 18px 20px
            padding 0
          table
            table-layout fixed
            border-collapse collapse
            width 100%
            img
              width 100%
            tbody
              td
                padding 5px 10px
        .brand
          position relative
          margin-top 30px
          min-height 60px
          padding-left 76px
          line-height 24px
          .brand_logo
            position absolute
            top 0
            left 0
            width 60px
            height 60px
            background-color #fafafa
            img
              position absolute
              top 0
              left 0
              right 0
              bottom 0
              margin auto
              max-width 56px
              max-height 56px
          .brand_name_en
            padding-top 4px
            font-size 14px
            font-weight bold
            color color_black
          .brand_description_en
            font-size 12px
            color color_black
      .toggle_cont
        margin 50px auto 0
        color color_black
        border-top 1px solid #000
        padding-top 50px
        .tg_head
          margin-bottom 24px
          font-size 20px
          font-weight bold
        &.product_info
        &.tg_reviews
          padding-top 0
          border-top 0 none
        &.tg_reviews
          margin-top 80px
          .mp_review_list
            margin -60px auto 40px
            .review_header
              height 16px
        &.product_info
          .tg_body
            font-size 14px
            html:not([lang="jp"]) &
              line-height 1.57
        &.tg_size
          table
            width 100%
            table-layout fixed
            border-collapse collapse
            text-align center
            th
            td
              padding 11px 0
              color #333
              font-size 12px
              border 1px solid #e9ecef
              font-weight 200
            tr:first-child
              th
                font-weight normal
                text-align center
                color color_black
                background-color #fafafa
            tr
              th:first-child
                font-weight bold
                color color_black
              td
                color color_black
          .size_tip
            &[has_size_info_url="true"]
              display flex
              .size_info_url
                flex none
                justify-content center
                align-content center
                margin-top 30px
                width 350px
                img
                  max-width 100%
              ul
                justify-content center
                align-content center
                margin-top 35px
                margin-left 20px
            ul
              margin 15px 0 0
              padding 0
              list-style none
              li
                padding-left 19px
                background url(//s3.marpple.co/files/u_18053/2018/10/original/f_5967_1539181292187_BzObFQ8cH7bFS4X1vL6F.png) no-repeat
                background-size 8px 8px
                background-position 3px 9px
                line-height 1.83
                margin-bottom 4px
          .size_txt1
            margin 12px 0 0
            padding 0 0 0 19px
            background url(//s3.marpple.co/files/u_18053/2018/10/original/f_5967_1539181292187_BzObFQ8cH7bFS4X1vL6F.png) no-repeat
            background-size 8px 8px
            background-position 3px 9px
            line-height 1.83

        &.tg_size_compare
          .size_compare_items
            overflow hidden
            .item
              float left
              margin-left 10px
              width 240px
              height 350px
              vertical-align top
              &:first-child
                margin-left 0
              .img
                height 300px
                img
                  height 100%
              .txt1
                margin-top 15px
              .txt1
              .txt2
                font-size 12px
                text-align center
        &.tg_print
          .print_area
            color color_black
            line-height 1.71
            .img1
              margin 0 0 36px
            .m
              display none
            h2
              margin 36px 0 15px
              font-size 14px
              font-weight bold
            h3
              margin 10px 0 4px
              padding 0
              font-size 14px
              font-weight bold
            ul.list1
              margin 0 0 0 18px
              padding 0
              li
                margin-bottom 8px
            p.txt3
              margin 0
              padding 0
              em
                color #ea291f
                font-style normal
            p.txt1
              margin 0 0 15px
              padding 0
            p.txt2
              margin 0
              padding 0
              color color_gray3
        &.tg_faq
          .faq_area
            color color_black
            line-height 1.71
            ul
              margin 0
              padding 0
              list-style none
              li
                margin-bottom 40px
              .q
                margin 0 0 15px
                color color_black
                font-size 14px
                font-weight bold
              .a
                padding 24px
                color color_black
                background-color #fafafa
                background-image url("//s3.marpple.co/files/u_14355/2019/3/original/f_23765_1552465457127_RqZ9K2oT2ly2zXLXukr.png")
                background-size 16px 16px
                background-position 0 0
                background-repeat no-repeat
        &.tg_delivery
          color color_black
          line-height 1.71
          h2
            margin 36px 0 15px
            font-size 14px
            font-weight bold
          p.txt1
            margin 0 0 14px
            padding 0
            em
              color #ea291f
              font-style normal
          ul
            margin 0
            padding 0
            list-style none
          table
            width 100%
            border-collapse collapse
            border-top 1px solid #e9ecef
            th
              color color_gray3
              width 156px
              font-weight normal
              text-align left
              box-sizing border-box
              background-color #fafafa
            th
            td
              font-size 14px
              vertical-align top
              padding 10px 5px 10px 24px
              border-bottom 1px solid #e9ecef
            td
              color color_black
            td
              .price_table
                cursor pointer
                text-decoration underline
              .map
                margin-top 14px
              .m
                display none
        &.tg_delivery
        &.tg_guide
          display none
        &.tg_guide_en
          border-bottom 1px solid #000
          .tg_body
            padding-bottom 40px
            p
              margin 20px 0 0
              line-height 22px

      .bp_thumb_swiper
        opacity 0
        position relative
        max-width 1440px
        margin 0 auto
        height 656px
        &[is_show="false"]
          display none
        &[is_vertical="true"]
          height 590px
          .swiper-slide
            width 800px
          .bp_thumb_icon
            .swiper-slide
              width 40px
              height 27px

        .swiper-container
          position absolute
          top 0
          left 0
          right 0
          bottom 56px
          width 100%
          margin 0 auto
        .swiper-slide
          width 480px
          background-repeat no-repeat
          background-size cover
          background-position 50% 50%
          background-color #eee
        .swiper-pagination
          display none
        .bp_thumb_icon
          position absolute
          top auto
          left 0
          right 0
          bottom 0
          height 40px
          text-align center
          .swiper-wrapper
            display inline-block
          .swiper-slide
            display inline-block
            width 32px
            height 40px
            margin 0 4px
            cursor pointer
  @media screen and (max-width 1024px)
    body.product.detail
    body.maker_page
      a
      button
        -webkit-tap-highlight-color transparent !important
      .detail_wrapper
        background-color #f5f5f5
      .bp_default_info
        .text_info
          display none !important
          padding 0 4%
          color color_black
          h2
          .price
            display none
          p
            margin 0 0 20px
            padding 20px 0 0
            font-size 14px
            line-height 1.71
      .toggle_cont.group_sale
        margin 2% 0
      .toggle_cont
        margin-top 8px
        background-color #fff
        &[is_open="true"]
          .tg_head .btn
            transform rotate(0)
            background-image url(//s3.marpple.co/files/u_29089/2018/11/original/f_12447_1541497245203_Og6M2qzZ9SKk3Esst9IS.png)
          .tg_body
            height auto
            opacity 1
            padding 4% 4% 40px
            min-height 50px
          &.toggle_cont_img
            p
              margin 12px 0 0
              padding 0
            img
              max-width 100%
            span.a_left
              display block
              text-align left
              img
                width auto
            span.a_center
              display block
              text-align center
              img
                width auto
            span.a_right
              display block
              text-align right
              img
                width auto
          &.product_info
          &.print_guide
          &.guide
            .tg_body
              padding 0 16px 30px
            .product_info_text
            .print_guide_text
            .guide_text
              margin-top -14px
              line-height 21px
              font-size 14px
              .task-list-item
                display none

              table
                table-layout fixed
                border-collapse collapse
                width 100%
                img
                  width 100%
                tbody
                  td
                    padding 5px 10px
              p
                margin 12px 0 0
                padding 0
              li
                padding 0
              ol
              ul
                margin 12px 18px 20px 7px
                padding 0
                list-style none
                li
                  padding-left 7px
                  text-indent -7px
                  &:before
                    content '∙ '
                    display inline-block
                    font-size 12px
              ul:first-child
                margin 8px 18px 20px 7px
              > *:first-child
                margin-top 10px
              > *:last-child
                margin-bottom 0
            .brand
              position relative
              margin-top 24px
              line-height 24px
              .brand_logo
                position absolute
                top 0
                left 0
                width 48px
                height 48px
                background-color #fafafa
                img
                  position absolute
                  top 0
                  left 0
                  right 0
                  bottom 0
                  margin auto
                  max-width 44px
                  max-height 44px
              .brand_name_en
                font-size 14px
                font-weight bold
                color color_black
                min-height 48px
                padding-top 11px
                padding-left 64px
                box-sizing border-box
              .brand_description_en
                margin-top 8px
                font-size 12px
                line-height 1.67
                color color_black
        &[is_open="false"]
          .tg_body
            height 0
            opacity 0
            overflow hidden
        .tg_head
          position relative
          padding 24px 16px
          .tit
            font-weight bold
          .btn
            position absolute
            top 0
            right 4%
            bottom 0
            width 20px
            background url(//s3.marpple.co/files/u_29089/2018/11/original/f_12447_1541497245203_Og6M2qzZ9SKk3Esst9IS.png) no-repeat center center
            background-size 14px 8px
            transform rotate(180deg)
        .tg_body
          p
            margin 0
            padding 0
          .mp_review_list
            padding 0
            margin 0 0 24px
            position relative
            overflow visible
            .review_header
              padding-bottom 0
              .photo_reviews
                margin 0 0 24px 0 !important
          .review_item
            &:first-child
              padding-top 0
            &:last-child
              border-bottom 0
      .toggle_cont.tg_size
        table
          width 100%
          table-layout fixed
          border-collapse collapse
          text-align center
          th
          td
            padding 11px 0
            color #333
            font-size 12px
            border 1px solid #e9ecef
            font-weight 200
          tr:first-child
            th
              font-weight normal
              text-align center
              color color_black
              background-color #fafafa
          tr
            th:first-child
              font-weight bold
              color color_black
              width 70px
            td
              color color_black
        .size_tip
          &[has_size_info_url="true"]
            .size_info_url
              margin-top 20px
              img
                max-width 100%
          ul
            margin 15px 0 0
            padding 0
            list-style none
            li
              padding-left 19px
              background url(//s3.marpple.co/files/u_18053/2018/10/original/f_5967_1539181292187_BzObFQ8cH7bFS4X1vL6F.png) no-repeat
              background-size 8px 8px
              background-position 3px 6px
              line-height 1.83
              margin-bottom 4px
              font-size 12px
        .size_txt1
          margin 12px 0 0
          padding 0 0 0 19px
          background url(//s3.marpple.co/files/u_18053/2018/10/original/f_5967_1539181292187_BzObFQ8cH7bFS4X1vL6F.png) no-repeat
          background-size 8px 8px
          background-position 3px 6px
          line-height 1.83
          font-size 12px
      .toggle_cont.tg_size_compare
        .size_compare_items
          .item
            margin-left 10px
            width 230px
            vertical-align top
            &:first-child
              margin-left 0
            .img
              position relative
              padding-top 125.22%
              img
                position absolute
                top 0
                left 0
                right 0
                bottom 0
                width 100%
            .txt1
              margin-top 15px
            .txt1
            .txt2
              font-size 12px
              text-align center

      .toggle_cont.tg_print
        .print_area
          margin 0 0 40px
          padding 1px 0 0
          color color_black
          line-height 1.83
          font-size 12px
          h2
            margin 24px 0 8px
            padding 0
            font-size 12px
            font-weight bold
            &:first-child
              margin-top 0
          .img1
            margin 0 0 10px
            img
              width 100%
          .pc
            display none
          ul.list1
            margin 0 0 0 18px
            padding 0
            li
              margin-bottom 8px
          h3
            margin 0
            padding 0
            font-size 12px
            font-weight bold
          p.txt3
            margin 0
            padding 0
            em
              color #ea291f
              font-style normal
          p.txt1
            margin 0 0 15px
            padding 0
          p.txt2
            margin 0
            padding 0
            color color_gray3
      .toggle_cont.tg_delivery
        .tg_body
          padding-left 0
          padding-right 0
          padding-bottom 0
        .delivery_area
          margin 0
          padding 0
          color color_black
          line-height 1.83
          font-size 12px
          p.txt1
            margin 0 6% 14px
            padding 0
            em
              color #ea291f
              font-style normal
          ul
            margin 0 6% 6%
            padding 0
            list-style none
          .delivery_table
            padding 6%
            background-color #fafafa
            h2
              margin 6% 0 14px
              padding 6% 0 0
              font-size 12px
              font-weight bold
              border-top 1px solid #d5dbe0
              &:first-child
                margin-top 0
                padding-top 0
                border-top 0 none
          table
            width 100%
            border-collapse collapse
            th
              color color_gray3
              width 59px
              font-weight normal
              text-align left
              box-sizing border-box
            th
            td
              font-size 12px
              vertical-align top
              padding 0 0 6px
            td
              color color_black
            td
              .price_table
                cursor pointer
                text-decoration underline
              .map
                position relative
                margin-top 14px
                padding-top 38%
                a
                  position absolute
                  top 0
                  left -59px
                  right 0
                  display block
                img
                  width 100%
              .pc
                display none
      .toggle_cont.tg_faq
        .tg_body
          padding-left 0
          padding-right 0
          padding-bottom 0
        .faq_area
          margin 0
          padding 5px 0 0
          color color_black
          line-height 1.83
          font-size 12px
          ul
            margin 0
            padding 0
            list-style none
            li
              margin-bottom 24px
              &:last-child
                margin-bottom 0
            .q
              margin 0 0 15px
              padding 0 6%
              color color_black
              font-weight bold
            .a
              padding 6%
              color color_black
              background-color #fafafa
              background-image url("//s3.marpple.co/files/u_14355/2019/3/original/f_23765_1552465457127_RqZ9K2oT2ly2zXLXukr.png")
              background-size 16px 16px
              background-position 6% 0
              background-repeat no-repeat
      .toggle_cont.tg_guide
        .guide_area
          margin 0 0 6%
          color color_black
          font-size 12px
          line-height 1.83
          h2
            margin 6% 0 3%
            font-size 12px
            font-weight bold
            &:first-child
              margin-top 0
          h3
            margin 2% 0 4px
            padding 0
            font-size 12px
            font-weight bold
          ul.list1
            margin 0
            padding 0
            list-style none
          ul.list2
            margin 0 0 0 16px
            padding 0
            li
              color color_gray3
          p.txt1
            margin 0
            padding 0
            color color_gray3
          p.txt2
            margin 6% 0 0
            padding 0
          p.txt3
            margin 0
            padding 0
            color color_gray3
            a
              color color_gray3
      .toggle_cont.tg_guide_en
        .tg_body
          p
            margin 20px 0 0
            line-height 22px
            &:first-child
              margin-top 0
      [is_show="false"]
        display none
      .bp_default_info
        margin-top 8px
        padding 11% 0 10%
        background-color #fff
        > p
          margin 0
          padding 0 4% 4%
          font-size 12px
          line-height 1.67
          color #495057
      .bp_thumb_swiper
        opacity 0
        position relative
        &[is_vertical="true"]
          .thumbnails_area
            padding-top 55.47%
          .thumbnails_icon_area
            padding-top 6%

        .thumbnails_area
          position relative
          width 100%
          padding-top 104%
          .swiper-container
            position absolute
            top 0
            left 0
            right 0
            bottom 0
          .swiper-slide
            width 83.202%
            height 100%
            background-repeat no-repeat
            background-size cover
            background-position center
            background-color #eee
          .swiper-button-prev
          .swiper-button-next
          .swiper-pagination
            display none
        .thumbnails_icon_area
          position relative
          width 87.202%
          margin 3.8% auto 0
          padding-top 10.67%
          overflow hidden
          .bp_thumb_icon
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            text-align center
            .swiper-wrapper
              display inline-block
            .swiper-slide
              display inline-block
              width 8.536%
              //width 32px;
              height 100%
              margin 0 1.90%
              background-repeat no-repeat
              background-size cover
              background-position center
              background-color #eee
      .help_center
        .help_head
          padding 8% 8%
          text-align center
      .don_page[page_name='mp.review.list']
        >.don_wrapper
          .mp_review_list
            margin 0
            padding 0 16px 40px
            .review_header
              h1.review_title
                margin-bottom 8px
body.product.detail
body.maker_page
  .photo_reviews
    border-left 0 !important
    padding-left 0 !important
    margin 18px 0 0 0 !important
  .mp_review_list
    .review_item
      .info
        .right_info
          margin-left 0
    #cate_list_selector
      display none !important
  .don_frame[frame_name='mp.review.list']
    >.don_wrapper
      background #fff
      >.header
        height 56px !important
        background #fff
        z-index 3
      .don_page[page_name='mp.review.list']
        background-color #fff
        >.don_wrapper
          background-color #fff
          .photo_reviews
            display none
  .detail_wrapper
    >.product_color
      background #fff
      padding 24px 16px 24px
      .section
        .head
          margin-top 20px
          margin-bottom 8px
        .footer
          margin-top 8px
          span
            color GY
            font-weight 400
            font-size 12px
      .section.dosu_option_cards
        &__error
          .option_text__body
          .color_count
          .dosu_option_cards__footer-message
            color #FF6B00
        .body
          flex-direction column
          .dosu_option_card
            position relative
            display flex
            height 50px
            padding 0 16px
            align-items center
            justify-content space-between
            background-color #F6F6F6
            border-radius 8px
            .description
              display flex
              align-items center
              &.option_summary
                width calc(100% - 73px)
              &.option_color
                position absolute
                right 16px
                .color-chips
                  display flex
                  position relative
                  gap 3px
                  .color-chip
                    border-radius 100%
                    border 1.5px solid #F6F6F6
                    margin-left -12px
                    width 24px
                    height 24px
                  .color_count
                    display flex
                    justify-content center
                    align-items center
                  //.color_count
                  //  position absolute
                  //  top -3px
                  //  right -3px
                  //  display flex
                  //  justify-content center
                  //  align-items center
                  //  height 14px
                  //  min-width 14px
                  //  border-radius 999px
                  //  background-color OG
                  //  .count
                  //    font-size 10px
                  //    font-weight 500
                  //    color white
      > .inner
        position relative
        > .share_j
        > .name
          font-size 14px
          color color_black
        > .share_j
          position absolute
          top 0
          right 0
          padding 0 0 0 20px
          background url(//s3.marpple.co/files/u_193535/2019/9/original/f_883741_1569311752546_RMS8v1z7K9IgeJpzgXT.png) left center no-repeat
          background-size 14px
          display none
          border 1px solid transparent
          html[collabo_type="line"] &
            display block
        > .price_info
        > .name
          margin-bottom 5px
        > .open_chooser
          margin 0 0 10px 0
          border-radius 2px
          background-color #ffffff
          border solid 1px #d5dbe0
          padding 5px 10px
          font-size 12px
          display none
          html[collabo_type="line"] &
            display block
        .row
          display flex
          width 100%
        .select_list
          flex-grow 1
          height 32px
          bottom 0
          width 50%
          .selected
            height 30px
            border-radius 2px
            line-height 0
            background-color #ffffff
            border solid 1px #d5dbe0
          .item
            position relative
            font-size 12px
            height 30px
            line-height 30px
            color #212529
            &:after
              content ''
              position absolute
              top 13px
              right 8px
              width 10px
              height 6px
              background url("//s3.marpple.co/file/guest/2018/7/original/f_5372_1532331330318_Z5zD6vl2px7tyT5bDTxA.png") no-repeat
              background-size 10px 6px
            .name
              position relative
              top 1px
              padding-left 8px
              font-weight 300
          .sold_out
            position relative
            &:before
              content "일시 품절"
              html[lang="en"] &
                content "Sold Out"
              html[lang="jp"] &
                content "Sold Out"
              color color_gray3
              width 100%
              position absolute
              top 50%
              right -17px
              transform translateY(-50%)
              font-size 10px
              line-height 0
              text-align center
          &.color_list
            margin-right 10px
            html[is_size_view_out = "true"] &
              width 100%
              margin-right 0
            .item
              .code
                position absolute
                top 8px
                left 8px
                width 16px
                height 16px
                border-radius 50%
                box-sizing border-box
                &[need_ccc="true"]
                &[color_code="#ffffff"]
                &[color_code="#fefefe"]
                  border 1px solid #d5dbe0
              .name
                padding-left 32px
                font-weight 400
          html[is_size_view_out = "true"] &
            &.size_list
              display none
        .price_info
          .original_price
            font-size 14px

    > .options
      display none
      position relative
      background #fff
      border-top 1px solid #e9ecef
      border-bottom 1px solid #e9ecef
      height 40px
      &:after
        content ''
        display block
        position absolute
        top 12px
        left 50%
        width 1px
        height 16px
        background #e9ecef
      button
        width 50%
        height 40px
        font-size 12px
        color #212529
        border 0 none
        outline none
        background #fff
        vertical-align bottom
        box-shadow none
        &:active
          background #eee
        &.share:before
          content ''
          display inline-block
          width 13px
          height 15px
          background url(//s3.marpple.co/file/guest/2018/7/original/f_5374_1532333094853_mPCZmc4UO3k8vaqJ8It.png) no-repeat
          background-size 100% 100%
          position relative
          top 3px
          margin-right 4px
        &.heart:before
          content ''
          display inline-block
          width 14px
          height 12px
          background url(//s3.marpple.co/file/guest/2018/7/original/f_5373_1532333075070_zT4bT1d7nmPW1Nrxlap.png) no-repeat
          background-size 100% 100%
          position relative
          top 2px
          margin-right 4px
  .toggle_cont.tg_reviews
    .review_title
      margin-left 8px
      .count
        color color_gray3
      .score_percent
        vertical-align middle
        display inline-block
        margin-left 8px
    >.tg_body
      .mp_review_list
        h1.review_title
          display none !important

.don_tooltip
  background-color #fff
  .item_body
    display flex
    align-items center
    .up_c_info
      margin-left 8px
      >.msg
        font-size 14px
        font-weight bold
        margin-bottom 8px
      >.title
        font-size 14px
        margin-bottom 8px
      >.quantity .qty
        font-size 14px
        color color_gray3



@media screen and (min-width 1025px)
  .mp_bpd_tip.is_pc
    > .don_wrapper
      width 1056px !important
      > .header
        position absolute
        top 0
        left 0
        right 0
        .title
          padding-top 38px
          color color_black
          font-size 20px
          font-weight normal
          text-align center
        .don_hide_frame
          position absolute
          top 10px
          right 10px
          width 40px
          height 40px
          background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12518_1542349614799_n4G7zKEdc0ebi6uxFVX.png") no-repeat 50% 50%
          background-size 16px auto
  .tip_area
    margin-top 104px
    .tip_images
      position relative
      width 976px
      margin 0 auto
      padding-bottom 30px
      .swiper-container
        width 976px
        margin 0 auto
        overflow hidden
        .swiper-slide
          width 320px
          height 420px
          background-position center
          background-size cover
          background-repeat no-repeat
          background-color #fafafa
        .swiper-pagination
        .swiper-button-black
          display none
      .btn_prev
        position absolute
        top 220px
        left -20px
        width 9px
        height 20px
        cursor pointer
        background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12521_1542351340021_Z8Mor2wHQA7X6CUu2PFG.png") no-repeat
        background-size 9px auto
        &:hover
        &:active
          background-image url("//s3.marpple.co/files/u_14355/2018/11/original/f_12519_1542351340021_ZScuDmbspMd3P1fdv.png")
      .btn_next
        position absolute
        top 220px
        right -20px
        width 9px
        height 20px
        cursor pointer
        background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12522_1542351340021_Q7KUGbhze0O3w7BKCKG.png") no-repeat
        background-size 9px auto
        &:hover
        &:active
          background-image url("//s3.marpple.co/files/u_14355/2018/11/original/f_12520_1542351340021_sS0z1yZyq9mh0rs8GM5Vg.png")
      .tip_pagination
        position absolute
        top 430px
        left 0
        right 0
        text-align center
        .page_icon
          display inline-block
          margin 0 3px
          width 6px
          height 6px
          cursor pointer
          border-radius 6px
          background-color #d5dbe0
          &.active
            background-color #000
    .tip_description
      padding 0 40px 40px
      font-size 12px
      color color_black
      html:not([lang="jp"]) &
        line-height 1.57
      li
        padding 0 0 8px
      ul
        margin 12px 18px 20px
        padding 0


@media screen and (max-width 1024px)
  .mp_bpd_tip.is_mobile
    background-color #fff
    > .don_wrapper
      > .header
        z-index 1
        background-color #fff
        .title
          padding-top 22px
          color color_black
          font-size 20px
          font-weight normal
          text-align center
        .don_hide_frame
          position absolute
          top 10px
          right 10px
          width 40px
          height 40px
          background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12518_1542349614799_n4G7zKEdc0ebi6uxFVX.png") no-repeat 50% 50%
          background-size 16px auto
  .tip_area
    .tip_images
      position relative
      margin 0 auto
      padding-bottom 30px
      .swiper-container
        .swiper-slide
          width 85.334%
          padding-top 122.67%
          background-position center
          background-size cover
          background-repeat no-repeat
          background-color #fafafa
        .swiper-pagination
        .swiper-button-black
          display none
      .btn_prev
      .btn_next
        display none
      .tip_pagination
        position absolute
        top 96%
        left 0
        right 0
        text-align center
        .page_icon
          display inline-block
          margin 0 3px
          width 6px
          height 6px
          cursor pointer
          border-radius 6px
          background-color #d5dbe0
          &.active
            background-color #000
    .tip_description
      padding 0 5% 10%
      font-size 12px
      color color_black
      html:not([lang="jp"]) &
        line-height 1.57
      li
        padding 0 0 8px
      ul
        margin 12px 18px 20px
        padding 0
