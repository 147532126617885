.assoc_composite_template_list_style
  .assoc_composite_template
    .canvas_wrapper
      padding-top 100%
      position relative
      overflow hidden
      canvas
        background #f6f6f6
        position absolute
        top 50%
        left 50%
        transform translate(-50%, -50%)
    &[width_more="true"]
      canvas
        height 100%
    &[width_more="false"]
      canvas
        width 100%
