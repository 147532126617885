product_detail_tabs_container_active = {
  color: #111,
  border-bottom: 1px solid #111,
  font-weight: 600
}

.product_detail_tabs_container
  @media screen and (min-width: 1025px)
    max-width 1440px
    margin 0 auto
    .product_detail_tab_body:not([data-tab_name="STORY_TAB"])
      margin 40px auto 0
    .product_detail_tab_body[data-tab_name="STORY_TAB"]
      margin 8px auto 0
  html.creator &
    @media screen and (max-width: 1024px)
      padding-bottom 60px
  .review_header
    border-bottom none !important
  @media screen and (max-width: 1024px)
    margin-top 8px
  @media screen and (min-width: 1025px)
    margin 100px auto 0
  .product_detail_tabs
    height 45px
    background #fff
    .wrapper
      display flex
      justify-content space-between
      background #fff
      height 45px
      align-items center
      width 100%
      @media screen and (min-width: 1025px)
        max-width 1440px
        margin 0 auto
  .product_detail_tab
    margin 0
    height 100%
    background none
    border-top none
    border-bottom 1px solid #d5dbe0
    border-right none
    border-left none
    color #828282
    font-size 14px
    @media screen and (min-width: 1025px)
      font-size 15px
    flex 1
    &[data-is_hidden="true"]
      display none !important
    @media screen and (max-width: 1024px)
      &[data-tab_name="STORY_TAB"]
        flex 0.7
      &[data-tab_name="REVIEW_TAB"]
        flex 1.3
  .product_detail_tab_body
    .mp_review_list
      @media screen and (min-width: 1025px)
        width 100%
    .bp_info_pop_buttons
      html.design_collection_page &
        display none
    &[data-is_hidden="true"]
      display none !important
  &[data-current_tab="REVIEW_TAB"]
    .product_detail_tab[data-tab_name="REVIEW_TAB"]
      {product_detail_tabs_container_active}
  &[data-current_tab="BP_DETAIL_TAB"]
    .product_detail_tab[data-tab_name="BP_DETAIL_TAB"]
      {product_detail_tabs_container_active}
  &[data-current_tab="MANUFACTURE_TAB"]
    .product_detail_tab[data-tab_name="MANUFACTURE_TAB"]
      {product_detail_tabs_container_active}
  &[data-current_tab="STORY_TAB"]
    .product_detail_tab[data-tab_name="STORY_TAB"]
      {product_detail_tabs_container_active}
  .product_detail_tab_body
    &[data-tab_name="STORY_TAB"]
      @media screen and (min-width: 1025px)
        margin-top 50px
  .product_detail_tab_body:not([data-tab_name="STORY_TAB"])
     @media screen and (min-width: 1025px)
       padding 40px 40px 0
  .product_detail_tab_body[data-tab_name="STORY_TAB"]
    @media screen and (min-width: 1025px)
      padding 80px 0 160px
    @media screen and (max-width: 1024px)
      background #fff
      padding-top 50px
      padding-bottom 140px
  .toggle_cont
    border-top none !important
  .product_detail_tab_body
    @media screen and (min-width: 1025px)
      .toggle_cont
        margin 0 !important
      .toggle_cont:first-child
        padding-top 0 !important
  .bp_info_pop_buttons
    margin-bottom 0 !important
  .product_detail_tab_body
    @media screen and (max-width: 1024px)
      margin-top 8px
    .toggle_cont
      margin-top 8px
      &:first-child
        margin-top 0
      &:last-child
        border-bottom none !important
        @media screen and (min-width: 1025px)
          .tg_body
            padding-bottom 0 !important
    &:first-child
      margin-top 0 !important
      .toggle_cont:first-child
        margin-top 0 !important
    @media screen and (min-width: 1025px)
      margin-top 40px
      border-top 1px solid #000
      &:first-child
        border-top none
        margin-top 0
  &[data-is_header_headroom="true"]
    &[data-is_top_fixed="true"]
      .product_detail_tabs
        .wrapper
          transition transform 0.25s ease-in-out
          &.headroom--pinned
            position fixed
            //top 0
            //left 0
            //right 0
            z-index 10000
            transform translateY(60px)
          &.headroom--unpinned
            position fixed
            //top 0
            //left 0
            //right 0
            z-index 10000
            transform translateY(0)
  &[data-is_header_headroom="false"]
    &[data-is_top_fixed="true"]
      .product_detail_tabs
        .wrapper
          position fixed
          //top 0
          //left 0
          //right 0
          z-index 10000
