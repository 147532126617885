body.order
  .don_frame.has_up_style
    .up_c_item
      padding-bottom 16px
      &:not(:first-child)
        padding-top 16px
        border-top 1px solid #d5dbe0
      .item_header
        margin-bottom 16px
        font-size 14px
        { color_gray5 }
        .no
          &:before
            content ''
            font-size 1px
            margin 0 8px
            border .4px solid #dee2e6
      .item_body
        .item_body_wrap1
          display flex
          margin-bottom 16px
      .thumb
        width 118px
        height 118px
        margin-right 24px
        background #f8f8f8
        @media screen and (max-width 1024px)
          width 90px
          height 90px
          margin-right 12px
        .image_100
          width 100%
          height 100%
          > img
            width 100%
        .canvas_100
          display black
          width 100%
          height 100%
          > *
            transform scale(118/150)
            transform-origin 0 0
            @media screen and (max-width 1024px)
              transform scale(90/150)
      .up_c_info
        .title
          font-weight normal
          .pc_name
            font-size 16px
          .name
            margin-bottom 4px
            { color_gray5 }
            font-size 12px
        .sub_title
          font-size 14px
          color #000
          margin-right 3px
          @media screen and (max-width 1024px)
            font-size 13px
            color #4e4e4e
        .faces, .sizes
          font-size 14px
          display flex
        .product_price.pc_v
          @media screen and (max-width 1024px)
            display block !important
            font-size 13px
            color #4e4e4e
            margin-top 6px
        .qty
          @media screen and (max-width 1024px)
            color #4e4e4e
        .up_c_s_list
          flex 1
        .up_c_s_item
          {flex-center-between}
          justify-content end
          position relative
          @media screen and (max-width 1024px)
            color #4e4e4e
          .size
            margin-right 6px
            @media screen and (max-width 1024px)
              margin-right 8px
          &.empty
            text-decoration underline
            cursor pointer
          &:not(:last-child)
            margin-bottom 8px
        html:not(.creator_store) &
          .pc_name
            display none
        html.creator_store &
          .bp_name
            font-size 12px
            { color_gray5 }
            margin-bottom 4px
          .faces
            display none
      .product_price
        font-size 14px
        {flex-center-right}
        .origin
          text-decoration line-through
          font-size 14px
          font-weight 300
          line-height 1.17
          margin-right 8px
          color color_gray3

  .don_frame.has_up_modal_style
    .don_page >.don_wrapper >.header
      .title
        font-size 20px !important
        font-weight normal !important
        html[lang="en"] &
          font-family CircularStd
    .modal
      background-color white
      .list_body
        width 100%
        box-sizing border-box
        overflow-y scroll
        .product_title
          margin-bottom 16px
        .item
          box-sizing border-box
          position relative
          border-bottom 1px solid #d5dbe0
          padding 16px
          &.is_not_stock
            .info
              color color_gray3 !important
            .size_info
              span
                position relative
                &:after
                  content "Sold Out"
                  html[lang="kr"] &
                    content "일시품절"
                  color color_gray3 !important
                  font-size 10px
                  margin-left 10px
                  width 80px
                  position absolute
                  top 50%
                  transform translateY(-50%)
          &.is_overflowed
            padding-bottom 30px
            &:before
              content "The image size is too large."
              html[lang="kr"] &
                content "인쇄크기 넘침"
              html[lang="jp"] &
                content "印刷可能な大きさを超えました。"
              position absolute
              bottom 6px
              right 16px
              color #fea022 !important
          &:last-child
            border-bottom none
          .info_wrap
            {flex-center-between}
            .info
              font-size 14px
              color #111
              display flex
              &.qty_info
                display flex
                flex-direction column
            span.size, span.qty
              margin-right 8px
            .qty_wrap
              display flex
            .input_wrap
              width 106px
              height 24px
              background-color #ffffff
              border solid 1px #e9ecef
              box-sizing border-box
              {flex-center-between}
              input.quantity
                font-size 12px
                line-height 1.17
                text-align center
                color #212529
                outline none
                border none
                width 80px
                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button
                  -webkit-appearance none
                  margin 0
              .plus, .minus
                height 24px
                width 40px
                padding 10px
                cursor pointer
              .plus
                background svg-plus no-repeat center
                &:active
                  background svg-plus-active no-repeat center
              .minus
                background svg-minus no-repeat center
                &:active
                  background svg-minus-active no-repeat center
          .product_price_wrap
            display flex
            justify-content flex-end
          .product_price
            display flex
            bottom 14px
            font-size 14px
            &[is_discounted="true"]
              .discounted
                display block
              .original
                text-decoration line-through
                color color_gray3
            &.hide
              .discounted, .original
                display none
            .discounted
              display none
              margin-top 12px
              color #111
            .original
              font-size 13px
              margin-right 8px
              margin-top 13px
              color #111
      .modal_footer
        position absolute
        bottom 0
        left 0
        right 0
        padding 16px 24px
        background-color #ffffff
        box-shadow 0 -2px 10px -2px rgba(0, 0, 0, 0.2)
        .summary
          {flex-center-between}
          .title
            font-size 14px
            font-weight normal
            color #111
          .total_price
            font-size 12px
            font-style normal
            font-stretch normal
            line-height 1.17
            letter-spacing normal
            .percent
              margin-right 5px
              font-weight 500
              color #ff6b6b
            .origin
              text-decoration line-through
              margin-right 8px
              font-weight 300
              text-align right
              color color_gray3
            .sign
              line-height 1.07
              font-size 14px
              font-weight normal
              margin-right 1px
            .discounted
              font-size 14px
              font-weight normal
              line-height 1
        .msg
          margin-top 8px
          font-size 9px
          color color_gray3
          html[lang="kr"] &
          html[lang="jp"] &
            display none
        .buttons
          margin-top 16px
          button
            width 50%
            height 40px
            outline none
            font-size 14px
            border none
            &:active, &:focus
              outline none
          #confirm
            width 100%
            background-color #000
            color #fff

  @media screen and (min-width 1025px)
    .don_frame.has_up_modal_style
      .don_wrapper
        position absolute
        top 0
        left 0
        right 0
        bottom 0
      >.don_wrapper >.header
        display none
      .don_page >.don_wrapper >.header
        z-index 20
        position absolute
        right 0
        top 0
        box-sizing border-box
        width calc(100% - 624px)
        padding 40px 32px 16px !important
      .modal
        display flex
        height 100%
        >.product_preview
          position relative
          background-color #f8f9fa
          width 624px
          height 100%
          >.product_face_container
            position relative
            width 100%
            height 100%
          .product_face_list.top
            z-index 1
            .product_face
              padding-top 6%
              height 80%
            .img.canvas_600
              width auto
              height 100%
              margin auto
              text-align center
              > canvas
                display inline-block
                transform scale(1)
                width auto !important
                height 100% !important
          .product_face_list.bottom
            {flex-center}
            height 70px
            position absolute
            bottom 8%
            left 0
            right 0
            z-index 2
            .product_face.preview_thumb
              margin 0 8px
              cursor pointer
              .img.canvas_60
                opacity 0.5
              .face_name
                font-size 10px
                text-align center
                line-height 0.5
              &.active
                .img.canvas_60
                  opacity 1
                .face_name
                  color color_active
              &:hover
                .img.canvas_60
                  opacity .8
          .swiper_control
            width 10px
            height 20px
            position absolute
            top 0
            bottom 0
            margin auto 0
            z-index 20
            cursor pointer
          .swiper-button-disabled
            opacity 0.1
            cursor default
          .preview_prev
            left 32px
            background svg-preview-left-arrow no-repeat center
          .preview_next
            right 32px
            background svg-preview-right-arrow no-repeat center

        >.for_desktop
          flex 1
          position relative
          padding 65px 32px 24px 32px
          .list_body
            position absolute
            top 81px
            right 0
            bottom 125px
            left 0
            padding 0 32px

  @media screen and (max-width 1024px)
    .don_frame.has_up_modal_style
      .don_page >.don_wrapper >.header
        .title
          margin 24px 0 0 24px
          display inline-block
    .don_frame.has_up_modal_style.not_many_sizes
      box-shadow 0 -2px 10px -2px rgba(0,0,0,0.2)
      .don_page >.don_wrapper
        position initial
        >.body
          position initial
          .don_tab
            position initial
            >.don_wrapper
              position initial
        >.header
          height 65px
      .modal
        .list_body
          padding 0 24px 0 24px
    .don_frame.has_up_modal_style:not(.not_many_sizes)
      .don_page >.don_wrapper >.header
        position fixed
        top 0
        right 0
        left 0
        height 65px
        box-sizing border-box
        z-index 20
      .modal
        position fixed
        top 0
        right 0
        bottom 0
        left 0
        .list_body
          padding 65px 24px 0 24px

  tooltip_common_style =
    background-color white
    box-sizing border-box

  tooltip_shadow =
    box-shadow 1px 1px 5px 0 rgba(0, 0, 0, 0.2)

  .don_tooltip
    {tooltip_common_style}
    {tooltip_shadow}
    position absolute
    opacity 0
    top 16px
    font-size 14px
    z-index 101
    >.wrapper
      {tooltip_common_style}
      width 100%
      height 100%
      padding 16px
      z-index 4
