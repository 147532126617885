body.receipt
  .don_tab.receipt_tab
    .store-body
      display initial
    >.don_wrapper .receipt_body
      max-width 500px
      margin auto
      padding 140px 0
      @media screen and (max-width 1024px)
        padding 120px 0 160px
      p
        margin 8px 0
      .header_title
        box-sizing border-box
        max-width 992px
        font-size 40px
        position relative
        .status_bar
          font-size 14px
          color color_gray3
          font-weight normal
          position absolute
          right 0
          bottom 0
          display none
          span
            &.active
              color color_black
            &.arrow
              margin auto 8px
              height 10px
              min-width 10px
              background svg-right-arrow no-repeat center
      .success_msg
        text-align center
        {flex-center}
        flex-direction column
        .circle
          width 300px
          height 168px
          background url(//s3.marpple.co/files/u_14345/2021/6/original/913490c997da447298f1b0c53cda2dc4ac0daf921.png) no-repeat
          background-size contain
          margin-right 16px
          margin-bottom 24px
          @media screen and (max-width 1024px)
            width 160px
            height 90px
            margin-right 10px
        .title
          font-size 28px
          font-weight bold
          font-stretch normal
          font-style normal
          line-height 1.36
          letter-spacing -0.2px
          text-align center
          color #111111
          @media screen and (max-width 1024px)
            font-size 22px
        .msg
          display none
      .buttons
        padding 44px 24px 0
        text-align center
        &:after
          content ''
          display block
          clear both
        a
          padding 16px
          outline none
          display inline-block
          width 100%
          font-size 18px
          font-stretch normal
          font-style normal
          line-height 1.33
          letter-spacing -0.2px
          text-align center
          color #ffffff
          max-width 370px
          @media screen and (max-width 1024px)
            float left
            width 48%
            font-size 14px
            &:first-child
              margin-right 2%
          @media screen and (max-width 320px)
            font-size 13px
        >#continue
          color #ffffff
          margin-bottom 10px
          padding 16px 0 14px
          border-radius 4px
          background-color #111111
        >#view_orders
          color #ffffff
          padding 16px 0 14px
          border-radius 4px
          background-color #0e2ee2
    .vbank_info
      margin-top 24px
      margin-bottom 0
      padding-left 0
      text-align left
      li
        padding-bottom 6px
      b
        padding-right 8px
  @media screen and (min-width 1025px)
    .don_tab.receipt_tab >.don_wrapper
      .receipt_body
        position relative
        >*
          max-width 440px
          margin auto
        .success_msg
          background-color #ffffff
          margin 40px auto
          padding 80px 0 0px
          p
            margin auto
          .for_desktop
            display flex
            margin-top 8px
            >p:first-child
              margin-right 4px
        .buttons
          padding 0
          position relative
          display flex
          flex-direction row-reverse
          >a#continue
            margin-left 16px
            margin-bottom 0

  @media screen and (max-width 1024px)
    .don_tab[tab_name="@TAB/Creator/Receipt"] >.don_wrapper .receipt_body
      background #ffffff
      .success_msg
        margin-bottom 80px
        .msg
          a
            text-decoration underline

