&
  .invite_area
    .invite_description
      h3:first-child
        margin-top 0
        padding-top 0
    &[data-invite_count="0"]
      .invite_email
      .invite_link .input
        display none
    button
      margin-left 10px
      vertical-align middle
      @media screen and (max-width: 600px)
        margin-left 0
        margin-top 5px
        width 100%
        padding 5px 15px
        font-size 14px
        font-weight 500
    @media screen and (max-width: 600px)
      h3:first-child
        font-size 15px

  .invite_description
    ul
      margin 0
      padding 0 0 0 20px
      li
        line-height 20px
        margin-bottom 5px
        color #4e5155

  input[type="text"]
    settings-input-style()
    vertical-align middle
  input[name="invite_email"]
  input[name="invite_name"]
  input[name="invite_link"]
    display inline-block !important
  input[name="invite_link"]
    width 430px !important
    font-size 12px
    padding 0.3rem 0.8rem
  input[name="invite_name"]
  input[name="invite_email"]
    width 260px !important

  @media screen and (max-width: 600px)
    input[name="invite_link"]
    input[name="invite_name"]
    input[name="invite_email"]
      width 100% !important

  .invite_email
  .invite_link
    margin-top 40px

  ul.invite_link_list
    margin 0
    padding 20px 0 0 20px
    li
      margin-bottom 10px
      font-size 12px
      button
        padding 0.3rem 0.8rem
