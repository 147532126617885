&
  .delivery_refund
    margin-top 24px
    padding 0 16px
    line-height 1.6
    > article
      margin-bottom 56px
      article
        margin-bottom 16px
      h4
        margin 0 0 12px 0
        font-weight 600
        font-size 20px
      h5
        font-weight 600
        font-size 14px
        margin 0 0 2px 0
      p
        margin 0 0 4px 0
   html[lang="kr"] & .delivery_refund
    .delivery
      &-region
        display flex
        &::after
          margin 0 10px
        p
          margin 0 0 0 10px
      &-price
        display flex
        p
          margin 0 0 0 10px
      &-cs
        p
          margin 0
      &-plan
        ul
          padding-left 18px
          margin 4px 0
      &-faq_link
        margin 0
        a
          margin-left 4px
          text-decoration underline
          cursor pointer
    .refund
      &-explanation
        margin 0 0 10px 0
        span
          display block
          margin 0 0 4px 0
      &-faq_link
        margin 0 0 10px 0
        a
          margin-left 4px
          text-decoration underline
          cursor pointer
      &-cancel_subscription
        ul
          padding-left 18px
          margin 0 0 4px 0
        &-tel
          margin-top 10px
          p:first-child
            margin 0 0 10px 0
            font-weight 600
          dl
            margin 0
            .row
              display flex
              dt
                width 130px
              dd
                margin 0
                width 0
                flex-grow 1

  html[lang="jp"] & .delivery_refund
    .delivery
      &-plan
        ul
          padding-left 18px
          margin 4px 0
    .refund
      &-explanation
        margin 0 0 10px 0
        span
          display block
          margin 0 0 4px 0

@media screen and (min-width: 1025px)
  &
    .delivery_refund
      padding 40px 64px
