// start with frame
frame_modal_full_height()
  .don_page
    height 100%
    > .don_wrapper
      height 100%
      display flex
      flex-direction column
      > .body
        flex 1
        .don_tab
          height 100%
          .don_wrapper
            height 100%

frame_modal_default_header()
  & > .don_wrapper
    border-radius 10px

  .don_page > .don_wrapper > .header
    border-top-left-radius 10px
    border-top-right-radius 10px
    position absolute
    top 0
    width 100%
    background-color white
    z-index 2

    .title
      font-size 18px
      height 56px
      display flex
      justify-content center
      align-items center
      font-weight 500
      border-bottom 1px solid #ededed