
body.creator-settings #creator_settings_body .settings-content > .header
  margin 40px 0 0
.studio-youtube
  display flex
  justify-content space-between
  max-width 1280px
  &__channel-list
    margin-bottom 20px
  &__container
    margin-top 60px
  &__section
    margin-bottom 60px
    .img_container img
      max-width 100%
  &__pd_check
    img
      height 50px
      @media screen and (max-width: 1024px)
        height 25px
  &__title
    font-weight 600
    margin-right 6px
    margin-bottom 10px
    display inline-block
    line-height 1.64
    letter-spacing -0.5px
  &__info
    margin-bottom 20px
    line-height 1.5
    letter-spacing -0.5px
  &__check-anchor
    font-weight 600
    line-height 1.64
    letter-spacing -0.5px
    color #3c89f9
    text-decoration underline
  &__insert
  &__item
    display flex
    align-items center
    justify-content space-between
  &__input
    settings-input-style()
    font-size 14px !important
    width 464px
    &::placeholder
      color #999
    &--disabled
      background-color #f5f5f5
      cursor default
      color #666
  &__btn
    margin-left 10px
    width 120px
    background #3c89f9
    color #fff
    border-radius 3px
    padding 8px 21px
    line-height 1.54
    border 1px solid #3c89f9
    &--remove
      border-color #ededed
      background #fff
      color #000
      font-weight 500
  &__info-img
    width 418px
    img
      width 100%
@media screen and (max-width: 780px)
  body.creator-settings #creator_settings_body .settings-content > .header
    margin 30px 0 20px
  .studio-youtube
    flex-direction column
    max-width 100%
    &__container
      margin-top 30px
    &__info-img
      width 100%
    &__info
      color #b2b2b2
    &__channel-list
      padding 20px
      background-color #fafafa
      margin 0 -20px 20px
    &__insert
    &__item
      margin-bottom 10px
    &__input
      width auto
    &__btn
      width 100px

@media screen and (min-width: 1025px)
  .studio-youtube-cont
    padding-bottom 30px
    &__link
      display flex
      align-items center
      justify-content flex-start
      gap 40px
    &__h2
      margin 80px 0 0
      padding 0
      color #111
      font-family Pretendard
      font-size 20px
      font-style normal
      font-weight 700
      line-height 30px
      letter-spacing -0.3px
    &__h3
      margin 60px 0 0
      padding 0
      display flex
      align-items center
      justify-content flex-start
      gap 4px
      color #111
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: -0.3px;
    &__h3-icon
      display flex
      align-items center
      margin-bottom 2px
    &__link
      margin-top 40px
    &__ul
      margin 8px 0 0 24px
      padding 0
      color: var(--Gray-600, #828282);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.3px;
    &__helps
      margin-top 10px
      border-radius 8px
      background #F5F9FE
      display flex
      width 564px
      padding 24px 40px 28px 24px
      flex-direction column
      align-items flex-start
      gap 16px
    &__helps-h3
      margin 0
      padding 0
      color #1F5EFF
      font-family Pretendard
      font-size 18px
      font-weight 600
      line-height 27px
      letter-spacing -0.3px
    &__helps-ul
      margin 0
      padding 0
      list-style none
    &__helps-li
      color #3C89F9
      font-family Pretendard
      font-size 16px
      font-weight 500
      line-height 24px
      letter-spacing -0.3px
    &__helps-li-check
      svg
        vertical-align middle
        margin-bottom 3px
    &__btn-area
      margin-top 16px

    &__btn-guide
      display flex
      width 274px
      height 48px
      min-width 100px
      padding 0 28px
      justify-content center
      align-items center
      gap 4px
      border-radius 4px
      border 1px solid #DDD
      background #FFF
      &:hover
      &:active
        color #3C89F9
        border-color #3C89F9

    &__cancel-txt
      margin 80px 0 0
      padding 0
      color: #828282
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.3px;
    &__btn-cancel-area
      margin-top 8px
    &__btn-cancel-linked
      display: flex;
      height: 40px;
      padding: 0 28px;
      justify-content: center;
      align-items: center;
      color: #A2A2A2
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.3px;
      border 0 none
      border-radius: 4px;
      background: #F2F2F2

  .youtube-link-btn
    display flex
    width 440px
    height 100px
    padding 0 20px 0 36px
    align-items center
    gap 20px
    cursor pointer
    box-sizing border-box
    border-radius 20px
    background #1F5EFF
    &:hover
    &:active
      background-color #0E43CB
    &__icon
      display flex
      align-items center
      justify-content center
      padding-top 4px
    &__icon-img
      width 70px
    &__txt
      display flex
      flex-direction column
      justify-content center
      height 100%
    &__txt1
      color #FFF
      font-family Pretendard
      font-size 16px
      font-style normal
      font-weight 600
      line-height 24px
      letter-spacing -0.3px
    &__txt2
      color #FFF
      font-family Pretendard
      font-size 22px
      font-style normal
      font-weight 600
      line-height normal
      letter-spacing -0.3px
  .youtube-link-status
    color #1F5EFF
    &[data-youtubes="0"]
      color #ccc

  .youtube-linked
    display flex
    width 440px
    padding 20px
    height 100px
    box-sizing border-box
    align-items center
    gap 16px
    border-radius 20px
    border 1px solid #F2F2F2
    background #FAFAFA
    &__icon
      display flex
      align-items center
      justify-content center
      padding-top 4px
    &__icon-img
      width 70px
      border-radius 999px
    &__txt
      display flex
      flex-direction column
      justify-content center
      height 100%
    &__txt1
      color: #111
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px; /* 150% */
      letter-spacing: -0.3px;
    &__txt2
      color: #111
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.3px;
      a
        color #111

  .youtube-store
    display flex
    width 440px
    padding 20px
    height 100px
    box-sizing border-box
    align-items center
    gap 16px
    border-radius 20px
    border 1px solid #F2F2F2
    background #FAFAFA
    &__icon
      display flex
      align-items center
      justify-content center
      padding-top 4px
    &__icon-img
      width 70px
      height 70px
      border-radius 999px
      object-fit cover
    &__txt
      display flex
      flex-direction column
      justify-content center
      height 100%
    &__txt1
      color: #111
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px; /* 150% */
      letter-spacing: -0.3px;
    &__txt2
      display flex
      align-items center
      justify-content flex-start
      column-gap 8px
      flex-wrap wrap
      color: #111
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.3px;
      a
        color #111








@media screen and (max-width: 1024px)
  .studio-youtube__api
    display none

  .studio-youtube-cont
    padding-bottom 30px
    &__link
      display flex
      flex-direction column
      align-items center
      justify-content center
      gap 8px
    &__h2
      margin 80px 0 0
      padding 0
      color: #111
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px;
      letter-spacing: -0.3px;
    &__h3
      margin 60px 0 0
      padding 0
      display flex
      align-items flex-start
      justify-content flex-start
      gap 4px
      color #111
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: -0.3px;
    &__h3-icon
      display flex
      align-items center
      margin-bottom 2px
    &__link
      margin-top 40px
    &__ul
      margin 8px 0 0 24px
      padding 0
      color: var(--Gray-600, #828282);
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      letter-spacing: -0.3px;
    &__helps
      margin-top 10px
      border-radius 8px
      background #F5F9FE
      display flex
      padding 24px 12px 40px
      flex-direction column
      align-items flex-start
      gap 16px
    &__helps-h3
      margin 0
      padding 0
      color #1F5EFF
      width 100%
      display flex
      align-items center
      justify-content center
      font-family Pretendard
      font-size 18px
      font-weight 600
      line-height 27px
      letter-spacing -0.3px
    &__helps-ul
      margin 0
      padding 0
      list-style none
    &__helps-li
      color #3C89F9
      font-family Pretendard
      font-size 16px
      font-weight 500
      line-height 24px
      letter-spacing -0.3px
      text-indent -30px
      padding-left 30px
    &__helps-li-check
      svg
        vertical-align middle
        margin-bottom 3px
    &__btn-area
      margin-top 16px
      display flex
      align-items center
      justify-content center

    &__btn-guide
      display flex
      width 84%
      height 48px
      min-width 100px
      padding 0 28px
      justify-content center
      align-items center
      gap 4px
      border-radius 4px
      border 1px solid #DDD
      background #FFF
      &:hover
      &:active
        color #3C89F9
        border-color #3C89F9

    &__cancel-txt
      margin 80px 0 0
      padding 0
      color: #828282
      text-align center
      font-family: Pretendard;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.3px;
    &__btn-cancel-area
      margin-top 8px
      display flex
      align-items center
      justify-content center
    &__btn-cancel-linked
      display: flex;
      width 60%
      height: 40px;
      padding: 0 28px;
      justify-content: center;
      align-items: center;
      color: #A2A2A2
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.3px;
      border 0 none
      border-radius: 4px;
      background: #F2F2F2

  .youtube-link-btn
    display flex
    width 100%
    height 100px
    padding 0 20px 0 36px
    align-items center
    gap 20px
    cursor pointer
    box-sizing border-box
    border-radius 20px
    background #1F5EFF
    &:hover
    &:active
      background-color #0E43CB
    &__icon
      display flex
      align-items center
      justify-content center
      padding-top 4px
    &__icon-img
      width 70px
    &__txt
      display flex
      flex-direction column
      justify-content center
      height 100%
    &__txt1
      color #FFF
      font-family Pretendard
      font-size 16px
      font-style normal
      font-weight 600
      line-height 24px
      letter-spacing -0.3px
    &__txt2
      color #FFF
      font-family Pretendard
      font-size 22px
      font-style normal
      font-weight 600
      line-height normal
      letter-spacing -0.3px
      a
        color #111
  .youtube-link-status
    color #1F5EFF
    &[data-youtubes="0"]
      color #ccc

  .youtube-linked
    display flex
    padding 20px
    width 100%
    height 100px
    box-sizing border-box
    align-items center
    gap 16px
    border-radius 20px
    border 1px solid #F2F2F2
    background #FAFAFA
    &__icon
      display flex
      align-items center
      justify-content center
      padding-top 4px
    &__icon-img
      width 70px
      height 70px
      border-radius 999px
      object-fit cover
    &__txt
      display flex
      flex-direction column
      justify-content center
      height 100%
    &__txt1
      color: #111
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px; /* 150% */
      letter-spacing: -0.3px;
    &__txt2
      color: #111
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.3px;
      a
        color #111

  .youtube-store
    display flex
    padding 20px
    width 100%
    height 100px
    box-sizing border-box
    align-items center
    gap 16px
    border-radius 20px
    border 1px solid #F2F2F2
    background #FAFAFA
    &__icon
      display flex
      align-items center
      justify-content center
      padding-top 4px
    &__icon-img
      width 70px
      height 70px
      border-radius 999px
      object-fit cover
    &__txt
      display flex
      flex-direction column
      justify-content center
      height 100%
    &__txt1
      color: #111
      font-family: Pretendard;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 27px; /* 150% */
      letter-spacing: -0.3px;
    &__txt2
      display flex
      align-items center
      justify-content flex-start
      column-gap 8px
      flex-wrap wrap
      color: #111
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: -0.3px;
      a
        color #111


