body.is_mobile:not(.order)
  .don_frame.has_up_style
    .up_c_item
      .item_header
        margin-bottom 8px
        height 16px
        font-size 13px
        color #a2a2a2
        span:first-child
          display none
      .item_body
        position relative
        .item_body_wrap1
          display flex
      .thumb
        width 90px
        height 90px
        margin-right 12px
        background #f8f8f8
        .image_100
          width 100%
          img
            width 100%
            height auto
        .canvas_100
          width 100%
          height 100%
          > *
            transform scale(90/150)
            transform-origin 0 0
        .image_100
          width 100%
          height 100%
          > img
            width 100%
      .up_c_info
        width calc(100% - 102px)
        .title
          .pc_name
            font-size 15px
            margin-bottom 6px
            line-height 1.27
            letter-spacing -0.2px
            color #111111
            text-overflow ellipsis
            overflow hidden
            white-space nowrap
          .name
          .bp_name
            margin-bottom 4px
            font-size 13px
            line-height 1.38
            letter-spacing -0.2px
            height 34px
            color #4e4e4e
            text-overflow ellipsis
            overflow hidden
        .sizes
          margin-top 41px
          margin-left -(90+12)px
          .sub_title
            display none
        .qty
          color color_gray3
        .up_c_s_list
          flex 1
          padding 15px 16px 13px
          border-radius 8px
          background-color #f8f8f8
          font-size 13px
          font-weight normal
          font-stretch normal
          font-style normal
          line-height 1.38
          letter-spacing -0.2px
          color #4e4e4e
        .up_c_s_item
          {flex-center-between}
          position relative
          .size
            margin-right 6px
          &.empty
            text-decoration underline
            cursor pointer
          &:not(:last-child)
            margin-bottom 6px
          /*.quantity:before
            content '수량: '
          html[lang="en"] &
            content 'Qty: '
          html[lang="jp"] &
            content '数量: '*/
        .faces
          display none
      .product_price
        position absolute
        top 70px
        left 90 + 12px
        font-size 14px
        letter-spacing -0.2px
        .origin
          text-decoration line-through
          font-weight 300
          margin-right 8px
          color color_gray3
          display inline
        .origin:empty
          display none

  tooltip_common_style =
    background-color white
    box-sizing border-box

  tooltip_shadow =
    box-shadow 1px 1px 5px 0 rgba(0, 0, 0, 0.2)

  .don_tooltip
    {tooltip_common_style}
    {tooltip_shadow}
    position absolute
    opacity 0
    top 16px
    font-size 14px
    z-index 101
    >.wrapper
      {tooltip_common_style}
      width 100%
      height 100%
      padding 16px
      z-index 4


body:not(.is_mobile):not(.order)
  .don_frame.has_up_style
    .up_c_item
      padding-bottom 16px
      .item_header
        margin-bottom 6px
        height 21px
        font-size 15px
        color #a2a2a2
        span:first-child
          display none
      .item_body
        position relative
        .item_body_wrap1
          display flex
      .thumb
        width 118px
        height 118px
        margin-right 24px
        background #f8f8f8
        .image_100
          width 100%
          img
            width 100%
            height auto
        .canvas_100
          width 100%
          height 100%
          > *
            transform scale(118/150)
            transform-origin 0 0
        .image_100
          width 100%
          height 100%
          > img
            width 100%
      .up_c_info
        width calc(100% - 142px)
        .title
          .pc_name
            margin-bottom 10px
            font-size 18px
            line-height 1.33
            letter-spacing -0.2px
            color #111111
            text-overflow ellipsis
            overflow hidden
            white-space nowrap
          .name
          .bp_name
            margin-bottom 4px
            font-size 15px
            line-height 1.4
            letter-spacing -0.2px
            color #4e4e4e
            height 40px
            text-overflow ellipsis
            overflow hidden
        .sizes
          margin-top 57px
          margin-left -(118+24)px
          .sub_title
            display none
        .qty
          color color_gray3
        .up_c_s_list
          flex 1
          padding 15px 16px 13px
          border-radius 8px
          background-color #f8f8f8
          font-size 14px
          font-weight normal
          font-stretch normal
          font-style normal
          line-height 1.38
          letter-spacing -0.2px
          color #4e4e4e
        .up_c_s_item
          {flex-center-between}
          position relative
          .size
            margin-right 6px
          &.empty
            text-decoration underline
            cursor pointer
          &:not(:last-child)
            margin-bottom 6px
          /*.quantity:before
            content '수량: '
          html[lang="en"] &
            content 'Qty: '
          html[lang="jp"] &
            content '数量: '*/
        .faces
          display none
      .product_price
        position absolute
        top 90px
        left 118 + 24px
        font-size 16px
        letter-spacing -0.2px
        font-weight 600
        .origin
          text-decoration line-through
          font-weight 300
          margin-right 8px
          color color_gray3
          display inline
        .discounted
          display inline
        .origin:empty
          display none

  tooltip_common_style =
    background-color white
    box-sizing border-box

  tooltip_shadow =
    box-shadow 1px 1px 5px 0 rgba(0, 0, 0, 0.2)

  .don_tooltip
    {tooltip_common_style}
    {tooltip_shadow}
    position absolute
    opacity 0
    top 16px
    font-size 14px
    z-index 101
    >.wrapper
      {tooltip_common_style}
      width 100%
      height 100%
      padding 16px
      z-index 4
