@media screen and (max-width: 330px)
  html.prev_product_matcher
    .prev_product_matcher
      .bottom
        .header
          margin 12px auto !important
        .options
          margin 21px 0 10px 0 !important
@media screen and (max-width: 1024px)
  #maker
    .board
      .msg.pinch
      .special_message
      .tip_lock
      .front_blank
        user-select: none; /* CSS3 (little to no support) */
        -ms-user-select: none; /* IE 10+ */
        -moz-user-select: none; /* Gecko (Firefox) */
        -webkit-user-select: none; /* Webkit (Safari, Chrome) */
        .face_name
          user-select: none; /* CSS3 (little to no support) */
          -ms-user-select: none; /* IE 10+ */
          -moz-user-select: none; /* Gecko (Firefox) */
          -webkit-user-select: none; /* Webkit (Safari, Chrome) */
  html.maker_editor_mode
    #maker .btn_tip
      opacity 1
      transform translateX(0)
      transition-property transform, opacity, left
      transition-timing-function ease, ease, ease-in-out
      transition-duration 0.3s, 0.3s, 0.3s
  html.clear_maker_canvas
    .need_in_safe_area
    .special_message
    .dosu_warning
      display none !important
    #maker .btn_tip
      opacity 0
      transition-property opacity
      transition-duration 0.3s
  html[lang="kr"] #maker .btn_tip
    .txt2
      top 0
      padding 18px 0
      font-size 11px
    .txt3
      top 0
      padding 18px 0
      left 136px
      font-size 11px
  html[lang="jp"] #maker .btn_tip
    .txt3
      letter-spacing -0.1px
  #maker
    .btn_tip
      &.how_to_custom
        top 62px
        .icon
          padding 13px 16px
          img
            width 22px
      &.enter_marpple_shop
        top calc(62px + 52px + 16px)
        .icon
          padding 16px 18px
          img
            width 18px
      position absolute
      left 87%
      opacity 0
      transform translateX(50px)
      width 90%
      height 52px
      overflow hidden
      padding-left 54px
      border-radius 100px
      background-color #fff
      box-sizing border-box
      transition left 0.3s
      &.tip_open
        left 5%
      &[is_empty="true"]
        display none !important
      .icon
        position absolute
        top 0
        left 0
      .txt1
        display none
      .txt2
        position absolute
        top 12px
        left 54px
        width 56%
        color color_black
        font-size 12px
        font-weight bold
        cursor pointer
      .txt3
        position absolute
        top 28px
        left 54px
        width 72%
        color color_black
        font-size 12px
        cursor pointer
      .btn_close
        position absolute
        top 10px
        right 10px
        width 30px
        height 30px
        img
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          margin auto
          width 8px
  #maker_frame
    overflow hidden
  html.prev_product_matcher
    .board
      .prev_product_matcher
        display block !important
    .select_face
      transform translateY(180px) !important
    .board
      .prev_product_matcher
        height 213px
        .new_bp_name
          position fixed
          top 0
        .bottom
          position fixed
          .header
            margin 24px auto
          >p
            margin 24px 0
        .options
          margin 24px 0 23px 0
          button
            width 100%
            height 32px
        .design
          width 72px
          height 72px
  html.mp_maker_not_ready
    .canvas-container
      transition transform 0s !important
  body.body_scroll
    overflow-y auto !important
    overflow-x hidden !important
    position static !important
    width auto !important
    height auto !important
  .don_frame[frame_name="maker.product_editor"]
    html[is_size_view_out = "true"] &
      .tab_button[tab_name="product_editor.size"]
        display none
    .pc_name
      display none
    .product
      >.body
        margin-top 20px
        margin-bottom 18px
        font-size 14px
        .name
          margin-bottom 8px
      >.options
        display flex
        justify-content center
        gap 6px
        margin 0 6px
        button
          line-height 23px
          background-color #ffffff
          border solid 1px #d5dbe0
          text-align center
          width 124px
          height 32px
          font-size 12px
    .one_size
      display none
    .main_head
      display none
    .product.section
      text-align center
      >.body >.name
        padding 0 12px
    .head
      display none
    .size.section
      height 142px
      >.body
        margin-top 20px
        width 100%
      .swiper-container
        width 100%
        height 122px
      .swiper-pagination-bullet-active
        background #000
      .swiper-slide
        padding 0 24px
        box-sizing border-box
        list-style none
        display flex
        height 90px
        flex-wrap wrap
        align-content center
        align-items center
        li
          height 32px
          box-sizing border-box
          line-height 32px
          text-align center
          cursor pointer
          margin-right 8px
          border solid 1px #d5dbe0
          font-size 14px
          color color_black
          &.selected
            color color_active
            border-color color_active
      &[length="1"]
        li
          &.selected
            color color_black
            border 0 none
            width auto !important
      &[more_than_one = "false"]
        .item
          flex-grow 1 !important
      &[one_line_length = "1"]
        .swiper-slide
          li:nth-child(1n)
            margin-right 0
      &[one_line_length = "2"]
        .swiper-slide
          li:nth-child(2n)
            margin-right 0
      &[one_line_length = "3"]
        .swiper-slide
          li:nth-child(3n)
            margin-right 0
      &[one_line_length = "4"]
        .swiper-slide
          li:nth-child(4n)
            margin-right 0
      &[one_line_length = "5"]
        .swiper-slide
          li:nth-child(5n)
            margin-right 0
      &[one_line_length = "6"]
        .swiper-slide
          li:nth-child(6n)
            margin-right 0
      &[one_line_length = "7"]
        .swiper-slide
          li:nth-child(7n)
            margin-right 0
      &[one_line_length = "8"]
        .swiper-slide
          li:nth-child(8n)
            margin-right 0
      &[one_line_length = "9"]
        .swiper-slide
          li:nth-child(9n)
            margin-right 0
      li.sold_out
        position relative
        font-size 11px !important
        line-height 20px !important
        &:before
          content "일시 품절"
          html[lang="en"] &
            content "Sold Out"
          html[lang="jp"] &
            content "Sold Out"
          color #878b8f
          width 100%
          position absolute
          top 73%
          left 0
          font-size 10px
          line-height 0
          text-align center
  html.maker_editor_mode
    #maker_frame
      position fixed
    body
      position fixed
      background #f8f9fa
      width 100%
      height 100%
    .image_color.don_frame
      .don_page
        height 158px
    .fake_mp_maker
    .detail_wrapper
    .footer_buttons
    #header
      display none !important
    .canvass
      position absolute
      top 0
      right 0
      bottom 0
      left 0
    .mp_maker
      background #f8f9fa !important

  //html.maker_zoomed
  //  #maker
  //    .canvas_container .canvas-container
  //      transform scale(2) translateY(0) !important
  html.maker_page:not(.maker_editor_mode)
    #don_up
      bottom 58px
  html.maker_page.maker_editor_mode
    .select_face
      transform translateY(0)
  body.maker_page
    .filter_blur
      filter blur(10px) !important
      -webkit-filter blur(10px) !important
    .mp_maker
      background #f8f9fa
      filter blur(0px)
      -webkit-filter blur(0px)
      transition background 0.5s, filter 0.5s
    .decoration_menu_for_pc
      display none
    .mp_maker.cv_object_selected
      .select_face
        display none !important
    .select_face
      left 84px
      right 84px
      bottom 31px
      html.safe_area_inset_bottom &
        bottom 41px
      transform translateY(70px)
      transition transform 0.3s
      -ms-user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      user-select: none;
    #maker
      .canvas_container
        canvas
          transform-origin 0 0
          width 100% !important
          height 100% !important
        .canvas-container
          //position absolute !important
          top 0
          left 0
          margin auto
          //transition transform 0.6s
  .don_frame[frame_name="mp.product.detail"]
    .footer_buttons
      font-weight 300
    .mp_product_detail
      padding-top 60px
      //.fake_mp_maker
      //  padding-top 30px
    .don_tab[tab_name="mp.product.detail"]
      padding-bottom 48px
      //bottom -200px !important

@media screen and (max-width: 1024px)
  .maker_range_wrapper
    height 32px
    display flex
    >.head
      flex-grow 5
      display flex
      align-items center
      width 50px
      html[lang="jp"] &
        width 70px
    >.body
      display flex
      flex-grow 95
    .range_wrapper
      flex-grow 70
    .blank
      flex-grow 4
    .number_wrapper
      width 70px
    >.head
      >.title
        padding-bottom 5px
  body.maker_page
    .footer_buttons
      width 100%
      position fixed
      bottom env(safe-area-inset-bottom)
      display flex
      .maker_to_cart
      .maker_go_next
      #to_direct_custom
        background #000
        display flex
        justify-content center
        align-items center
      >div
        flex-basis 50%
        height 48px
        transition: height 0.2s;
        text-align center
        color #fff
        font-size 14px
        position relative
        padding-bottom env(safe-area-inset-bottom)
        padding-bottom constant(safe-area-inset-bottom)
        html[not-safe-area="true"] &
          padding-bottom 0
        html.safe_area_inset_bottom &
          padding-bottom 34px
        html[is_marpple_app="true"] &
          padding-bottom 0
      .custom
        background color_active
        .border_box
          position absolute
          top 7px
          left 16px
          right 16px
          height 34px
          .border
            height 32px
            opacity 0.2
            border dashed 1px #ffffff
          >.deco
            width 3px
            height 3px
            background-color color_active
            border solid 1px #ffffff
            position absolute
            opacity 0.6
            border-radius 20%
          .text
            position absolute
            height 17px
            top 8px
            transform translateX(-50%)
            left 50%
            width 156px
          .one
            top -1px
            left -1px
          .two
            top -1px
            right -1px
          .three
            bottom -1px
            left -1px
          .four
            bottom -1px
            right -1px

  /* bottom_menu */
  .mp_maker
    touch-action none
    position absolute
    top 0
    right 0
    bottom 0
    left 0
    html[collabo_type=""] &
      .add_sticker_custom
      .for_line
        display none !important
  .msg.pinch
    position absolute
    bottom 68px
    left 50%
    transform translateX(-50%)
    width 190px
    z-index 0
    opacity 0
    border-radius 2px
    display none
    background-color #878b8f
    font-size 12px
    text-align center
    color #fff
    padding 8px 0 7px
    box-shadow 0 2px 8px 0 rgba(0,0,0,0.06)
    transition opacity 1s
    &:after
      position absolute
      bottom -4px
      left 50%
      width 9px
      height 9px
      margin-left -6px
      transform rotate(45deg)
      background-color #878b8f
      box-shadow 1px 1px 1px 0 rgba(0,0,0,0.06)
      content ''
      z-index -1
      display block
    &.is_show
      opacity 1
      z-index 2
  .pinch_canvas
    display none
  .pinch_mode
    .pinch_canvas
      display block
      z-index 1
      position absolute
      top 0
      bottom -10px
      left 0
      right 0
  .pinch_menu
    position absolute
    bottom -56px
    left 0
    right 0
    height 56px
    background #212529
    transition bottom 0.4s
    p.no_image
      text-align center
      margin 0
      position absolute
      padding 0
      top 21px
      left 0
      right 0
      color #fff
      opacity 0
      transition opacity 1s
      display none
    .wrapper
      left 0
      box-sizing border-box
      height 56px
      overflow auto
      position absolute
      right 0
      padding 8px 0 8px 10px
      text-align center
      -webkit-overflow-scrolling touch
      transition left 0.5s
      white-space nowrap
      &:after
        position absolute
        top 15px
        left 0
        content ''
        display none !important
        width 1px
        height 20px
        background #212529
        opacity 1
    .obj_img
      width 40px
      height 40px
    .obj_img[selected="true"]
      background #212529 url(//s3.marpple.co/file/guest/2018/7/original/f_5294_1531463731823_N8U8Vo5Wi9iM0PoArETw.png) repeat left top
      background-size 40px
      box-shadow 0 0 2px 2px color_active
      //box-shadow 0 0 0 2px #fff
      border-radius 3px
    .obj_img[selected="false"]
      background #212529 url(//s3.marpple.co/file/guest/2018/7/original/f_5295_1531463731823_MutzRbFvF6U1AMz4q9G.png) repeat left top
      background-size 40px
    .obj_imgs
      white-space nowrap
      height 40px
    .obj_img
      opacity 1
      margin-right 14px
      display inline-block
      position relative
      >img
        position absolute
        left 50%
        top 50%
        transform translate(-50%, -50%)
        max-height 72%
        max-width 72%
  #tooltip_canvas.is_show
    display flex !important
  #tooltip_canvas
    position absolute
    display none
    height 40px
    border 1px solid white
    border-radius 2px
    background-color white
    box-shadow 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)
    > div
      flex-grow 1
      display none
      text-align center
      line-height 43px
      padding 0 10px
      font-weight 500
      -webkit-user-select none  /* Chrome all / Safari all */
      -moz-user-select none    /* Firefox all */
      -ms-user-select none    /* IE 10+ */
      user-select none
    > div:active
      background-color #ddd
    &.none_active
      width 200px
      .select_all, .remove_all
        display block !important
      .select_all
        padding-left 15px
      .remove_all
        padding-right 15px
    &.has_active
      width 276px
      .copy, .duplicate, .cut, .remove
        display block !important
      .copy
        padding-left 15px
      .remove
        padding-right 15px
    &.has_clip_none
      width 264px
      .paste, .select_all, .remove_all
        display block !important
      .paste
        padding-left 15px
      .remove_all
        padding-right 15px
    &.has_clip_active
      width 294px
      > div
        padding 0 5px
      .paste, .copy, .duplicate, .cut, .remove
        display block !important
      .copy
        padding-left 10px
      .paste
        padding-right 10px

  .unlock_editor
    .m_unlock_imgs_page
      height 113px
      .overflow_wrapper
        width 100%
        overflow hidden
        height 113px
      .obj_imgs
        display flex
        justify-content center
      .obj_set
        position relative
        padding-top 24px
      .obj_img
        width 40px
        height 40px
        opacity 1
        margin-right 8px
        margin-left 8px
        position relative
        background #fff url(//s3.marpple.co/file/guest/2018/7/original/f_5295_1531463731823_MutzRbFvF6U1AMz4q9G.png) no-repeat
        background-size 40px 40px
        margin-bottom 9px
        box-sizing border-box
        >img
          position absolute
          left 50%
          top 50%
          transform translate(-50%, -50%)
          max-height 72%
          max-width 72%
      .lock span, .unlock span
        display: none;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        position absolute
        left 50%
        transform translateX(-50%)
      .lock span
        background-image url(//s3.marpple.co/file/guest/2018/7/original/f_5292_1531463731823_FC8lSA6rgep4T0bqs7C3C.png)
        background-size 100%
      .unlock span
        background-image url(//s3.marpple.co/files/u_193535/2018/10/original/f_11768_1540780825772_zlPL5chF4pL8QZ6Itux.png)
        background-size 100%
      .obj_set[is_locked="true"]
        .lock span
          display block
        .unlock span
          display none
      .obj_set[is_locked="false"]
        .lock span
          display none
        .unlock span
          display block
        .obj_img
          background #fff url(//s3.marpple.co/file/guest/2018/7/original/f_5294_1531463731823_N8U8Vo5Wi9iM0PoArETw.png) no-repeat

  html[lang="kr"]
    .image_upload_frame
      p.txt1
        padding-top 0
        text-align left !important
        font-size 11px
      p.txt2
        text-align left !important
        font-size 11px
  .image_upload_frame
    .don_page
      .image_upload_desc
      .loc_opt_desc
        padding 0 7%
        height 200px
        p.txt1
          margin 0
          padding 24px 0 0
          font-size 12px
          font-weight 500
          line-height 1.67
          text-align center
          color color_black
        p.txt2
          margin 0
          padding 8px 0 0
          font-size 12px
          line-height 1.83
          text-align center
          color color_black
          a
            color color_black
            text-decoration underline
        .btn_upload
          margin-top 24px
          input[type="file"]
            display none
          button.btn_add_img
            width 100%
            height 34px
            font-size 14px
            font-weight 200
            text-align center
            color #fff
            border 0 none
            background-color #000
            box-sizing border-box
          button.btn_my_lib
            width 100%
            height 34px
            font-size 14px
            font-weight 200
            text-align center
            color color_black
            margin-top 10px
            background-color #fff
            border 1px solid #000
            box-sizing border-box
      .loc_opt_desc
        white-space pre-line


  /* TODO :not() 지우기 */
  .mp_maker.cv_object_selected
    .canvas_container
      &[location="both"]
        .cross
          display block !important
      &[location="horizontal"]
        .horizontal
          display block !important
      &[location="vertical"]
        .vertical
          display block !important

  .mp_maker.croped .btm_menu.image_editor .options .pattern
    opacity 0.3
    &:active
      background-color #424a52 !important

  .mp_maker.pinch_mode
    .pinch_menu
      bottom 0 !important
    .msg.pinch
      display block !important
    .pinch_btn
      span
        border-radius 6px
        //position relative
        z-index 1
        background-image url(//s3.marpple.co/files/u_193535/2018/10/original/f_11790_1540782083887_VqyIlPUt0Ld0KulKf.png), url(//s3.marpple.co/files/u_193535/2018/10/original/f_11766_1540780825772_SZwkK1wPTc0SlCusT.png) !important
        background-size 18px auto, 32px auto !important;
  html[zoomed="true"]
    .btn_tip
      display none
  html.maker_page:not(.maker_editor_mode_for_canvas)
    .canvas_container
      display block !important

/* iphone XR */
html[x_style_navi_hidden="true"][is_marpple_app="false"]
  .footer_buttons > div
    @media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 2)
      padding-bottom 34px
    @media only screen and (device-width : 375px) and (device-height : 812px) and (-webkit-device-pixel-ratio : 3)
      padding-bottom 34px
    @media only screen and (device-width : 414px) and (device-height : 896px) and (-webkit-device-pixel-ratio : 3)
      padding-bottom 34px


html#dream_factory.projection
  .mp_maker
    .menu
      .list
        > div
          &.close_pinch_mode
          &.done
          &.add
            margin-top 4px

#check_pixel3534
  position: absolute;
  left: -1000px;
  top: -1000px;
  opacity: 0;

//@media screen and (max-width: 350px)
//  html.maker_page
//    .don_frame.product_editor
//      .size
//        &.section[length="4"]
//          ul
//            display flex
//            flex-wrap wrap
//          li
//            width 100%
//            max-width 95px
//            margin-right 8px
//            &:nth-child(3n)
//              margin-right 0
