@media screen and (max-width: 1024px)
  .maker_image_list
    .image_item
      position relative
      display inline-block
      margin 0 3% 3% 0
      width 21%
      cursor pointer
      border 1px solid #dee2e6
      border-radius 6px
      &:nth-child(4n)
        margin-right 0
      &:active
        border-color #b8d9fd
        background-color #fafcff
      .image
        padding 100% 0 0
        background #fff url(//s3.marpple.co/file/guest/2017/10/original/f_710_1507456502604_O2nn8NXb7wq0y0SdR1WTv.png) repeat left top
        img
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          max-width 70%
          max-height 70%
          margin auto
          vertical-align top
      .name
        display none

@media screen and (max-width: 1024px)
  html.maker_page
    .maker_image_frame[is_fixed_head="true"]
      .folder_detail_area
        > .head
          position fixed
          top 50px
          left 0
          right 0
          height 30px
          z-index 2
        > .body
          padding-top 60px
    .maker_free_image_frame[is_fixed_head="true"]
      .folder_detail_area
        > .head
          position fixed
          top 0
          left 0
          right 0
          height 30px
          z-index 2
        > .body
          padding-top 48px
    .folder_list
      margin 0 0 0 6%
      text-align center
      .folder_item
        position relative
        display inline-block
        margin -4px 4.2% 4% 0
        width 29%
        cursor pointer
        &:active
          .image
            > span
              background-color #fafcff
              border-color #b8d9fd
          .name
            color color_active
        .image
          position relative
          width 99%
          padding 0
          padding-top 100%
          margin-left 1px
          margin-top 5px
          > span
            position absolute
            display block
            width 90%
            height 86.5%
            border-radius 6px
            border 1px solid #dee2e6
            background-color #f8f9fa
          .l1
            top 4px
            left 0
            z-index 3
            img
              position absolute
              top 0
              left 0
              right 0
              bottom 0
              margin auto
              max-width 70%
              max-height 70%
          .l2
            top 2px
            left 2px
            z-index 2
          .l3
            top 0
            left 4px
            z-index 1
        .name
          padding 3px 5px 3px 2px
          text-align center
          font-size 14px
          color #343a40
          height 17px
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
    .maker_image_frame
    .maker_free_image_frame
      .folder_detail_area
        > .head
          position relative
          height 30px
          padding 15px 5%
          z-index 2
          background-color #fff
          .btn_prev
            position absolute
            top 15px
            left 5%
            display block
            cursor pointer
            width 30px
            height 30px
            text-align center
            background url(//s3.marpple.co/file/u_37/2017/11/original/f_802_1509694903685_Zg3VcOtmG2zHvJg4F0i.png) no-repeat
            background-size 30px auto
            &:active
              border-radius 15px
              background-color #f1f1f1
          .folder_img
            position absolute
            top 15px
            left 17%
            width 100px
            bottom 15px
            background-position left center
            background-size auto 80%
            background-repeat no-repeat
          .name
            position absolute
            top 22px
            left 20%
            right 20%
            text-align center
        > .body
          padding 0 0 0 5%

    .don_frame.maker_image_frame
      &[is_fixed_head="true"]
        .don_head
          .don_head_btn.don_close
            position fixed
            z-index 11
        .don_tab_buttons
          position fixed
          z-index 10

      .don_head
        height auto
        .don_head_btn.don_close
          position absolute
          top 5px
          right 11px
          z-index 10
          width 40px
          height 40px
          background url(//s3.marpple.co/file/guest/2017/11/original/f_883_1511156826358_U3MVBH8T1Cq8f6SbKcus.svg) no-repeat 50% 50%
          background-size 12px auto
          //&:active
          //  background-color #ddd
          //  border-radius 5px
          span
            display none
      .don_tab_buttons
        position absolute
        top 0
        height 50px
        right 0
        width auto
        text-align center
        margin-bottom 30px
        &:after
          content ' '
          display block
          position absolute
          left 0
          right 0
          bottom 0
          height 1px
          background-color #eee
        button[type="button"]
          position relative
          z-index 1
          display inline-block
          margin 0 7%
          min-width 40px
          height 50px
          line-height 50px
          font-size 14px
          font-weight 400
          overflow hidden
          border 0 none
          color #343a40
          border-bottom 3px solid transparent
          background-color transparent
          outline none
          border-radius 0
          &[selected="true"]
            color color_active
            border-bottom-color color_active
      .don_page
        padding-top 65px
        &[page_name="free_image"]
          padding-top 50px



    .don_frame.maker_free_image_frame
      &[is_fixed_head="true"]
        .don_head
          .don_head_btn.don_close
            position fixed
            z-index 11
        .don_tab_buttons
          position fixed
          z-index 10
        .don_tabs .don_page
          .page_title
            position fixed
            z-index 10
            background-color #fff
    .don_frame.maker_seller_image_frame
    .don_frame.maker_free_image_frame
      .don_head
        height auto
        .don_head_btn.don_close
          position absolute
          top 5px
          right 11px
          z-index 10
          width 40px
          height 40px
          background url(//s3.marpple.co/file/guest/2017/11/original/f_883_1511156826358_U3MVBH8T1Cq8f6SbKcus.svg) no-repeat 50% 50%
          background-size 12px auto
          //&:active
          //  background-color #ddd
          //  border-radius 5px
          span
            display none
      .don_page[page_name="free_folder_page2"]
      .don_page[page_name="seller_image_page2"]
        padding-top 65px
        .page_title
          position absolute
          top 0
          left 0
          height 50px
          right 0
          width auto
          text-align center
          &:after
            content ' '
            display block
            position absolute
            left 0
            right 0
            bottom 0
            height 1px
            background-color #eee
          span
            position relative
            z-index 1
            display inline-block
            margin 0 10%
            min-width 40px
            box-sizing border-box
            height 50px
            line-height 52px
            font-size 14px
            font-weight 500
            overflow hidden
            border 0 none
            background-color transparent
            outline none
            border-radius 0
            color #333
      .don_page[page_name="free_image2"]
        padding-top 12px
      .don_page[page_name="seller_image_page2"]
        #select_seller_image
          ul
            li.seller_item
              width 27%
              padding-top 134px

@media screen and (min-width: 1025px)
  #dream_factory
    .maker_worker_folder_frame
      .don_tab[tab_name="maker.worker_folder_tab"]
        .title
          position absolute
          top 20px
          left 0
          right 0
          text-align center
          font-size 16px
          font-weight normal
          color #212529
        .sticker_list
          padding-top 100px
    .maker_my_lib_frame
    .maker_sticker_frame
      position fixed
      background-color transparent !important
      > .don_wrapper
        padding-top 0
        position absolute
      .don_page > .don_wrapper > .header .don_backpage
        top 12px !important
        left 14px !important
      .don_page[page_name="maker_sticker_page"]
        position static !important
        > .don_wrapper
          > .body
            top 66px !important
        .don_tab[tab_name="maker_sticker_tab"]
          margin-top 40px
      .don_page[page_name="maker_sticker_page2"]
      .don_page[page_name="maker_embro_page2"]
        > .don_wrapper
          > .body
            top 50px !important
      .don_page.sticker_header_style1 > .don_wrapper > .header
        top -38px !important
      .don_page[page_name="maker_sticker_page3"]
        top 60px !important
        &.sticker_header_style1 > .don_wrapper > .header
          top -99px !important
      .don_page[page_name="maker_my_lib_page"]
        top 60px !important
        &.sticker_header_style1 > .don_wrapper > .header
          top -121px !important
        .folder_detail_area
          padding-bottom 50px !important
        .btn_area
          bottom 0 !important
      .sticker_image_list
        padding-top 50px


  html[lang="kr"]
    #my_lib_group_images[is_empty="true"]
      &:after
        content '이미지를 업로드하고 나만의 멋진 상품을 만들어보세요.' !important
    .image_upload_desc
    .loc_opt_desc
      width 300px !important
      line-height 1.71
      min-height 211px !important
      h3
        font-size 13px
    .loc_opt_desc
      white-space pre-line
      min-height unset

  .maker_sticker_frame.maker_my_lib_frame
    > .don_wrapper
      height 700px
    #my_lib_group_images[is_empty="true"]
      position relative
      margin 0
      min-height 400px
      &:after
        display block
        padding-top 280px
        text-align center
        font-size 16px
        content 'Your library is empty'
        background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12434_1541404949186_AQ9ZQ6vLkXCbX2v6LE1G.png") no-repeat
        background-size 80px auto
        background-position 50% 170px
        html[lang="jp"] &
          content 'ライブラリーが空です。'

  .maker_sticker_frame.maker_my_lib_frame
    .don_head .don_head_btn.don_close
    .don_head .don_head_btn.don_done
      display none
    > .don_wrapper
      padding-top 82px
      padding-bottom 100px
      &.drop
        outline 2px solid color_active
      > .body
        padding-top 20px
      .don_tab
        > .don_wrapper
          position static !important
          .my_images_wrap
            position relative
      .btn_area
        position absolute
        bottom 40px
        left 0
        right 0
        text-align center
        input[type="file"]
          position absolute
          top -9999px
          left -9999px
          visibility hidden
        button
          width 343px
          height 48px
          margin-bottom -13px
          font-size 14px
          color #fff
          text-align center
          background-color #000000
          border 1px solid #000
        .image_upload_desc
        .loc_opt_desc
          display none
          position absolute
          bottom 64px
          left 50%
          z-index 3
          width 322px
          min-height 144px
          margin-left -161px
          padding 16px
          color color_black
          background-color #fff
          box-sizing border-box
          //box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.1)
          box-shadow 0 2px 16px 2px rgba(0,0,0,0.15)
          .arrow
            position absolute
            bottom -13px
            left 50%
            width 37px
            height 17px
            margin-left -19px
            -webkit-transform rotate(180deg)
            transform rotate(180deg)
            background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12429_1541148267479_wlBszQ4KTt0HO3lAg1U.png") no-repeat
          h3
            margin 0
            padding 0
            font-size 14px
            font-weight bold
          .txt1
            margin 9px 0 0
            padding 0
            font-size 12px
            font-weight 500
            line-height 1.67
            word-spacing -1px
            br
              display none
          .txt2
            margin 6px 0 0
            padding 0
            font-size 12px
            line-height 1.67
            word-spacing -1px
            a
              color color_black
              text-decoration underline
        .loc_opt_desc
          white-space pre-line
          min-height unset
      .image_item
        .delete
          position absolute
          top -15px
          right -15px
          width 30px
          height 30px
          transform rotate(0deg);
          cursor pointer
          border-radius 15px
          background-color #ccc
          span
            display block
            position absolute
            top 23px
            left 0
            height 4px
            width 73%
            background #fff
            border-radius 2px
            transform rotate(45deg)
            &:nth-child(1)
              top 13px
              left 4px
            &:nth-child(2)
              top 13px
              left 4px
              transform rotate(-45deg)


  .maker_sticker_frame
    padding-top 0 !important
    >.don_wrapper
      padding-top 102px
      >.header
        display none
    &[is_fixed_head="true"]
      .don_page .page_title
        display none
      .don_page[page_name="maker_my_lib_page"] .page_title
        display block
        .name
          font-size 16px
          font-weight normal
          color #212529
      .don_page[page_name="maker_sticker_page"] .page_title
        position fixed
    .don_page[page_name="maker_sticker_page"]
    .don_page[page_name="maker_line_sticker_page"]
    .don_page[page_name="maker_sticker_shinhan_page"]
      > .don_wrapper > .header
        position absolute
        top 0
        left 0
        right 0
        padding-top 20px
        width auto
        text-align center
        border-bottom 1px solid #e9ecef
        z-index 1
      > .don_wrapper > .body
        top 121px !important
      .tab_buttons
        padding 0 48px
        height 100%
        button.tab_button
          position relative
          z-index 1
          display inline-block
          margin 0 0 -1px
          padding 0
          width 50%
          height 60px
          line-height 60px
          font-weight 300
          box-sizing border-box
          overflow hidden
          border 0 none
          border-bottom 1px solid transparent
          background-color transparent
          outline none
          border-radius 0
          font-size 20px
          text-align center
          color #878b8f
          &:active
            color color_black
          &[selected="true"]
            color color_black
            border-bottom-color #000
    .don_page[page_name="maker_line_sticker_page"]
      .tab_buttons
        button.tab_button
          width 50%
    .don_page.sticker_header_style1
      background-color #fff
      > .don_wrapper
        > .header
          position absolute
          top 0
          left 0
          right 0
          z-index 2
          background-color transparent
          .don_back_pag2e
            position absolute
            top 10px
            left 10px
            display block
            padding 0
            width 40px
            height 40px
            color #fff
            font-weight 500
            cursor pointer
            background url(//s3.marpple.co/files/u_18053/2018/10/original/f_6063_1539332507585_To8KK9bM1Cs2e8bm4Xi6eT.png) no-repeat
            background-size 19px auto
            background-position 50%
            overflow hidden
            span
              position fixed
              top -1000px
              left -1000px
          .name
            position absolute
            top 40px
            left 20%
            right 20%
            font-size 20px
            font-weight bold
            text-align center
            color color_black
    .sticker_list
      margin 0 0 0 48px
      text-align center
      .infi_wrapper
        text-align left
      .sticker_item
        position relative
        display inline-block
        margin 0 32px 55px 0
        width 160px
        height 160px
        cursor pointer
        &:nth-child(5n)
          margin-right 0
        &:active
        &:hover
          .image
            background-image sticker-folder-hover
          .name
            color color_active
        .image
          position relative
          width 100%
          height 100%
          padding 0
          background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6066_1539333281684_UC6pb6CwCOeuM6dSaD.png)
          background-repeat no-repeat
          background-position 50% 50%
          background-size contain
          img
            position absolute
            top 15px
            left 0
            right 0
            bottom 0
            max-width 140px
            max-height 90px
            margin auto
        .name
          padding 5px 0 3px
          text-align center
          font-size 14px
          color #343a40
          height 14px
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
    .folder_detail_area
      > .body
        padding 0
        background-color #fff
    .sticker_image_list
      margin 0 0 40px 48px
      text-align center
      .infi_items
      .infi_items > div
        text-align left
      .image_item
        position relative
        display inline-block
        margin 0 32px 32px 0
        width 160px
        height 160px
        cursor pointer
        border-radius 6px
        box-sizing border-box
        border 1px solid #d5dbe0
        &[is_group="true"]
          border 0 none
          border-radius 0
          background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6092_1539337454782_MlLaAEK9fq9zP6eMG3K.png)
          background-repeat no-repeat
          background-size contain
          background-position 50% 50%
          .thumbs
            padding 100% 0 0
            display block
            text-align center
            &[group_length="0"]
              display none
            .thumb
              display inline-block
              width 28%
              height 28%
              margin 0
              background-repeat no-repeat
              background-size contain
              background-position center center
              &:nth-child(1)
                position absolute
                top 19%
                left 14%
              &:nth-child(2)
                position absolute
                top 19%
                right 16%
              &:nth-child(3)
                position absolute
                bottom 12%
                left 14%
              &:nth-child(4)
                position absolute
                bottom 12%
                right 16%
          .image
            display none
          &.selected
          &:active
          &:hover
            background-image sticker-group-hover
        &:nth-child(5n)
          margin-right 0
        &.selected
        &:active
        &:hover
          border-color color_active
          background-color #fff
        .thumbs
          display none
        .image
          border-radius 6px
          padding 100% 0 0
          background url(//s3.marpple.co/file/guest/2017/10/original/f_710_1507456502604_O2nn8NXb7wq0y0SdR1WTv.png) repeat left top
          img
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            max-width 70%
            max-height 70%
            margin auto
            vertical-align top
        .name
          display none


@media screen and (max-width: 320px)
  #my_lib_group_images[is_empty="true"]:after
    background-position 50% 34% !important
  .maker_sticker_frame
    .don_page[page_name="maker_line_sticker_page"]
      .tab_buttons
        button.tab_button
          font-size 11px !important
          letter-spacing -0.5px

@media screen and (max-width: 1024px)
  html[lang="kr"]
    #my_lib_group_images[is_empty="true"]
      &:before
        font-size 12px
        content '이미지를 업로드하고 나만의 멋진 상품을 만들어보세요.'
  #my_lib_group_images[is_empty="true"]
    position relative
    margin 0
    min-height 400px
    &:before
      position fixed
      top 54%
      left 0
      right 0
      display block
      text-align center
      font-size 16px
      content 'Your library is empty'
      html[lang="jp"] &
        content 'ライブラリーが空です。'
    &:after
      position fixed
      top 48px
      left 0
      right 0
      bottom 0
      display block
      text-align center
      font-size 16px
      content ' '
      background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12434_1541404949186_AQ9ZQ6vLkXCbX2v6LE1G.png") no-repeat
      background-size 80px auto
      background-position 50% 40%
  .maker_sticker_frame.maker_my_lib_frame
    .don_head .don_head_btn.don_close
      display none
    .don_head .don_head_btn.don_done
      display block
      position absolute
      top 5px
      right 11px
      width 40px
      height 40px
      z-index 11
      background url(//s3.marpple.co/file/guest/2017/11/original/f_883_1511156826358_U3MVBH8T1Cq8f6SbKcus.svg) no-repeat 50% 50%
      background-size 12px auto
      span
        display none
    > .don_wrapper
      .don_tab
        > .don_wrapper
          padding-bottom 90px
          .btn_area
            position fixed
            right 0
            bottom 0
            left 0
            height 80px
            border-top 1px solid #e9ecef
            background-color #fff
            input[type="file"]
              position absolute
              top -9999px
              left -9999px
              visibility hidden
            button[type="button"]
              position absolute
              top 24px
              left 8%
              right 8%
              width 84%
              height 32px
              font-size 14px
              text-align center
              color #fff
              background-color #000
              border 1px solid #000
              box-sizing border-box
            .image_upload_desc
            .loc_opt_desc
              display none
      .image_item
        .delete
          position absolute
          top -12px
          right -12px
          width 24px
          height 24px
          transform rotate(0deg);
          cursor pointer
          border-radius 12px
          background-color #ccc
          span
            display block
            position absolute
            top 23px
            left 0
            height 4px
            width 73%
            background #fff
            border-radius 2px
            transform rotate(45deg)
            &:nth-child(1)
              top 10px
              left 3px
            &:nth-child(2)
              top 10px
              left 3px
              transform rotate(-45deg)


  .maker_sticker_frame
    padding-top 0 !important
    background-color #fff
    >.don_wrapper
      height 100%
      >.header
        display none
      >.body
        height 100%
        >.don_page
          min-height 100%
    &[is_fixed_head="true"]
      .don_page .page_title
        display none
      .don_page[page_name="maker_my_lib_page"] .page_title
        display block
        .name
          font-size 16px
          font-weight normal
          color #212529
      .don_page[page_name="maker_sticker_page"] .page_title
      .don_page[page_name="maker_sticker_shinhan_page"] .page_title
      .don_page[page_name="maker_line_sticker_page"] .page_title
        position fixed
    .don_page[page_name="maker_sticker_page"]
    .don_page[page_name="maker_line_sticker_page"]
    .don_page[page_name="maker_sticker_shinhan_page"]
      > .don_wrapper > .header
        position fixed
        top 0
        height 48px
        left 0
        right 0
        width auto
        text-align center
        border-bottom 1px solid #e9ecef
        z-index 1
        background rgba(255,255,255,0.9) !important
      .tab_buttons
        padding 0 5%
        height 100%
        button.tab_button
          position relative
          z-index 2
          display inline-block
          margin 0 0 -1px
          padding 0
          width 50%
          height 49px
          line-height 48px
          box-sizing border-box
          font-size 14px
          overflow hidden
          border 0 none
          color #878b8f
          border-bottom 1px solid transparent
          background-color transparent
          outline none
          border-radius 0
          font-weight bold
          text-align center
          &:active
            color color_black
          &[selected="true"]
            color color_black
            border-bottom-color #000
      .don_tab
        padding-top 70px
        background-color #fff
    .don_page[page_name="maker_line_sticker_page"]
      .tab_buttons
        button.tab_button
          width 50%
          font-size 12px
          word-spacing -2px
    .don_page.sticker_header_style1
      background-color #fff
      > .don_wrapper
        > .header
          position fixed
          top 0
          left 0
          right 0
          height 48px
          padding 0 5%
          z-index 2
          border-bottom 1px solid #eee
          background rgba(255,255,255,0.9) !important
          .don_back_pag2e
            position absolute
            top 5px
            left 4px
            display block
            padding 0
            color #878b8f
            font-size 10px
            cursor pointer
            .btn_back
              display block
              width 40px
              height 40px
              background url("//s3.marpple.co/files/u_14355/2018/11/original/f_12438_1541409323249_q9vQ8BNlU4U9lrAq6ER5V.png") no-repeat
              background-size 19px auto
              background-position 50%
          .name
            position absolute
            top 15px
            left 20%
            right 20%
            text-align center
            font-size 14px
            line-height 1.29
            color #212529
        > .body
          padding-top 70px
    .sticker_list
      margin 0 0 0 5%
      text-align center
      .infi_items
        text-align left
      .sticker_item
        position relative
        display inline-block
        vertical-align top
        margin 0 4% 4% 0
        width 29.193%
        cursor pointer
        &:active
          .image
            border-color color_active
            &:after
              background-image m-sticker-folder-active
          .name
            color color_active
        .image
          position relative
          padding 0
          padding-top 100%
          box-sizing border-box
          border 1px solid #d5dbe0
          border-radius 3px
          img
            position absolute
            top 6%
            left 0
            right 0
            bottom 0
            max-width 90%
            height 40%
            margin auto
          &:after
            display block
            content ''
            position absolute
            top -1px
            right -1px
            width 42px
            height 15px
            background #fff url(//s3.marpple.co/files/u_14355/2018/12/original/f_18088_1545039269083_m4nE5g8LrbdG8TENPlz.jpg) no-repeat
            background-size 100% auto
        .name
          padding 5px 0 3px
          text-align center
          font-size 14px
          color #343a40
          height 14px
          overflow hidden
          text-overflow ellipsis
          white-space nowrap
    .folder_detail_area
      > .body
        padding 0
        background-color #fff
    .sticker_image_list
      margin 0 0 3% 4%
      text-align center
      .infi_items
        text-align left
      .image_item
        position relative
        display inline-block
        margin 0 2.2% 2% 0
        width 30.4%
        cursor pointer
        border 1px solid #dee2e6
        border-radius 6px
        box-sizing border-box
        &[is_group="true"]
          border 0 none
          border-radius 0
          background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6088_1539336153118_RFCIvKu2VZuXLn0U2R.png)
          background-repeat no-repeat
          background-size 100% auto
          background-position 50% 50%
          .thumbs
            padding 100% 0 0
            display block
            text-align center
            &[group_length="0"]
              display none
            .thumb
              display inline-block
              width 28%
              height 28%
              margin 0
              background-repeat no-repeat
              background-size contain
              background-position center center
              &:nth-child(1)
                position absolute
                top 19%
                left 14%
              &:nth-child(2)
                position absolute
                top 19%
                right 16%
              &:nth-child(3)
                position absolute
                bottom 12%
                left 14%
              &:nth-child(4)
                position absolute
                bottom 12%
                right 16%
          .image
            display none
          &.selected
          &:active
            //background-image url(//s3.marpple.co/files/u_18053/2018/10/original/f_6087_1539336136793_CvFL4VaU4TON1hE9RbF.png)
            background-image sticker-group-hover
        &:nth-child(3n)
          margin-right 0
        &.selected
        &:active
          border-color color_active
          background-color #fff
          .image
            background none
        .thumbs
          display none
        .image
          border-radius 6px
          padding 100% 0 0
          background url(//s3.marpple.co/file/guest/2017/10/original/f_710_1507456502604_O2nn8NXb7wq0y0SdR1WTv.png) repeat left top
          img
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            max-width 70%
            max-height 70%
            margin auto
            vertical-align top
        .name
          display none



@media screen and (min-width: 1025px)
  .maker_line_sticker_frame
    .don_page[page_name="maker_line_sticker_page"]
      margin-top 66px
      .tab_buttons
        padding 0
        border-bottom 0 none
        button.tab_button
          box-sizing border-box
          border-bottom 1px solid #e9ecef

  html[design_collection_type="brawlstars"]
  html[design_collection_type="bt21"]
  html[design_collection_type="bnf"]
  html[design_collection_type="wdzy"]
  html[design_collection_type="truz"]
    .maker_line_sticker_frame
      .don_page[page_name="maker_line_sticker_page"]
        margin-top 0

  html[design_collection_type="bt21"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bnf_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bs_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_wdzy_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_truz_tab"]
        display none

  html[design_collection_type="bnf"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bt21_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bs_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_wdzy_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_truz_tab"]
        display none

  html[design_collection_type="brawlstars"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bt21_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bnf_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_wdzy_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_truz_tab"]
        display none

  html[design_collection_type="wdzy"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bt21_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bnf_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bs_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_truz_tab"]
        display none

  html[design_collection_type="truz"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bt21_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bnf_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bs_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_wdzy_tab"]
        display none

@media screen and (max-width: 1024px)
  .maker_line_sticker_frame
    .don_page[page_name="maker_line_sticker_page"]
      margin-top 50px
      .tab_buttons
        padding 0
        border-bottom 0 none
        button.tab_button
          border-bottom 1px solid #e9ecef
        .tab_button[tab_name="maker_line_bnf_tab"]
          padding-left 5%
        .tab_button[tab_name="maker_line_bt21_tab"]
          padding-right 5%

  html[design_collection_type="brawlstars"]
  html[design_collection_type="bt21"]
  html[design_collection_type="bnf"]
  html[design_collection_type="wdzy"]
  html[design_collection_type="truz"]
    .maker_line_sticker_frame
      .don_page[page_name="maker_line_sticker_page"]
        margin-top 0

  html[design_collection_type="bt21"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bnf_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bs_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_wdzy_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_truz_tab"]
        display none

  html[design_collection_type="bnf"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bt21_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bs_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_wdzy_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_truz_tab"]
        display none

  html[design_collection_type="brawlstars"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bt21_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bnf_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_wdzy_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_truz_tab"]
        display none

  html[design_collection_type="wdzy"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bt21_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bnf_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bs_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_truz_tab"]
        display none

  html[design_collection_type="truz"]
    .maker_line_sticker_frame
      .tab_button
        border-bottom-color transparent !important
      .tab_buttons .tab_button[tab_name="maker_line_bt21_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bnf_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_bs_tab"]
      .tab_buttons .tab_button[tab_name="maker_line_wdzy_tab"]
        display none


.mp_maker[is_shade="true"]
.mp_maker[data-is_dosu_color_app="true"]
  .btm_menu.image_editor
    .color.circle_icon
      display none
  .cv_image_editor
    .image_color.section
      display none
