// mp review.styl 에서 가져옴
.review-delete
  margin-top 8px
  display flex
  justify-content flex-end
  button
    background #fff
    border 1px solid #ccc
    color #ccc
    font-size 12px
.review_title
  .score_percent
    height 18px
    display inline-block
    vertical-align top
    margin 1px 16px
    .star_off
      position relative
      display inline-block
      vertical-align top
      width 15px
      height 15px
      background url(//s3.marpple.co/files/u_29089/2019/3/original/f_3415325_1552557992312_BXL8s3EIm1g0oeeaE4VD.png) no-repeat
      background-size 15px 15px
      .star_on
        position absolute
        top 0
        left 0
        width 0
        height 15px
        background url(//s3.marpple.co/files/u_29089/2019/3/original/f_3415324_1552557992312_HmeVs4k7ht7w7Rv9e7Hx3J.png) no-repeat
        background-size 15px 15px
.review_item
  position relative
  ul
    margin 0
    padding 0
    list-style none
  &[has_photo="false"]
    .info
      .review_info
        .right_info
          .review_imgs_wrap
            display none
  .best_mark
    //.sns_url_wrap
    //.sns_url_wrap[is_sns_url="false"]
  .reply_wrap[is_reply="false"]
    display none !important
  html.maker_page &
    .reply_wrap
      display none !important
  .reply_wrap[is_reply="true"]
    position relative
    margin-top 16px
    .owner
      width 32px
      height 20px
      position relative
      margin-bottom 8px
      &:before
        content ''
        display block
        position absolute
        left 0
        top 4px
        width 7px
        height 7px
        border-left 1px solid #495057
        border-bottom 1px solid #495057
      &:after
        content ''
        top 3px
        left 16px
        display block
        position absolute
        width 16px
        height 16px
        background url(//s3.marpple.co/f1/2017/4/29269_1491504069799.png) no-repeat
        background-size 100%
    .reply
      margin-left 16px

  .review_img
    font-size 0
    img
      width 100%
  .photo_info
    position relative
    .review_img
      cursor pointer
    .user_name
      position absolute
      left 0
      bottom 0
      color #fff
      padding 4px 8px
  .more_img[is_more="false"]
    display none
  .more_img[is_more="true"]
    position absolute
    top 8px
    right 8px
    width 27px
    height 27px
    background-image url(//s3.marpple.co/files/u_18050/2018/10/original/f_6294_1540542330331_ii9Or1wO5KT9p3QeBTqp.png)
  .info
    position relative
    width 100%
    padding 24px 0
    border-bottom 1px solid #d5dbe0
    .base_product
      position absolute
      left 0
      top 24px
      width 52px
      margin-right 16px
      .product_thumb_url
        width 52px
        height 52px
        vertical-align top
        font-size 0
        position relative
        background-color #f6f6f6
        img
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          margin auto
          max-width 90%
          max-height 90%
    .right_info
      margin-left 68px
      min-height 60px
      position relative
      .bp_name
        min-height 16px
        font-size 14px
        margin-bottom 16px
      .created_at
      .user_name
        height 14px
        display inline-block
        vertical-align top
        margin-right 8px
        a
          text-decoration underline
      .comment_wrap
        margin 8px 0 0
        display block
        line-height 24px
        &.comment_wrap2
          max-height 72px
          -webkit-line-clamp 3
          display -webkit-box
          overflow hidden
          text-overflow ellipsis
          -webkit-box-orient vertical
      .comment
        font-size 14px
        position relative
        a.linkified
          text-decoration underline
      .more
        color color_gray3
        line-height 24px
        text-decoration underline
        margin-top 4px
        display none
        cursor pointer
        &.show
          display block
    .review_imgs_wrap
      position relative
      .review_img
        position relative
        display inline-block
        vertical-align top
        overflow hidden
        font-size 0
        margin-right 0
        .img
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          background-position center
          background-size cover
  &[has_photo="false"]
    .info
      .right_info
        .review_imgs_wrap
          display none

  @media screen and (min-width 1025px)
    overflow hidden
    .info
      .right_info
        overflow hidden
        &[files_len="0"]
          .right_left
            width 100%
        .right_left
          width 592px
          display inline-block
          vertical-align top
        .review_score
          vertical-align top
        .review_imgs_wrap
          display inline-block
          vertical-align top
          height 160px
          float right
          .review_img
            width 160px
            height 160px
            cursor pointer

  @media screen and (max-width 1024px)
    .photo_info
      .user_name
        font-size 12px
        padding 2px 4px
    &[has_photo="true"]
      .info
        .review_imgs_wrap
          margin 16px 0 16px
          height 109px
          .review_img
            width 109px
            height 109px

body.review:not(.my_review):not(.line_create)
  .reviews_wrap
    .review_list
      min-height 900px
body.review
body.maker_page
body.creator_store.product-detail
body.crew_mall_home
  .review_header
    &[photo_review="false"]
      border-bottom 1px #000 solid
    h1
      margin 0
      font-size 32px
      display inline-block
      vertical-align top
      margin-right 16px
      line-height 1.19
      font-weight 400
      html[lang="en"] &
        font-family CircularStd
    .review_total_count
      margin-left 8px
      color color_gray3
      .count2
        display none
      &:before
        content '('
      &:after
        content ')'
    .photo_reviews
      height 16px
      &[is_hide="true"]
        display none !important
      .radio_toggle
        width 50px
        height 20px
        display inline-block
        vertical-align top
        margin-top -1px
        margin-right 1px
        position relative
        border-radius 20px
        color #fff
        background-color #d5dbe0
        &.check_on
          background-color #000000
          .control_button
            transform translateX(25px)
        label
          font-size 8px
          height 25px
          width 25px
          display inline-block
          vertical-align top
          margin-top -1px
          position relative
          cursor pointer
          input
            cursor pointer
            position absolute
            top 0
            left 0
            right 0
            bottom 0
            opacity 0
          span
            position absolute
            top 5px
          &.on
            span
              left 7px
          &.off
            span
              right 6px
        .control_button
          cursor pointer
          width 24px
          height 24px
          background-color #fff
          border 1px solid #d5dbe0
          border-radius 24px
          position absolute
          top -3px
          transform translateX(0)
          transition transform .3s ease-out
  .reviews_wrap[total_count]
    position relative
    &[total_count="0"]
    &[review_len="0"]
      #not_found_results
        padding 80px 0
        margin 0 auto
        display block
        &:before
          background-image url(//s3.marpple.co/files/u_29089/2018/11/original/f_12446_1541496885022_M8F6LaVQO0hT1f3fg5glJ.png)

      .review_list
        display none
    .review_list
      position relative
      overflow visible
      &[photo_review="false"]
        .review_item
          .photo_info
            display none
      &[photo_review="true"]
        .review_item
          .info
            display none
  @media screen and (min-width 1025px)
    &.maker_page
      .product_detail_list
        #not_found_results
          margin 40px 0
      #not_found_results
        padding 40px 0 16px
      .bp_info
        #not_found_results
          border-top 1px solid #e9ecef
          border-bottom 1px solid #e9ecef
    #review_cate_list
      z-index 1
    &.tg_reviews
      width 992px
      margin 0 auto
    .mp_review_list
      width 992px
      margin 80px auto 120px
      min-height 420px
      position relative
      overflow hidden
      .review_header
        position relative
        padding-bottom 42px
        .score_percent
          margin 10px 16px
          height 20px
          .star_off
            width 20px
            height 20px
            background-size 20px 20px
            .star_on
              height 20px
              background-size 20px 20px
        .review_header_right
          position absolute
          right 0
          top 0
        .photo_reviews
        .cate_list_selector_wrap
          position relative
          display inline-block
          vertical-align top
          &.photo_reviews
            margin 13px 0 13px 16px
            padding-left 16px
            border-left 1px solid #d5dbe0
            .title
              margin-right 8px
        #cate_list_selector
          .selected
            padding 13px 25px 13px 17px
            &:after
              content ''
              position absolute
              top 18px
              right 0
              width 10px
              height 6px
              background-size 100%
              background-image url("//s3.marpple.co/files/u_18050/2018/9/original/f_5875_1537284759381_Ud4eH8zur3ghvrGV2v2q.png")
      .reviews_wrap[total_count]
        .review_list
          &[photo_review="true"]
            text-align center
            .infi_wrapper
              width 320px !important
              margin-right 16px
              &:nth-child(3)
                margin-right 0
            .review_item
              display inline-block
              vertical-align top
              margin 0 0 16px 0
  @media screen and (max-width 1024px)
    .mp_review_list
      position relative
      overflow hidden
      margin 40px 0 80px
      padding 0 16px
      .review_header
        position relative
        padding-bottom 24px
        h1
          line-height 1.2
          font-size 20px
          margin-bottom 16px
          .score_percent
            margin 6px 16px
        .photo_reviews
          .photo_toggle
            display inline-block
            .title
              float right
            .radio_toggle
              float left
              margin-right 8px
              label
                span
                  top 6px
                &.on
                  span
                    left 7px
                &.off
                  span
                    right 6px
        .cate_list_selector_wrap
          position absolute
          right 0
          bottom 24px
          #cate_list_selector
            .selected
              padding-right 18px
              &:after
                content ''
                position absolute
                top 7px
                right 0
                width 10px
                height 6px
                background-size 100%
                background-image url("//s3.marpple.co/files/u_18050/2018/9/original/f_5875_1537284759381_Ud4eH8zur3ghvrGV2v2q.png")
      .reviews_wrap[total_count]
        .review_list
          .infi_wrapper:nth-child(1)
            .infi_items
              margin-right 4px
          .infi_wrapper:nth-child(2)
            .infi_items
              margin-left 4px
          .review_img
            .more_img[is_more="true"]
              width 24px
              height 24px
              right 4px
              top 4px
              background-image url(//s3.marpple.co/files/u_18050/2018/10/original/f_6293_1540542325032_geh2Soy7HXs9z0V7HoGp.png)
              background-size 24px 24px
          &[photo_review="true"]
            .review_item
              display inline-block
              vertical-align top
              width 50%
              margin-bottom 8px

html.maker_page
  .review_detail
  .review_item
    .base_product
    .bp_name
      display none !important

@media screen and (max-width 1024px)
  .don_frame[frame_name="mp.review.detail"]
    background #fff
    >.don_wrapper
      background #fff
      >.header
        height 64px !important
        background rgba(255,255,255,0.9) !important
        z-index 3
    .don_tab
      .swiper-button
      .swiper-pagination
        display none !important
      .review_detail
        padding 0 16px 40px
        position relative
        .left_info
          margin-bottom 24px
          ul
            margin 0
            padding 0
            display block
            li.review_img
              font-size 0
              text-align center
              display block
              margin-bottom 8px
              background-color #f6f6f6
              &:last-child
                margin-bottom 0
              img
                width 100%
        .right_info
          position relative
          .user_name
            font-size 16px
            margin-bottom 17px

          .created_at
            display inline-block
            vertical-align top
            margin-left 8px
          .comment_wrap
            padding 42px 0 24px
            .comment
              line-height 1.71
          .base_product
            border 1px solid #d5dbe0
            padding 16px
            height 56px
            position relative
            display block
            &:after
              content ''
              background url(//s3.marpple.co/files/u_18050/2018/10/original/f_5962_1539177196753_PXXI2r1V1Zu8lzE3C2ib7K.svg)
              position absolute
              top 28px
              right 16px
              width 24px
              height 8px
            .product_thumb_url
              width 56px
              height 56px
              display inline-block
              vertical-align top
              font-size 0
              position relative
              background-color #f6f6f6
              img
                position absolute
                top 0
                left 0
                right 0
                bottom 0
                margin auto
                max-width 90%
                max-height 90%
            .name
              position absolute
              top 24px
              left 88px
              right 48px
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
              .color
                font-weight 400
                margin-top 8px
          //.sns_url_wrap
          .reply_wrap
          .best_mark_wrap
            display none !important
@media screen and (min-width 1025px)
  .don_frame[frame_name="mp.review.detail"]
    >.don_wrapper
      >.header
        height 64px !important
        position absolute
        top 0
        right 0
        left 0
    .don_tab
      .review_detail
        position relative
        height 100%
        .left_info
        .right_info
          position relative
          display inline-block
          vertical-align top
          font-size 0
        .left_info
          width 624px
          .review_imgs_wrap
            &[is_len="false"]
              .swiper-button
              .swiper-pagination
                display none !important
            ul
              margin 0
              padding 0
              li.review_img
                width 624px
                height 768px /* 624px ?*/
                font-size 0
                text-align center
                img
                  max-width 100%
                  max-height 100%
                  position absolute
                  top 0
                  left 0
                  bottom 0
                  right 0
                  margin auto
          .swiper-button
            position absolute
            margin-top -15px
            top 50%
            cursor pointer
            background-size 14px 30px
            width 14px
            height 30px
            opacity 1
            &.swiper-button-next
              right 32px
              background-image url(//s3.marpple.co/files/u_18050/2018/9/original/f_5884_1537364162968_t4bt0Qto0aL6M3zIeGyA.png)
              &.swiper-button-disabled
                transform rotate(180deg)
                background-image url(//s3.marpple.co/files/u_18050/2018/9/original/f_5885_1537364162968_t2rnl6qM0Al1N8CwMrgq.png)
            &.swiper-button-prev
              left 32px
              background-image url(//s3.marpple.co/files/u_18050/2018/9/original/f_5884_1537364162968_t4bt0Qto0aL6M3zIeGyA.png)
              transform rotate(180deg)
              &.swiper-button-disabled
                transform rotate(0deg)
                background-image url(//s3.marpple.co/files/u_18050/2018/9/original/f_5885_1537364162968_t2rnl6qM0Al1N8CwMrgq.png)
          .swiper-pagination
            bottom 32px
            .swiper-pagination-bullet
              font-size 14px
              width auto
              height auto
              margin 0 8px
              background transparent
              color color_gray3
              font-weight bold
              opacity 1
            .swiper-pagination-bullet-active
              color color_black
        .right_info
          position absolute
          left 624px
          top 0
          right 0
          bottom 0
          font-size 14px
          .right_info_wrap
            position absolute
            top 64px
            left 32px
            right 32px
            bottom 32px
          .user_name
            font-size 20px
            margin-bottom 17px

          .created_at
            display inline-block
            vertical-align top
            margin-left 8px
          .comment_wrap
            margin 40px 0
            overflow auto
            position relative
            .comment
              line-height 1.71
          .base_product
            position absolute
            bottom 0
            left -1px
            right -1px
            border 1px solid #d5dbe0
            padding 16px
            height 56px
            .product_thumb_url
              width 56px
              height 56px
              display inline-block
              vertical-align top
              font-size 0
              position relative
              background-color #f6f6f6
              img
                position absolute
                top 0
                left 0
                right 0
                bottom 0
                margin auto
                max-width 90%
                max-height 90%
            .name
              margin-top 8px
              margin-left 16px
              display inline-block
              vertical-align top
              width 200px
              position relative
              overflow hidden
              text-overflow ellipsis
              white-space nowrap
              .color
                font-weight 400
                margin-top 8px
            &:after
              content ''
              background url(//s3.marpple.co/files/u_18050/2018/10/original/f_5962_1539177196753_PXXI2r1V1Zu8lzE3C2ib7K.svg)
              position absolute
              top 28px
              right 16px
              width 24px
              height 8px
          //.sns_url_wrap[is_sns_url="false"]
          .reply_wrap
          .best_mark_wrap
            display none !important


body.creator_store
body.crew_mall_home
  &.product-detail
    .review_item
      .base_product
      .bp_name
        display none
    .mp_review_list
      width auto
      margin 80px 0 40px
      *
        box-sizing unset
      .review_header
        padding-bottom 24px
        .review_title
          font-size 24px
          .score_percent
            display none
        .photo_reviews
          margin 10px 0 0 0 !important
        .cate_list_selector_wrap
          display none !important
      .reviews_wrap[total_count]
        #not_found_results
          border-top 1px solid #e9ecef
          border-bottom 1px solid #e9ecef
        .review_list[photo_review="true"]
          @media screen and (min-width: 1025px)
            .infi_wrapper
              width 366.66666666666px !important
              margin-right 10px
              .review_item
                margin-bottom 10px
  &.crew_mall_home
    .mp_review_list
      width auto
      margin 0
      padding 0
      *
        box-sizing unset
      .review_header
        display none
      .reviews_wrap[total_count]
        .review_list[photo_review="true"]
          .infi_wrapper
            @media screen and (min-width: 1025px)
              width 420px !important
              margin-right 10px
              &:nth-child(3)
                margin-right 0
              .review_item
                margin-bottom 10px

