plain-list(padding = 0, margin = 0)
  list-style none
  padding padding
  margin margin

make-list(n, space)
  width calc((100% - ((n - 1) * space)) / n)
  margin-right space
  &:nth-child(n + 1)
    margin-right 0

box()
  border-radius 6px
  border 1px solid #ededed

setting-box()
  background #fff
  border-radius 0.25rem
  box-shadow 0 10px 30px 0 rgba(24,28,33,0.04)

setting-box-2()
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(24,28,33,0.05);
  border-radius: .25rem;
  box-shadow: 0 1px 6px rgba(0,0,0,0.09);

flag_kr()
  background-image:
    url(//s3.marpple.co/files/u_1504988/2021/5/original/d05a7a031e2f0f82541e2214de8a490d48f2f0422.png);

flag_en()
  background-image:
    url(//s3.marpple.co/files/u_1504988/2021/5/original/8cdf8115075aa4531e151168e423b631f44facc73.png);

flag_jp()
  background-image:
    url(//s3.marpple.co/files/u_1504988/2021/5/original/6c5d69765a44b53486d9a898d759f082077428a11.png);
