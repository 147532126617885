.swiper-container.style1
  .swiper-pagination-bullet
    width 6px
    height 6px
  .swiper-pagination-bullet-active
    background #000

.frame_close_bg
  content ""
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  background #000
  opacity 0
.don_frame
  .iscroll_wrapper
    overflow hidden

.don_frame.after_bar, .don_page.after_bar
  > .don_wrapper > .header
    &:after
      content ' '
      display block
      position absolute
      bottom 0
      left 0
      right 0
      height 1px
      background-color #edeff2


.one_tab_button
  .tab_buttons
    display block !important
    width 100%
    text-align center
    button
      position relative
      z-index 1
      height 48px
      margin 0 6%
      min-width 26px
      text-align center
      padding 0
      font-size 14px
      font-weight bold
      border 0 none
      color #212529
      border-top 2px solid transparent
      border-bottom 2px solid transparent
      background-color transparent
      outline none
      border-radius 0

.tab_buttons.blue_tab_buttons
  width 100%
  display flex
  padding 0 24px
  box-sizing border-box
  .tab_button
    flex-grow 1
    position relative
    z-index 1
    height 48px
    text-align center
    width 100%
    border-radius 0
    box-sizing border-box
    border-top 1px solid transparent
    border-bottom 1px solid transparent
    button
      color #878b8f
      padding 0
      font-size 14px
      font-weight bold
      height 100%
      border none
      outline none
      background-color transparent
    &:active
      button
        color color_black
    &[selected="true"]
      button
        color color_black
      border-bottom 1px solid #000



.maker_dialog
  .don_tab_buttons
    position absolute
    top 0
    height 48px
    right 0
    width auto
    text-align center
    border-bottom 1px solid #e9ecef
    display block
  .don_tabs
    position absolute
    top 49px
    left 0
    right 0
    bottom 0
  .don_tab
    position absolute
    top 0
    left 0
    right 0
    bottom 0
  .don_head .don_head_btn.don_close
  .don_head .don_head_btn.don_done
    position absolute
    top 5px
    right 11px
    z-index 10
    width 40px
    height 40px
    background url(//s3.marpple.co/file/guest/2017/11/original/f_883_1511156826358_U3MVBH8T1Cq8f6SbKcus.svg) no-repeat 50% 50%
    background-size 12px auto
    //&:active
    //  background-color #ddd
    //  border-radius 5px
    span
      display none
  .don_page .page_title2 .btn_done
    position absolute
    top 5px
    right 11px
    z-index 10
    width 40px
    height 40px
    background url(//s3.marpple.co/file/guest/2017/11/original/f_883_1511156826358_U3MVBH8T1Cq8f6SbKcus.svg) no-repeat 50% 50%
    background-size 12px auto
    cursor pointer
    //&:active
    //  background-color #ddd
    //  border-radius 5px
    span
      display none
  .don_page .page_title2 .btn_prev
    background url(//s3.marpple.co/file/guest/2017/12/original/f_1348_1513563513215_iMTmdJg6Z6r8uTQ7xwk.svg) no-repeat 50% 50%
    position absolute
    top 5px
    left 11px
    z-index 10
    width 40px
    height 40px
    cursor pointer
    //&:active
    //  background-color #ddd
    //  border-radius 5px
  .don_tab_buttons
    button[type="button"]
      position relative
      z-index 1
      display inline-block
      margin 0 6%
      padding 0
      min-width 40px
      height 48px
      line-height 48px
      box-sizing border-box
      font-size 14px
      font-weight 400
      overflow hidden
      border 0 none
      color #343a40
      border-bottom 2px solid transparent
      background-color transparent
      outline none
      border-radius 0
      &:active
        color #1f5ea8
      &[selected="true"]
        color #1f5ea8
        border-bottom-color #1f5ea8

.automatic_swiper.color_picker
.color_picker.mkf
.color_picker.mkf2
  .color_item
    width 28px
    height 28px
    padding 1px
    box-sizing border-box
    border-radius 13px
    cursor pointer
    border 1px solid transparent
    &[need_ccc="true"]
    &[color_code="#ffffff"]
    &[color_code="#fefefe"]
    &[color_code="white"]
      span
        border 1px solid #ced4da
    &.selected
      border-color color_active
    span
      display block
      width 24px
      height 24px
      box-sizing border-box
      border-radius 50%

@media screen and (min-width: 1025px)
  .color_picker.mkf
    display flex
    flex-wrap wrap

.automatic_swiper
  box-sizing border-box
  //display flex
  //flex-direction column
  //justify-content center
  >.header
    text-align center
    margin-bottom 10px
  .swiper-slide
    display flex
    flex-wrap wrap
    box-sizing border-box
    align-items center
  .swiper-pagination
    position initial
@media screen and (max-width: 1024px)
  .color_picker.mkf
    margin 0
    padding 0
    list-style none
    line-height 0
    height 100%
    .mkf_header
      height 30px
      display flex
      justify-content center
      align-items center
    &[is_only_one_line="true"]
      .swiper-slide
        justify-content center
    .swiper-slide
      display flex
      flex-wrap wrap
    .swiper-pagination
      bottom 4px
    .swiper-wrapper
      height 100px
    .swiper-slide
      min-height 80px
      padding 0 20px
      box-sizing border-box
@media screen and (max-width: 410px)
  .color_picker.mkf
    .color_item
      margin-left 3.8%

@media screen and (max-width: 390px)
  .color_picker.mkf
    .color_item
      margin-left 3.45%

@media screen and (max-width: 370px)
  .color_picker.mkf
    .color_item
      margin-left 3.4%

@media screen and (max-width: 360px)
  .color_picker.mkf
    .color_item
      margin-left 3.2%

@media screen and (max-width: 340px)
  .color_picker.mkf
    .color_item
      margin-left 2.9%

@media screen and (max-width: 325px)
  .color_picker.mkf
    .color_item
      margin-left 2.55%

.don_frame.product_editor
  .don_page
    height 190px
    background #fff
    >.don_wrapper >.header
      height 48px
  .don_tab >.don_wrapper >.color
    height 142px
    >.body
    .bp_color_name
      text-align center
      font-size 13px
      margin-bottom 10px
    .color_picker
      padding-top 17px
      .swiper-slide
        box-sizing border-box
        display flex
        flex-wrap wrap
        height 84px
      .swiper-pagination
        bottom 1px
      &[swiper-slide_length = "1"]
        .swiper-slide
          align-items center
      &[is_only_one_line="true"]
        padding-top 35px !important
        .bp_color_name
          margin-bottom 10px
        .swiper-slide
          justify-content center
          height 50px !important



.maker_dialog.picker
  background none
  > *
    z-index 100
  .don_head
    &:before
      content ''
      display block
      position absolute
      top 5px + 16px + 1px
      left 0
      right 0
      height 40px
      background #fff
      box-shadow 0 0px 15px rgba(0, 0, 0, 0.2)
      z-index 2
    &:after
      content ''
      display block
      position absolute
      top 5px
      left 0
      right 0
      margin 0 auto
      width 42px
      height 29px
      z-index 1
      box-shadow 0 0px 5px rgba(0, 0, 0, 0.1)
    .don_head_btn
      display none
    .don_done
      position absolute
      display block
      top 5px
      left 0
      right 0
      margin 0 auto
      width 42px
      height 29px
      background #fff url("//s3.marpple.co/file/u_18054/2018/6/original/f_5197_1528685538799_pNXOFBubzyFN5VT0B.svg") no-repeat center 6px
      z-index 3
      box-shadow none
      span
        display block
        position fixed
        top 0
        bottom 0
        left 0
        right 0
        z-index 100
  .don_tab > .don_wrapper > div
    position absolute
    top 0 !important
    left 0
    bottom 0
    right 0
    background #fff
    &.color
      height 41px + 204px
    &.size
      height 183px
      .sold_out
        position relative
        &:before
          content "일시 품절"
          html[lang="en"] &
            content "Sold Out"
          html[lang="jp"] &
            content "Sold Out"
          color #878b8f
          width 100%
          position absolute
          top 50%
          left 20%
          transform translateY(-50%)
          font-size 10px
          line-height 0
          text-align center
    .body
      position absolute
      top 40px
      left 0
      right 0
      bottom 0
      //overflow auto
      //overflow hidden
      border-top 1px solid #e9ecef
    .options
      position absolute
      top 0px
      left 0
      right 0
      height 40px
      .done
        position absolute
        top 0
        right 0
        padding 0 12px
        min-width 62px
        height 40px
        line-height 40px
        outline none
        border 0 none
        background none
        box-shadow none
        color #1f5ea8
        font-size 12px
      .size_table
        position absolute
        top 0
        left 0
        padding 0 12px
        width 68px
        height 40px
        line-height 40px
        outline none
        border 0 none
        background none
        box-shadow none
        color #212529
        text-decoration underline
        font-size 10px
    .item
      position relative
      width 100%
      height 34px
      color #878b8f
      border-top 1px solid #fff
      border-bottom 1px solid #fff
      font-weight 400
      text-align center
      line-height 32px
      font-size 12px
      &[need_ccc="true"]
      &[color_code="#ffffff"]
      &[color_code="#fefefe"]
      &[color_code="white"]
        .code
          border 1px solid #d5dbe0
      &.selected
        border-top 1px solid #e9ecef
        border-bottom 1px solid #e9ecef
        color #212529
        font-weight 500
        &:first-child
          border-top 1px solid #fff
      .code
        position absolute
        top 9px
        left 24px
        width 16px
        height 16px
        box-sizing border-box
        border-radius 50%
      .name
        position relative
        top 2px

.range_sty1
  .control.range
    position relative
  .control.range
    .rangeslider--horizontal
      border-top 7px solid #fff
      border-bottom 7px solid #fff
  input[type="number"]
    box-sizing border-box
    width 80px
    height 32px
    border-radius 2px
    border solid 1px #dee2e6
    font-size 14px
    color color_black
    &:focus
      outline none
    &:disabled
      background-color #fff !important
      opacity 0.9 !important

.don_page
  &.image_color
    position absolute
    top 0 !important
    left 0
    right 0
    bottom 0
    .body
    .swiper-container
      width 100%
      height 100%
    .swiper-slide
      box-sizing border-box
      padding-top 4%
      padding-left 4%


