$btn_size = 25px

.arrangement_container
  background-color white
  display block
  position relative

  .arrangement_header
    padding-left 30px
    padding-top 20px
    .description
      font-size 15px

  .arrangement_body
    height 100%
    position relative
    display flex

  .product_item
    .selected_mark
      display inline-block
      z-index 1
      position absolute
      color #bbb
      font-size 17px
      top 7px
      right 7px
    &.selected
      .selected_mark
        color #1976d2

  .store_container
    flex 0.7
    min-width 50%
    padding-bottom 50px
    margin-right 20px
    border-radius 6px
    border 1px solid #ededed
    transition all 500ms ease

    &[data-open="true"]
      min-width 100%
      margin-right 0

  .store_preview
    display block
    margin 0 auto
    width 880px
    max-width 90%
    //padding-bottom 50px
    .store-header-logo a, .store-header-right_gnb a
      user-select none
      pointer-events none
      cursor default
    .store
      min-height auto
    .store-body
      min-height auto
      padding-bottom 0
    .empty_box
      display none

  .clipboard_container
    flex 0.3
    top 0
    position sticky
    overflow-x hidden

    .clipboard-guide
      position absolute
      top 250px
      left 50%
      transform translateX(-50%)
      width 196px
      text-align center
      opacity 1
      transition opacity 500ms
      &:not(:last-child)
        opacity 0
      img
        width 90px
      p
        margin 0
        color #4c4c4c

    .drop_area
      position fixed
      right 0
      top 50%
      transform translateY(-50%)
      width 10%
      height 60vh

    &[data-open="true"] .drop_area
      display none

    &[data-open="false"]
      .clipboard
        transform translateX(100%)

    .clipboard
      position absolute
      top 0
      left 0
      width 100%
      height 100%
      padding 10px
      border-radius 6px
      border 1px solid #ededed
      //background-color white
      overflow-y auto
      z-index 2
      transform translateX(0)
      transition all 500ms

      .header
        display flex
        justify-content space-between
        align-items center
        padding 10px 10px 30px

        .title
          display inline-block
          user-select none
          font-size 16px
          font-weight 600
          margin 0

        .close_btn
          display inline-block
          width $btn_size
          height $btn_size
          font-size $btn_size
          color #666
          border none
          outline none
          padding 0
          background-color transparent

      .empty_box, .product_item
        display inline-block
        width calc((100% - 40px) / 4)
        padding-top calc((100% - 40px) / 4)
        margin 5px
        height 0

      .empty_box
        border 1px solid gray

      .product_item
        position relative
        user-select none
        cursor pointer
        &.drag_ghost
          opacity 0.2

        .selected_mark
          font-size 14px

        .mp-shop-product-item
          position absolute
          height 100%
          width 100%
          top 0
          .wrap-image
            width 100%
            height 100%
            text-align center
            img
              height 100%
          .mp-shop-product-store,.mp-shop-product-name,.embed_option,.mp-shop-product-price,.mp-shop-product-limited
            display none

  .open_btn
    position absolute
    right 20px
    top 20px
    z-index 1
    width $btn_size
    height $btn_size
    font-size $btn_size
    color #666
    border 0
    padding 0
    background-color white
    &[data-open="false"]
      .will-open
        display block
      .will-close
        display none
    &[data-open="true"]
      .will-open
        display none
      .will-close
        display block
    &:focus
      outline none

.store-products
  width 100%
  display flex
  flex-wrap wrap
  min-height 500px

  .product_item
    display inline-block
    width calc((100% - 10px * 10) / 5)
    margin 10px
    cursor pointer
    position relative
    &.drag_ghost
      opacity 0.2
      .mp-shop-product-store,.mp-shop-product-name,.embed_option,.mp-shop-product-price,.mp-shop-product-limited
        display none

  @media screen and (min-width: 1025px)
    .product_item
      margin 10px
      cursor pointer
      &.on_drag
        .mp-shop-product-store,.mp-shop-product-name,.embed_option,.mp-shop-product-price,.mp-shop-product-limited
          display none
      &.drag_ghost
        opacity 0

.infinite_scroll_target
  width 50%
  height 1px
  transform translateY(-100%)
  z-index -1

