.mp_maker_colors_wrapper_style
  .colors
    .row
      margin-bottom 12px
      &:last-child
        margin-bottom 0
    .color
      width 28px
      height 28px
      border-radius 28px
      cursor pointer
      span
        width 24px
        height 24px
        border-radius 24px
        background-size 24px 24px
  .colors
    .color
      &[data-color_code="#ffffff"]
        span
          border 1px solid #ced4da
      border 1px solid transparent
      display flex
      justify-content center
      align-items center
      &.active
        border 1px solid #0157ff
    .row
      display flex
      justify-content space-between
#maker_frame.mp_maker
  .open_cv_background_marpplizer
    display none
  &[data-has_background="true"]
    .open_cv_background_marpplizer
      display list-item
@import "./frame.styl"
@import "./marpplizer.styl"
