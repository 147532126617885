.canvas_thumbnail_loader_wrap
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  transform scale(1) !important
  .canvas_thumbnail_loader_img
    position absolute
    top 0
    left 0
    right 0
    bottom 0
    max-width 48px
    max-height 48px
    margin auto
    background url(//s3.marpple.co/files/u_2283830/2024/2/original/69948301f4d417f6e021e3d4b423414925d38b381.gif) no-repeat
    background-size 48px 48px
    opacity 0.5

@media only screen and (min-resolution: 192dpi), (-webkit-min-device-pixel-ratio: 2)
  .canvas_thumbnail_loader_wrap
    .canvas_thumbnail_loader_img
      background url(//s3.marpple.co/files/u_2283830/2024/2/original/69948301f4d417f6e021e3d4b423414925d38b381.gif) no-repeat
      background-size 48px 48px

