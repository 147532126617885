@import "helper.styl";
@import "tippy.js/animations/shift-toward.css";

body
  @media screen and (max-width: 600px)
    font-size 12px

[data-tippy-root]
  max-width 95%

.flag
  display inline-block
  width 24px
  height 24px
  background-size cover
  background-repeat no-repeat
  background-position center center
  border-radius 50%
  overflow hidden
  &.kr
    flag_kr()
  &.en
    flag_en()
  &.jp
    flag_jp()

@media screen and (min-width: 1025px)
  .creator-settings
    [frame_name="mp.checkout.change_email"]
      > .don_wrapper
        height 644px !important
      .page_body
        padding 50px !important

@media screen and (max-width: 1024px)
  .creator-settings
    [frame_name="mp.checkout.change_email"]
      > .don_wrapper
        > .header
          display flex
          justify-content space-between
          align-items center
          padding 32px 24px 24px
          box-sizing border-box
          background #fff
          .title
            font-size 18px
            font-weight bold
            line-height 1.56
            letter-spacing -0.2px
            color #111
          .don_hide_frame
            position static !important


settings-input-style()
  flex 1
  display block
  width 100%
  padding 9px 10px
  font-weight 500
  font-size 14px
  line-height 1.57
  color #000000
  background-color #fff
  background-clip padding-box
  border 1px solid #ededed
  border-radius 2px
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out
  outline none
  @media screen and (max-width: 600px)
    font-size 12px
  &:focus
    border-color #3c97fe
  &.inline
    display inline-block
    vertical-align top
    width auto
  &.danger
  &.invalid
    border-color #d9534f
  &.disabled
  &[disabled]
    background-color #f5f5f5 !important

body.creator-settings
  .maker_soop_asset_lib_page2
    > .don_wrapper
      > .header
        .title
          position: absolute;
          top: 38px;
          left: 20px;
          right: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $black;
          font-family: Pretendard;
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 34px;
          letter-spacing: -0.2px;

body.creator-settings
  .external-services
    opacity 1
  .studio-tab
    margin: 40px 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-bottom: 1px solid $gray-5;
    &__li
      display: flex;
      height: 40px;
      min-width: 60px;
      padding: 10px 0;
      align-items: center;
      gap: 4px;
      a
        color: $gray-600;
    &__li-on
      text-align: center;
      font-family: Pretendard;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px; /* 157.143% */
      letter-spacing: -0.2px;
      border-bottom: 2px solid var(--SS-Sky, #3C89F9);
      a
        color: $gray-800;

html.creator-settings.shopify
  height 100%;
  background-color #ffffff

html[lang="kr"].creator-settings *:not(text)
  letter-spacing -0.5px
//data-is_carved_phonecase_product

body.crew,
body.creator-settings
  .don_frame[is_modal][hide_frame_button_type="X"] >.don_wrapper >.header .don_hide_frame
    top 9px
    right 9px

.don_frame[frame_name="@FRAME/Creator/Setting/product_editing"]
  &[is_modal="false"]
    >.don_wrapper
      height 100% !important


body.crew,
body.creator-settings:not(.reset_css)
  .don_page:not(.page_in_mp_maker)
    input[type="text"]
    textarea
      border-radius 3px
      border 1px solid #ededed
    a
    button
      color: #4e5155;
    *
      box-sizing border-box


body.crew,
body.creator-settings
  .don_page:not(.page_in_mp_maker)
    .product-status
      span
        display inline-block
        padding .25em .417em
        font-size .858em
        font-weight 500
        line-height 1
        text-align center
        white-space nowrap
        vertical-align baseline
        border-radius .125rem
        transition all 0.2s ease-in-out
      span[data-status="public"]
        background-color transparent
        box-shadow 0 0 0 1px #02BC77 inset
        color #02BC77
      span[data-status="private"]
        background-color transparent
        box-shadow 0 0 0 1px #d9534f inset
        color #d9534f
      span[data-status="prohibited"]
      span[data-status="not_confirmed"]
        background-color transparent
        box-shadow 0 0 0 1px #000000 inset
        color #000000

      .shopify &
        position absolute
        top 10px
        left 10px

        [data-status]
          display flex
          align-items center
          justify-content center
          font-size 12px
          font-weight 400
          border-radius 2px
          background-color transparent
          border 1px solid #95bf47
          color #95bf47
          letter-spacing -0.5px
          line-height 1.0
          padding 1px 8px

          &::before
            display block
            content url("//s3.marpple.co/files/u_1093672/2020/9/original/a0fdc8e06b5b09d2f85bf895fee5e474b965a9381.svg")
            width 14px
            height 18px
            margin-top 1px
            margin-right 4px

        [data-status="unexported"]
          background-color #95bf47
          border 1px solid #95bf47
          color #ffffff

          &::before
            content url("//s3.marpple.co/files/u_1093672/2020/9/original/c745599b294c701f6972ce232715dd966a3f83242.svg")


body.crew,
body.creator-settings
  [data-show=false]
    display none !important
  .don_page:not(.page_in_mp_maker)
    .fx-auto-complete .fx-auto-complete-list
      top auto
      right 0
    .btn
      display: inline-block;
      font-weight: 600;
      cursor: pointer;
      text-align: center;
      vertical-align: middle;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid;
      //padding: .438rem 1.125rem;
      padding: 9px 1.125rem;
      line-height: 1.54;
      border-radius: .15rem;
      transition: all 0.2s ease-in-out;
      border-color: rgba(24,28,33,0.1);
      background: #fff;
      color: #000;
      margin 0 3px
      &.lnr:before
        display block
        line-height 1
      &.btn-icon
        border none
        outline none
        background transparent
        padding 0
      &:disabled
        opacity 0.7
      &:first-child
        margin-left 0
      &:last-child
        margin-right 0
      &:active
        box-shadow none
      &.btn-info
        border-color: #28c3d7;
        background: #28c3d7;
        color: #fff;
      &.btn-primary
        color #fff;
        background-color #3c89f9
        border-color #3c89f9
      &.btn-ok
      &.btn-save
        color: #fff;
        background-color: #02BC77;
        border-color: #02BC77;
      &.btn-cancel
        border-color: #8897AA;
        background: #8897AA;
        color: #fff;
      &.btn-delete
      &.btn-remove
      &.btn-danger
        border-color: #d9534f;
        background: #d9534f;
        color: #fff;
      &.btn-warning
      &.btn-yellow
        border-color: #FFD950;
        background: #FFD950;
        color: #665720;
      &.btn-blue
        border-color: #3c97fe;
        background: #3c97fe;
        color: #fff;
      &.btn-default
        border: solid 1px #d9d9d9;
        background-color: #f5f5f5;
      &.btn-submit
      &.btn-clone
        border-color: #3c89f9;
        background: #3c89f9;
        color: #fff;
      &.btn-export
        border-color: #95bf47;
        background: #95bf47;
        color: #fff;
      &.btn-go-admin
        border-color: #95bf47;
        background: transparent;
        color: #95bf47;
      &.btn-large
        padding 12px 24px 11px

    .btn-more
      display block
      width 24px
      background transparent
      padding 0
      border none
      &:focus
        outline none
      > img
        width 100%

    .download-excel
      color #23a566
      display flex
      align-items center
      justify-content center
      width 165px
      height 40px
      line-height: 1.54;
      background-color white
      border-radius: 2px
      border: solid 1px #dddddd
      &--disabled
        color #bbb
        &:hover
          position relative
      img
        width 13px
        margin-right 7px

      @media screen and (max-width: 600px)
        padding: 7px 20px 5px;
        font-size: 14px;
        font-weight: 400;

    .more-buttons
      display none
      position absolute
      top -2px
      right 32px
      padding 6px 0
      z-index 100
      setting-box-2()
      > *
        display block
        background none
        text-align left
        width 224px
        font-size 15px
        border 0 none
        padding 8px 16px
        margin 0
        outline none
        &:hover
          background-color: #3c97fe;
          color: #fff;
        &:first-child
          border 0 none


    .form-group
      margin-bottom 40px
      .title
        display block
        font-size 18px
        font-weight 600
        padding-bottom 30px
        @media screen and (max-width: 600px)
          font-size 16px
          font-weight 500
          padding-bottom 20px

    .form-label
      display block
      font-weight 600
      margin-bottom 10px
      @media screen and (max-width: 600px)
        font-weight 500
        margin-bottom 5px

    .form-item
      position relative
      margin-bottom 24px
      & + .form-item
        margin-top 20px
        @media screen and (max-width: 600px)
          margin-top 15px
      > .title-wrap
        display flex
        justify-content space-between
        align-items flex-start
      > .title
      > .title-wrap > .title
        display flex
        align-items center
        font-size 14px
        font-weight bold
        margin-bottom 10px
        margin-left 1px
        > small
          font-weight 500
          color #b2b2b2
          margin-left 5px
        @media screen and (max-width: 600px)
          margin-left 0
          > small
            font-size 12px
            font-weight 600
            line-height 1.92


      .form-control
        display flex
        justify-content space-between
        align-items center
        width 400px
        max-width 100%
        &.wide
          width none
        .add-option input[type="text"]
        .add-option input[type="number"]
        input.flatpickr-input
        > input[type="text"]:not(.cur-year)
        > input[type="password"]:not(.cur-year)
        > input[type="number"]:not(.cur-year)
        > input[type="email"]:not(.cur-year)
        > input[type="datetime-local"]:not(.cur-year)
        > select:not(.cur-year)
          settings-input-style()
          position relative
          min-width 50px

      .add-option input[type="text"]
      .add-option input[type="number"]
      input.flatpickr-input
      > input[type="text"]:not(.cur-year)
      > input[type="password"]:not(.cur-year)
      > input[type="number"]:not(.cur-year)
      > input[type="email"]:not(.cur-year)
      > input[type="datetime-local"]:not(.cur-year)
      > select:not(.cur-year)
        settings-input-style()
        position relative
        min-width 50px

      //input[type="radio"]
      //  position relative

      //label
      //  margin-left 2px
      //  margin-right 12px

    .checkbox-group
    .radio-group
      display flex
      flex-direction column
      align-items flex-start
      label
        display flex
        align-items center
        margin-bottom 11px
        &:last-child
          margin-bottom 0
      input[type="radio"]
      input[type="checkbox"]
        padding 0
        margin 0 10px 0 0
        transform translateY(-1px)
      &.inline
        flex-direction row
        align-items center
        justify-content flex-start
        label
          margin-bottom 0
          & + label
            margin-left 20px


@media screen and (max-width: 375px)
  body.crew,
  body.creator-settings
    #please-mobile
      font-size 12px

body.creator-settings.setting-info
  #please-mobile
    display none

body.crew,
body.creator-settings
  #please-mobile
    position fixed
    left 10px
    right 10px
    margin 0 auto
    max-width 700px
    bottom 24px
    padding 16px 16px
    border-radius .25rem
    border 1px solid transparent
    background-color #FFD950
    color #665720
    margin-top 15px !important
    margin-bottom 0!important
    z-index 9999999
    html.setting-terms &
    html.setting-invite-info &
      display none
    .please-mobile-wrap
      display flex
      justify-content space-between
      div
        margin-left 32px
        min-width 84px
        i
          margin-right 4px
        a
          color #665720
          text-decoration underline

  .form-item
    .color-circle
      width 18px
      height 18px
      border 1px solid #fff
      border-radius 50%
      margin-right 10px
      &[style="background-color: #ffffff;"]
      &[style="background-color: #f8f3ea;"]
        border-color rgba(0, 0, 0, 0.2);
      > img
        width 100%

    .color-option-list
    .color-list
      plain-list()
      //padding-left 10px

    .color-option-list > li
    .color-list > li
      width 20%
      display inline-block
      margin-bottom 8px
      @media (max-width: 1024px)
        width 33.33333%
        margin-bottom 0
        margin-top 24px


    .color-option-list .color-option-item
      cursor pointer
      margin 0
      line-height 1
      &[data-out-of-stock="all-out-of"]
        .color-name
          color #A2A2A2
        .color-circle
          position relative
          &:before
            content ''
            position absolute
            top -4px
            left -4px
            right -4px
            bottom -4px
            background-color rgba(255,255,255,0.7)
      input[type="checkbox"]
        position absolute
        opacity 0
        z-index 0
        margin 0
        padding 0
      > *
        cursor pointer

      .color-circle
        position relative
        z-index 1
        > img
          width 18px
          height 18px
          position absolute
          top -1px
          left -1px

      .color-name
        width calc(100% - 38px)
        text-overflow ellipsis
        overflow hidden
        white-space nowrap
        position relative

      &[data-checked="true"]
        .color-circle > img
          display block

      &[data-checked="false"]
        .color-circle > img
          display none

    .color-option-list .color-option-item
    .color-list .color-list-item
      display block
      > *
        display inline-block
        vertical-align middle
        line-height 18px

  .form-item[data-limited_sale_type="period"] .sales_period
    .separator
      font-size 18px
      margin 0 12px


  .form-item[data-limited_sale_type="period"] .sales_period input[name^="sell_"][name$="_at"]
  .form-item[data-limited_sale_type="quantity"] > input[type="number"][name="quantity"],input[type="number"][name="max_purchase_per_user"]
    background-color #ffffff
    @media screen and (max-width 1024px)
      width auto !important

  .form-item[data-item_name="is_quantity_public"]
    display inline-block
    vertical-align top
  .form-item[data-item_name="colors"]
    position relative
    .check-all
      display flex
      justify-content center
      align-items center
      margin 0 0 0 1px
      cursor pointer
      white-space nowrap
      > *
        cursor pointer
      > input[type="checkbox"]
        width 18px
        height 18px
        margin 0 10px 0 0
    > .title
      margin-bottom 12px

  .form-item[data-item_name="digital_product_notice"]
    .contents
      border 1px solid #ededed
      border-radius 2px
      padding 10px 14px
      p
        margin 0
        margin-bottom 8px
      ul
        plain-list()
        list-style-type disc
        margin-left 15px
        line-height 1.57

  .form-item > input[name="profit"].digital-product-price
    width 32% !important

  #creator_settings_header
    background-color white
    .seller-studio-closer .btn
      font-size 25px
      font-weight 100
      color #666

  #creator_settings
    display flex
    .shopify &
      background-color #ffffff
      padding-top 40px

  @import 'studio/partials/header.styl';

  @import 'studio/partials/menu.styl';

  @import 'studio/partials/body.styl';

  #product-story-editor
    @import 'product/product-detail-footer.styl'
    @import 'product/product-story-editor.styl'

  .style-editing-modal
    .form-item
      .title
        i
          margin-left 4px
          font-weight normal
          font-style normal
          font-size 12px
          color #888

    .form-item--logo_img
      html[crew_name="webtoonfriends"] &
        display none
    .file_selector
      font-size 12px
      padding 3px 15px
      border-radius 4px
      border 1px solid #aaa
      background #ededed
      cursor pointer
      input[type=file]
        width 0
        height 0
        opacity 0


  .product-creation-frame
  .product-editing-frame
    > .don_wrapper
      height 100%
      > .body
        height 100%
        > .don_page
          height 100%
          > .don_wrapper
            height 100%
            > .body
              height 100%
              > .don_tab
                height 100%
                > .don_wrapper
                  height 100%
    .don_page
      >.don_wrapper
        >.header
          .don_back_page
            top 8px !important


  .product-creation-frame
    .product-creation
      height 100%


  .product-editing-frame
    .product-editing
      height 100%
      @import 'product/product-creation.styl'
      .btn-reset
        box-shadow none
        border 0 none
        color #666
        padding 0 6px
        &:active
          top 0
        &:hover
          text-decoration underline
      .btn-submit
        html[is_mobile="true"] &
          padding 0 6px

  html.creator-settings.setting-orders &
    #creator_settings_body
      >.settings-content
        >.header
          display flex
          justify-content space-between
          >.download-excel
            height 40px
            align-self flex-end
            @media screen and (max-width: 600px)
              align-self unset
              font-size 14px
              margin-top 20px
          @media screen and (max-width: 600px)
            flex-direction column
    #orders
      tr
        cursor pointer
        &:last-child
          cursor initial
    #orders_in_analytics
      >.don_wrapper
        >.body
          overflow-y hidden
      .don_tab
        >.don_wrapper
          padding 30px
          @media screen and (max-width: 600px)
            padding 20px

          >.header
            display flex
            flex-direction column
            align-items center
            width 100%
            >.download-excel
              width 203px
              margin-bottom 30px
              @media screen and (max-width: 600px)
                font-size 14px
            >.title
              display flex
              flex-direction column
              align-items center
              justify-content center
              .icon
                margin 10px 0 20px
              h2
                align-items center
                font-size 20px
                font-weight 600
                margin 0 0 5px
              p
                margin 0 0 24px
            @media screen and (max-width: 600px)
              flex-direction column
              align-items center

          >.body .table-wrapper
            height 460px
            width 100%
            overflow-y auto
          >.body table
            >tbody
              z-index 1
            >thead
              position sticky
              background-color white
              top 0
              z-index 9999



  html.creator-settings &
    #creator_settings_body
      >.header
        button[name="date"]
          display flex
          align-items center
          i
            margin-right 8px
      .pagination-mp
        padding 0
      .no_mobile
        flex 1
        display flex
        justify-content center


  html.creator-settings.setting-adjustments &
    #creator_settings_body
      >.settings-content
        >.header
          >.right
            @media screen and (max-width: 600px)
              flex-direction unset
              justify-content unset
            >.btn
              margin-left 10px
              height 40px
              @media screen and (max-width: 600px)
                font-size 14px
    #adjustments
      td.positive
        color #02bc77
      tr.is-odd-true
        background #f8fbff
  @import "studio/studio.bank_account.styl"

  .setting-withdraw-frame
    [data-show=false]
      display none
    .feature-box
      display flex
      align-items center
      margin-bottom 20px
      .link
        text-decoration underline
      .tax_guidance
        margin-right auto
      .row
        text-align right
      .features
        padding 3px 0
      .feature
        display inline-block
        margin-right 20px
    .summary-info-title
      font-weight bold

  .product-creation-frame
  .product-editing-frame
    .don_page[page_name="@PAGE/Creator/Setting/composite_template_selection"] .option button.next
      width 100%
      max-width 255px
      height 40px
      line-height 40px
      font-size 14px
      font-weight 500
      padding 0
      border-radius 2px
      background-color #3c89f9
      html.safe_area_inset_bottom &
        box-sizing content-box
        padding-bottom 34px
    #maker_frame
      #marpplizer
        .maker_go_next
          border-radius 2px
          background-color #3c89f9
      &[data-is_vector="true"]
        #marpplizer
          .maker_go_next
            background-color #000
    > .don_wrapper
      top 20px
      bottom 20px
      left 20px
      right 20px
      width auto
      height auto
      padding-top 56px
      @media (max-width: 1024px)
        top 0
        bottom 0
        left 0
        right 0
        padding-top 0
        [page_name] > .don_wrapper > .body
          padding-top 56px

    [page_name] > .don_wrapper > .header
      display flex
      position absolute
      width 100%
      height 56px
      left 0
      top 0
      justify-content center
      align-items center
      border-bottom 1px solid #dfdfdf
      z-index 3

      @media (max-width: 1024px)
        position fixed
        .don_hide_frame
          display block
          background-color transparent
          position absolute
          background-position 50% 50%
          background-repeat no-repeat
          background-size 16px
          background-image url(//s3.marpple.co/files/u_193535/2018/12/original/f_18632_1545117841977_a8X6NA3p8T4cNCvpowk4t.png)
          width 36px
          height 36px
          top 11px
          right 14px
          border none
          outline none
          &:active
            background-color #ced4da

      .title
        font-size 16px
        font-weight 500
        letter-spacing -0.5px
        > .creation-modal-breadcrumb
          plain-list()
          display flex
          align-items center

          > li
            position relative
            display block
            width 170px
            text-align center
            color #000000
            @media (max-width: 1024px)
              display none

            > span
              line-height 1

            &:not(:last-child)
              &::after
                position absolute
                top 50%
                left 100%
                transform translateY(-50%)
                display block
                width 14px
                height 28px
                content ''
                background-image: url('//s3.marpple.co/files/u_1093672/2020/9/original/ce2fefa97d2f0dd8ed7a6208919164f60b3e42021.png');
                background-size 14px 28px
                @media (max-width: 1024px)
                  display none

            &[data-active="true"]
              @media (max-width: 1024px)
                display block
              > span
                font-weight 600
                position relative
                overflow visible
                &::before
                  content ''
                  position absolute
                  display block
                  width 120%
                  height 10px
                  bottom 1px
                  left 50%
                  transform translate(-50%, 50%)
                  border-radius 5px
                  background-color #fffb00;
                  z-index -1
                  @media (max-width: 1024px)
                    display none

            &[data-active="false"]
              color #b2b2b2
              @media (max-width: 1024px)
                display none

    .category-selection
      @import 'category-selection.styl'

    .base-product-selection
      @import 'base-products.styl'

    .product-creation
      @import 'product/product-creation.styl'

    .studio-selection-badges
      position absolute
      top 16px
      left 16px
      display flex !important
      gap 4px
      @media screen and (max-width 1024px)
        top 8px
        left 8px
    .studio-selection-out-of-badge
    .studio-selection-new-badge
      position relative
      display flex
      align-items center
      justify-content center
      padding 2px 6px
      border-radius 4px
      background-color #3c89f9
      font-size 12px
      font-weight 600
      letter-spacing -0.2px
      text-align center
      color #fff
      line-height 17px
      @media screen and (max-width 1024px)
        width 34px
        height 17px
        font-size 12px
        padding-top 1px
    .studio-selection-out-of-badge
      background-color #a2a2a2
    .category-selection,
    .base-product-selection
      color #000000
      > .title
        font-size 24px
        font-weight 500
        text-align center
        margin 0 0 5px
        @media screen and (max-width 1024px)
          font-size 20px
          font-weight 600
          margin 0 0 6px

      > .description
        font-size 14px
        text-align center
        color #4c4c4c
        margin 0 0 40px
        @media screen and (max-width 1024px)
          margin 0 0 30px

    @media screen and (max-width 1024px)
      .detail_wrapper
        > .product_color
          > .inner
            > .name
              margin-bottom 10px
            .select_list.color_list
              margin-right 0

    .don_page[page_name="@PAGE/Creator/Setting/composite_template_selection"]
      .option button.next
        width 100%
        max-width 255px
        height 40px
        line-height 40px
        font-size 14px
        font-weight 500
        padding 0
        //border-radius 2px
        border none
        color #fff
        background-color #3c89f9
      @media screen and (max-width 1024px)
        .don_tab[tab_name="@TAB/Creator/Setting/composite_template_selection"]
          > .don_wrapper
            padding 55px 8px 40px
        .assoc_composite_templates
          margin-left -7px
          padding 0
          flex-wrap wrap
        .assoc_composite_template
          width calc(50% - 7px)
          margin 0 0 7px 7px
        .option
          position fixed
          height auto
          left 0
          button.next
            font-size 14px
            font-weight bold
            max-width 100%
            height 48px
            line-height 48px
            background-color #3c89f9

  .style-editing-frame
    > .don_wrapper
      width 500px
      max-width 100%
      height auto
      top 10px
      bottom 10px
      max-height 620px
      >.header
        position absolute
        top 0
        left 0
        right 0
        height 56px
        background #fff
        border-bottom 1px solid #dfdfdf
        z-index 3
        .title
          text-align center
          height 56px
          line-height 56px
          font-size 18px
    .form
      width 100%
      height 100%
      display flex
      flex-direction column
      padding 28px
      padding-top 74px

      .form-item
        &.banner
          margin-bottom 10px

        &[data-show="true"]
          display unset

        &[data-show="false"]
          display none

        .image-preview
          position relative
          border 1px solid #ccc
          border-radius 0.25rem
          padding 16px
          text-align center
          margin-bottom 4px
          &[src=""]
            display none
          img
            max-width 60%
            max-height 56px
          &.logo
            img
              max-width 50%
              max-height 30px
          .remove
            position absolute
            top -11px
            right -11px
            background #fff
            border 0
            padding 0
            font-size 20px
            outline none
            width 20px
            height 20px

      .buttons
        text-align center


@media (max-width: 1024px)
  body.crew,
  body.creator-settings:not(.maker_page)
    .style-editing-frame
      > .don_wrapper
        >.header
          position fixed

@media (min-width: 1025px)
  html.creator-settings .don_frame[is_modal="true"] > .don_wrapper
    border-radius 10px
    overflow hidden

html.creator-settings.maker_page[marpplizer_mode="short"] body.setting-products #maker_frame .buying
html.creator-settings.maker_page[marpplizer_mode="short"] body.setting-custom-asset-products #maker_frame .buying
html.creator-settings.maker_page[marpplizer_mode="short"] body.setting-custom-user-products #maker_frame .buying
  border-radius 3px

@media screen and (min-width: 1025px)
  body.setting-invite-info
    h2
      margin-bottom 30px
    h3
      margin 30px 0 20px
      padding 0
      &:first-child
        margin-top 0
    ul
      margin 0
      padding 0 0 0 20px
      li
        line-height 20px
        margin-bottom 5px
    .youtube_list
      position relative
      padding-top 18.5%
      .youtube_list_body
        display flex
        position absolute
        top 0
        left 0
        right 0
        bottom 0
        height 100%
        iframe
          vertical-align top
    .marpple_shop a
      color #3c97fe
    .invite_info_description
      padding-top 30px
      margin-bottom 30px
      border-top 1px solid #ccc
    .control.domain_name
      .domain_name_ex
        position relative
        .link
          margin -5px 0 5px
          color #999

@media screen and (max-width: 1024px)
  body.setting-invite-info
    h2
      margin-bottom 30px
    h3
      margin 30px 0 20px
      padding 0
      &:first-child
        margin-top 0
    ul
      margin 0
      padding 0 0 0 20px
      li
        line-height 20px
        margin-bottom 5px
    .youtube_list
      ._iframe
        position relative
        padding-top 56.4%
        margin-bottom 10px
        iframe
          vertical-align top
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          height 100%
    .marpple_shop a
      color #3c97fe
    .invite_info_description
      padding-top 30px
      margin-bottom 30px
      border-top 1px solid #ccc
    .control.domain_name
      .domain_name_ex
        position relative
        .link
          margin -5px 0 5px
          color #999

body.setting-info
  [data-show=false]
    display none
  //.body
  //  margin-top 30px
  .info_form
    margin-top 30px
    .controls
      .control
        input[type="text"],
        .form-control
          settings-input-style()
          & + .form-control
            margin-left 10px
            max-width 120px
        &.form-radio
          label
            display inline-block
        &.mobile
          .input
            display flex
            height 100%
            select
              width 80px
              height 36px
              margin-right 10px
              align-items center
              justify-content center
        .title
          margin-bottom 15px
        label
          display block
          font-weight 600
          margin-bottom 10px
        .input
          display flex
          justify-content space-between
          align-items center
          max-width 400px
          span.txt1
            margin-right 10px
          button
            border 1px solid #ededed
            background transparent
            color #000
            border-radius 3px
    .buttons
      margin-top 20px
      padding-top 20px
      //border-top 1px solid #eee
      button
        width 120px
        border-color rgba(0,0,0,0)
        background #3c89f9
        color #fff
        border-radius 3px
        padding 8px 21px
        line-height 1.54

html.creator.creator-settings .seller_studio_banner
  display none
  @media screen and (max-width 415px)
    font-size 11px !important
    line-height 1.4
    .m_br
      display block
  @media screen and (max-width 374px)
    font-size 10px !important
    line-height 1.4
    .m_br
      display block

html.creator.creator-settings[can_selling="false"]
  .seller_studio_banner
    display block
    padding 7px
    font-size 13px
    text-align center
    background-color #ffd950
    color #333

html.creator.creator-settings
  &.setting-arrangement
    @import "studio/studio.arrangement.styl"
  &.setting-feed_list
    @import "studio/studio.feed_list.styl"
  &.setting-feed_detail
    @import "studio/studio.feed_detail.styl"
  &.setting-invite
    @import "studio/studio.invite.styl"
  &.setting-solution
    @import "studio/studio.solution.styl"
    width 100%
    min-height 100%
    display flex
    flex-direction column
    .body.white
      width 1132px
      max-width 100%
      .request-of-solution
        margin 40px auto
        width 1080px
        >.header
          margin-bottom 16px
  &.setting-youtube
    @import "../../../modules/MShop/Studio/Youtube/F/Style/index.styl"

@import "../../../modules/MShop/Studio/PcInfo/F/Style/Mo/index.styl"

.tooltip-icon2
  display inline-block
  width 16px
  height 16px
  vertical-align middle
  margin-left 3px
  margin-bottom 2px
  color #9F9F9F
  &:hover
    color #3C89F9
  &--question-hover
    display none

.tooltip-container
  background-color #ffffff
  border 1px solid black
  border-radius 4px
  box-shadow 0 2px 8px 0 rgba(0, 0, 0, 0.2)
  padding 16px
  position relative
  text-align left

  .tooltip-container-arrow
    position absolute
    top -16px
    left 0
    right 0
    margin 0 auto
    width 10px
    height 6px
  .tooltip-icon + &,
  .icon-product-guide-popup + &,
  .tooltip-icon2 + & {
    position absolute
    width 0
    height 0
    opacity 0
  }

  > *
    margin 0
    font-weight 400
    font-size 14px
    line-height 1.43
    word-break keep-all
    color #111
    letter-spacing -0.5px
  h4
    color black
    font-weight 600
    margin 0 0 2px 0
    line-height 1.29
    letter-spacing -0.5px
  b
    font-weight 500
  div
    line-height 1.43
    letter-spacing -0.5px
    color #111
    margin-bottom 4px
    span
      color black
      font-weight 600
  ul
    margin-bottom 16px
    padding 0
    padding-left 6px
    list-style none
.tooltip-icon-wrapper
  display flex
.tooltip-question
  width 17px
  height 16px
  background-image url(//s3.marpple.co/files/u_29089/2023/1/original/8f1cf491e127f462e90c94ac5149419734878b601.webp)
  background-size 17px 16px
  margin-left 6px
  display inline-flex
  background-repeat no-repeat
  &:hover
    background-image url(//s3.marpple.co/files/u_29089/2023/1/original/58f696f099767d26e5a850e8abc861d7f0ee61301.webp)
  &--large
    width 24px
    height 24px
    background-image url(//s3.marpple.co/files/u_29089/2023/1/original/9a763f18f0592cdc7a838c084355ec3982b0deb71.webp)
    background-size 24px 24px
    margin-left 8px
    &:hover
      background-image url(//s3.marpple.co/files/u_29089/2023/1/original/2b09eb3fed9e64f3658a3076d56643940c30e5921.webp)

.tippy-box
  line-height 1
  background #fff
  html.creator &
    background-color transparent

  &[data-placement^="bottom"]
    > .tippy-svg-arrow
      top 0
      svg
        position absolute
        bottom -1px
  &[data-placement^='top']
    > .tippy-svg-arrow
      bottom 0
      > svg
        transform rotate(180deg)
        position absolute
        top -1px

.svg-content
  fill #fff

.svg-arrow
  fill black

.only-mobile
  display none
  @media screen and (max-width: 600px)
    display block


.f-color
  &-info
    color color_active !important


body.creator-settings
  .don_dialog_msg
    &.modal
      .content
        width calc(100% - 40px)
        max-width 460px
    .content
      border-radius 10px
      > .body
        margin 0 0 20px
    &.preview
      .content
        border-radius 0
        > .body
          margin 0

  .modal_content
    text-align center
    &_header
      margin-top 10px
      margin-bottom 30px
      img
        display block
        width 50px
        margin 0 auto 20px
      h3
        margin 0
        font-size 20px
        font-weight bold
    &_body
      margin-bottom 40px
      p
        margin 0 auto
        width 216px
        color #000
        & + p
          margin-top 20px
    &_actions
      display flex
      justify-content space-between
      align-items center
      button
        flex 1
      button + button
        margin-left 10px !important
    @media screen and (max-width: 600px)
      &_header
        margin-top 10px
        margin-bottom 20px
        img
          width 40px
          margin 0 auto 15px
        h3
          font-size 16px
          font-weight 600
      &_body
        margin-bottom 30px
      p
        width 80%
        font-size 12px
        & + p
          margin-top 15px
      &_actions
        display flex
        flex-direction column
        align-items stretch
        button
          flex 1
        button + button
          margin-left 0 !important
          margin-top 10px !important




@media screen and (min-width: 1025px)
  #creator_settings_layer_pop
    box-shadow 2px 2px 20px #888
    position absolute
    top 143px
    right 8.714285714285714rem
    z-index 15
    background-color #000
    .banner
      text-align center
      a
        display block
        width 100%
      img
        vertical-align top
      .mobile
        display none
    .one_day_close
      display inline-block
      padding 7px 10px 5px
      color #fff
      cursor pointer
      font-size 12px
    .close
      position absolute
      bottom 2px
      right 7px
      cursor pointer
      img
        height 14px


@media screen and (max-width: 1024px)
  #creator_settings_layer_pop
    position absolute
    top 140px
    left 10%
    right 10%
    z-index 10
    width auto !important
    overflow hidden
    border-radius 10px
    border-top-left-radius 0
    border-top-right-radius 0
    .banner
      a
        display block
        width 100%
      img
        width 100%
        vertical-align top
      .pc
        display none
    .creator_layer_btm
      position relative
      padding-top 16%
      background-color #333
      .one_day_close
        display flex
        align-items center
        justify-content center
        position absolute
        top 0
        left 0
        right 30%
        bottom 0
        padding 0 10px
        cursor pointer
        font-size 12px
        font-weight 400
        color #fff
      .close
        position absolute
        top 0
        left 70%
        right 0
        bottom 0
        border-left 1px solid #222
        img
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          height 10px
          margin auto

/* 우선 store product 에디터에서만 적용되길 바라는 코드 */
.flatpickr-wrapper
  width 100%
.product-out-of-stock-list
  padding 16px 24px
  margin 0
  list-style none
  background-color #EBF3FE
  margin-top 16px
  border-radius 8px
  font-size 14px
  line-height 22px
  color #6b6b6b
  letter-spacing -0.2px
  &__item
    display inline-block
    vertical-align top
    &:last-child
      .product-out-of-stock-list__slash
        display none
  &__slash
    color #a2a2a2
    margin 0 8px
