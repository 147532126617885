.table-wrapper
  position relative
  width 100%
  overflow-x auto
  overflow-y hidden

  > .table
    width 100%
    border-collapse collapse

    &.has-total
      tbody tr:last-child
        background-color #f8fbff

    tr
      &.empty
        td
          padding 100px 0
          text-align center
          color #b2b2b2
        p
          margin 0
          vertical-align middle
        img
          margin-left 6px
          height 18px
    th, td
      &.left
        text-align left
      &.center
        text-align center
      &.color-info
        color #3c89f9
    th
      padding 19px 20px
      text-align right
      white-space nowrap
      border-bottom 1px solid #000
      .tooltip-icon
        display inline-block
        margin-left 5px
        transform translateY(1px)
        letter-spacing 0
      @media screen and (max-width: 600px)
        padding 12px 10px
    td
      position relative
      padding 19px 20px
      text-align right
      white-space nowrap
      letter-spacing -0.5px
      border-bottom 1px solid #ededed
      @media screen and (max-width: 600px)
        padding 12px 10px
    .center-middle
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
    .product_info
      display flex
      .img_wrap
        position absolute
        top 50%
        left 20px
        width 44px
        height 44px
        border-radius 2px
        transform translateY(-50%)
        @media screen and (max-width: 600px)
          width 32px
          height 32px
        img
          width 100%
          max-width 100%
          max-height 100%
          object-fit cover
          background #f6f6f6
      span.name
        margin-left 64px
        color #4e5155
        display block
        flex 1

