&
  position relative
  width 100%
  height 80px
  display flex
  align-items center
  justify-content center
  background #fff
  font-weight 600
  @media screen and (max-width: 1024px)
    position fixed
    top 0
    left 0
    width 100%
    transition transform .25s ease-in-out;
    z-index 100
    &.headroom--unpinned
      transform translateY(-100%)
  &-body
    position relative
    width 100%
    height 100%
    padding 0 20px
    display flex
    align-items center
    box-sizing border-box
    border-bottom 1px solid #dcdcdc
    @media screen and (min-width: 1025px)
      width 96%
  &.has_banner &-body
    border-bottom-color transparent

  &-logo
    margin 0 24px 0 0
    padding 0
    font-size 0px
    line-height 0
    width 0
    flex-grow 1
    img
      max-width 100%
      max-height 26px
  &-logo[data-has-logo="false"]
    font-size 20px
    line-height normal

  &-right_gnb
    margin 0
    padding 0
    height 40px
    list-style none
    display flex
    align-items center
    > li
      height 100%
      width 40px
      font-size 0
      position relative
      display flex
      align-items center
      justify-content center
    > li:not(:first-child)
      margin-left 9.4px
    > li[data-count]:not([data-count="0"]):after
      position absolute
      top -2px
      right -2px
      content attr(data-count)
      display inline-flex
      justify-content center
      align-items center
      background-color rgba(255 0 0, 0.9)
      color #ffffff
      min-width 8px
      height 20px
      padding 0 6px
      border-radius 10px
      font-size 10px
      font-weight 600
    .wish
      display none
  @media screen and (max-width: 1024px)
    &-right_gnb
      margin 0 -8px 0 0
      padding 0
      height 40px
      list-style none
      display flex
      align-items center
      > li
        height 100%
        width 36px
        font-size 0
        position relative
        display flex
        align-items center
        justify-content center
      > li[data-count]:not([data-count="0"]):after
        position absolute
        top -2px
        right -2px
        content attr(data-count)
        display inline-flex
        justify-content center
        align-items center
        background-color rgba(255 0 0, 0.9)
        color #ffffff
        min-width 8px
        height 20px
        padding 0 6px
        border-radius 10px
        font-size 10px
        font-weight 600
      .wish
        display none

@media screen and (min-width: 1025px)
  &
    height 120px
    &-body
      padding 0
      max-width 1120px
    &-logo
      height 28px
      max-height 28px
      img
        max-height 28px
    &-logo[data-has-logo="false"]
      font-size 26px
