.cv_preview_editor_frame.don_frame[frame_name="maker.in_out_pc"]
  .don_page
    >.don_wrapper >.header >.save
      display none
  >.don_wrapper
    width 95%
    height 95%
.cv_preview_editor
  border 1px solid black
  border-radius 4px
  padding 8px
  position absolute
  left 10px
  bottom 10px
  z-index 10000
  input[type="number"]
    width 60px
    text-align center
  .main-wrapper
    .end
      display none
    .start_end
      display none
    &[adjust="size"]
      [adjust-name="size"]
        .start
          display none
        .start_end
          display flex
        .end
          display block
    &[adjust="upside_downside"]
      [adjust-name="upside_downside"]
        .start
          display none
        .start_end
          display flex
        .end
          display block
    &[adjust="reflection"]
      [adjust-name="reflection"]
        .start
          display none
        .start_end
          display flex
        .end
          display block
    &[adjust="composite_area"]
      [adjust-name="composite_area"]
        .start
          display none
        .start_end
          display flex
        .end
          display block
    &[adjust="texture_url"]
      [adjust-name="texture_url"]
        .start
          display none
        .start_end
          display flex
        .end
          display block
  &.hided
    .group1
      display none
  .for_is_cylinder
  .for_is_shade
    display none
  &.is_cylinder
    .for_is_cylinder
      display block
  &.is_shade
    .for_is_shade
      display block
  .row
    display flex
    align-items center
    >*
      margin-right 10px
      &:last-child
        margin-right 0
  .group1
    margin-bottom 10px
    >.wrapper
      padding 5px
      border 1px solid black
      border-radius 4px
      >.row
        margin-bottom 10px
        &:last-child
          margin-bottom 0
    > label
      display block
      padding 5px
  .file_upload
    input
      display none
  > div
    .move
      button
        width 60px
