.fx-auto-complete
  position relative
  .fx-auto-complete-list
    position absolute
    left 0
    top 2.4rem
    z-index 1
    ul.fx-auto-complete-list-wrap
      background #eee
      border 1px solid rgba(24,28,33,0.1)
      border-top none
      padding 0
      margin 0
      list-style none
      overflow hidden
      &[len="0"]
        display none
      li
        cursor pointer
        padding 10px
        &[selected="true"]
          color #fff
          background-color #999
