
.mp_maker
  &[data-is_vector="true"]
    canvas.upper-canvas
      display none
.maker-product-info__bp-option-groups
  *
    box-sizing border-box
  span.head
    display block
    font-weight bold
    margin-bottom 6px
  .bp_option_group[data-ui_type_pc="SELECT"]
  .bp_option_group[data-ui_type_pc="IMAGE_SELECT"]
    select
      width 100%
      font-size 15px
      border 1px solid #d5dbe0
      cursor pointer
      padding 10px 16px
      background no-repeat url(//s3.marpple.co/files/u_193535/2021/4/original/bab67e04f0933fb7769f9c2a85ac43db952703641.png) right 16px bottom 50%
      background-size 10px 7px
      appearance none
      -moz-appearance none
      -webkit-appearance none
    select
      display block !important
    .select_box
      display none !important
  .bp_option_group[data-ui_type_pc="BUTTON"]
    select
      display none !important
    .select_box
      display flex !important
  .bp_option_groups
    &[data-is_designed="true"]
      .bp_option_group
        &[data-is_lockable="true"]
          select
            opacity 0.4
            background #f1f1f1 no-repeat url(//s3.marpple.co/files/u_193535/2021/5/original/93604cbd49a7593ed268f972cbc8a94e9208664d1.png) right 16px bottom 50%
  .bp_basic_info
    *
      font-size 20px
    margin-bottom 43px
    .name
      margin-bottom 8px
  .bp_option_groups
    &[data-is_designed="true"]
      .bp_option_group[data-is_lockable="true"]
        .option:not(.selected)
          opacity 0.4
    .title
      font-size 14px
      font-weight bold
      margin-bottom 8px
    .select_box
      display flex
      flex-wrap wrap
      .option
        cursor pointer
        margin-bottom 8px
        height 40px
        display flex
        justify-content center
        align-items center
        border 1px solid #d5dbe0
        margin-right 8px
        text-align center
        html[marpplizer_mode="short"] &
          width 80px
          &:nth-child(2n)
            margin-right 0
        html[marpplizer_mode="basic"] &
          width 89px
          &:nth-child(4n)
            margin-right 0
        html[marpplizer_mode="basic"] &
        html[lang="en"] &
          width 120px
          &:nth-child(3n)
            margin-right 0
        &.selected
          color #0157ff
          border 1px solid #0157ff
    select
      display none
    .bp_option_group[data-is_visibility="true"]
      margin-bottom 40px
    .bp_option_group[data-is_visibility="false"]
      display none
.maker-mobile-product-info__bp-option-groups
  background #ffffff
  margin-bottom 16px
  .delivery-price
    margin 10px 16px 0
    font-size 12px
  .select_box
    display none
  .thumbnail
    margin-bottom 24px
  .bp_basic_info
    margin 0 16px
  .bp_basic_info
    font-size 14px
    margin-bottom 16px
    .name
      margin-bottom 7px
    .price
      font-size 16px
      font-weight bold
      color #ea291f
  .bp_option_groups
    .title
      font-size 12px
      margin-bottom 4px
    display flex
    flex-wrap wrap
    justify-content space-between
    >[data-is_visibility="false"]
      display none
    >[data-is_visibility="true"]
      width calc(50% - 8px)
      position relative
  .bp_option_group
    select
      width 100%
      border 1px solid #d5dbe0
      padding 8px 16px
      background no-repeat url(//s3.marpple.co/files/u_193535/2021/4/original/bab67e04f0933fb7769f9c2a85ac43db952703641.png) right 16px bottom 50%
      background-size 10px 7px
      appearance none
      -moz-appearance none
      -webkit-appearance none
      color #000000
  .bp_option_groups
    &[data-is_designed="true"]
      .bp_option_group
        &[data-is_lockable="true"]
          select
            opacity 0.4
            background #f1f1f1 no-repeat url(//s3.marpple.co/files/u_193535/2021/5/original/93604cbd49a7593ed268f972cbc8a94e9208664d1.png) right 16px bottom 50%
