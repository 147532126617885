.don_frame[frame_name="/modules/NewMaker/Preview/Modal/S/Mui/frame.js"]
  .simple_modal
    .done
      background #000
      html.creator &
        background #3c89f9
      color #fff
  .simple_modal_wrapper
    .body
      display flex
      flex-direction column
      .preview-wrapper
        flex 1
        display flex
        justify-content center
        align-items center
        height 85%
        overflow hidden
      .description
        > *
          font-size 16px
          padding 0 45px
          padding-bottom 8px
    .preview
      display flex
      justify-content center
      align-items center
      padding 40px
      img
        width 100%
