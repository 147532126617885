&
  .product-footer-tab-header
    height 40px
    ul
      width 100%
      height 100%
      margin 0
      padding 0
      list-style none
      display flex
      box-sizing border-box
      border-color #dcdcdc
      border-style solid
      border-width 1px 0
      li
        width 0
        height 100%
        flex-grow 1
        padding 0 6px
        a
          display flex
          align-items center
          justify-content center
          width 100%
          height 100%
          margin 0
          font-size 12px
          font-weight 600
        &.selected
          background-color: #f5f5f5;

      li.qna
        display none
      html[lang="kr"] & li.qna
        display list-item

      html[lang="en"] & li
        width auto
  .product-footer-tabs
    .product-footer-tab
      display none
      &.selected
        display block
      &.qna-tab
        @import "product-detail-footer.qna-tab.styl"
      &.delivery-refund-tab
        @import 'product-detail-footer.delivery-refund-tab.styl'
  .product-story-wrap
    margin-top 48px
    word-break break-all
    @media screen and (max-width: 1024px)
      padding 0 16px
    .product-story-common
      margin 50px 0 100px
      font-size 20px
      line-height 1.15
    .story-section
      width 500px
      max-width 100%
      margin 0 auto
      .sns-wrap
        margin-bottom 56px
        iframe
          min-width auto !important
          max-width 100% !important
        .fb_iframe_widget
          span
            max-width 100% !important
      .youtube-wrap
        width 100%
        height 0
        padding-bottom 56.25%
        position relative
        iframe
          position absolute
          top 0
          left 0
          right 0
          bottom 0
          width 100%
          height 100%
      &#title-section
        margin-bottom 30px
        width auto
        text-align center
        h2
          margin 0
          font-size 37px
          font-weight bold
          line-height 1.24
      &#description-section
        width auto
        font-size 17px
        line-height 1.41
        margin-bottom 60px
        text-align center

      &#image-section
        width 912px
        margin 0 auto
        .product-story-image
          width 100%
          margin-bottom 56px
          text-align center
          position relative
          font-size 0
          max-width 100%
          img
            width 100%

@media screen and (min-width: 1025px)
  &
    .product-footer-tab-header
      ul
        li
          a
            font-size 14px
