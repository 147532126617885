.don_frame[frame_name="/modules/NewMaker/CompositePreview/S/Mui/frame.js"]
  .simple_modal
    .done
      background #000
      html.creator &
        background #3c89f9
      color #fff
  .simple_modal_wrapper
    .body
      display flex
      flex-direction column
      .preview-wrapper
        flex 1
        display flex
        justify-content center
        align-items center
        height 85%
        overflow hidden
        margin-bottom 40px
      .description
        > *
          font-size 16px
          padding 0 45px 8px
          @media screen and (max-width: 1024px)
            padding 0 25px 8px
    .preview
      display flex
      justify-content center
      align-items center
      height 100%
      margin-bottom 40px
      img
        max-height 100%
        max-width 100%
    @media screen and (max-width: 1024px)
      .footer
        padding 0 20px 8px
    @media screen and (max-width: 308px)
      .footer
        button
          padding 0
          margin 0
          &:first-child
            margin-right 4px
