point-color = #002aff
point-color2 = #3c89f9
black = #000
white = #fff
grey = #b2b2b2

flexBox(t, j, a, d = row)
  display t
  justify-content j
  align-items a
  flex-direction d

absolute(t, r, b, l)
  position absolute
  top t
  right r
  bottom b
  left l

status-color(color = #5eda42)
  border 1px solid color
  color color

font(fs = 14px, fw = inherit, ls = inherit, lh = inherit, c = inherit)
  font-size fs
  font-weight fw
  letter-spacing ls
  line-height lh
  color c

description()
  font-size 12px;
  font-weight 600
  letter-spacing -.5px
  line-height 23px
  color grey

resetSpace()
  margin 0
  padding 0

resetTextarea()
  resize none
  overflow-y: hidden
  outline: none

blind()
  position absolute !important
  width 1px
  height 1px
  overflow hidden
  clip rect(1px, 1px, 1px, 1px)

roundBox(w = 520px, h = 340px, bd = 1px dotted #d9d9d9)
  display flex
  flex-direction column
  justify-content center
  align-items center
  width w
  height h
  border bd
  border-radius 6px
  background-color #fff

addButton(w = 60px, height = 60px)
  display block
  width w
  height w
  border-radius 50%
  background point-color2 url(//s3.marpple.co/files/u_1093672/2020/9/original/01a700139bed7f43db3a597323abce7bfa19a8d51.png) no-repeat center;
  background-size 30px

addText()
  margin-top 20px
  text-align center
  font-size 14px
  color #4c4c4c

resetButton()
  margin 0
  padding 0
  border none
  background none

inputStyle(w = 100%, h = 40px)
  padding-left 13px
  width w
  height h
  border 1px solid #ededed !important
  border-radius 2px
  line-height 1.5
  font-weight 500
  &::placeholder
    color grey

icoInfo()
  display block
  margin 0 5px 0 -5px
  width 14px
  height 14px
  background url(//s3.marpple.co/files/u_1396787/2020/11/original/c785b38ef993ac36b31c921fa619edbbeba1a1571.png) no-repeat 0 0
  background-size 100%
  content ''

contentsPadding()
  padding-top 20px

deleteButton(w = 30px, h = 30px, bgz = 22px)
  display block
  width w
  height h
  border 1px solid grey
  border-radius 50%
  background #fff url(//s3.marpple.co/files/u_1165777/2020/11/original/35967a336e44aa6823fdd8612d65f621bf0cabde1.png) no-repeat center
  background-size bgz
  text-indent -999em

commonResetButton()
  display flex
  justify-content center
  align-items center
  border-radius 2px
  letter-spacing -.5px

whiteButton()
  commonResetButton()
  box-sizing border-box
  padding 0 24px
  min-width 72px
  height 40px
  border 1px solid #d9d9d9
  background-color #fff
  color black

submitButton()
  commonResetButton()
  box-sizing border-box
  padding 0 24px
  min-width 120px
  height 40px
  border none
  background-color #3c89f9
  font-weight 600
  color #fff !important
  &::before
    display block;
    margin-right 10px
    width 12px
    height 8px
    background url(//s3.marpple.co/files/u_1093672/2020/9/original/17dfcb4713cfa10c1f97391cd0a67d3af46f10521.png) no-repeat 0 0
    background-size 100%
    content ''

countryIcon()
  position relative
  input
    padding-left 38px
  &::before
    position absolute
    top 10px
    left 10px
    width 20px
    height 20px
    background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/ecd38341eb6b9e0e820a28f1f2203956a23a0ad61.png)
    background-repeat  no-repeat
    background-size 100%
    content ''
  &--en
    &::before
      background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/ce04b2abdf2d685ed86edc7f2d6390c5e6fe1f8f1.png)
  &--jp
    &::before
      background-image url(//s3.marpple.co/files/u_1396787/2020/11/original/4a057f84e5d2e2a9dde8f481a75b70581835940c1.png)
limitLine(l)
  display -webkit-box
  -webkit-box-orient vertical
  -webkit-line-clamp l
  overflow hidden
