.don_frame[frame_name="marstagram_editor"]
  &.hide_frame
    visibility hidden
  > .don_wrapper > .body
    overscroll-behavior contain
  //.rangeslider
  //  width 100%
  //  background-color #cccccc
  //  box-shadow none
  //  border-radius 0
  //  height 3px
  //.rangeslider__fill
  //  height 3px
  //.rangeslider__handle
  //  left 0
  //  top 1px
.don_page[page_name="marstagram_editor"]
  width 100%
  height 100%
  >.don_wrapper
    width 100%
    height 100%
    >.body
      width 100%
      height 100%
.don_tab[tab_name="marstagram_editor"]
  width 100%
  height 100%
  .marstagram_title span
    display flex
    justify-content center
    align-items center
    height 24px
    font-size 20px
    color #000
    margin-top 40px
    @media screen and (max-width: 1024px)
      display none
  >.don_wrapper
    width 100%
    height 100%
    .swiper-wrapper
      width 100%
    .swiper-slide
      display flex
      justify-content center
      align-content center
    .editor_body
      width 100%
      height 100%
      display flex
      flex-direction column
      flex-wrap wrap
      @media screen and (max-width: 1024px)
        position relative
      .div_canvas_box
        display flex
        justify-content center
        align-items center
        width 624px
        height 100%
        box-sizing border-box
        @media screen and (max-width: 1024px)
          flex-grow 1
          order -1
          width 100%
          height 0
        > .canvas_box
          display flex
          justify-content center
          align-items center
          width 100%
          height 100%
          canvas
            transition width 0.2s linear, height 0.2s linear
      .div_editor_selection_box
        @media screen and (max-width: 1024px)
          border-top 1px solid #e9ecef
        @media screen and (min-width: 1025px)
          display flex
          justify-content center
          align-items center
          box-sizing border-box
          width 400px
          > .editor_selection_box
            display flex
            justify-content center
            align-items center
            border-bottom solid 1px #d5dbe0
            width 100%
            margin-top 40px
            box-sizing border-box
            button
              outline none
              background-color transparent
              border none
              font-size 16px
              color #868e96
              width 112px
              height 51px
              &.selected
                color #000
                border-bottom solid 1px black
        @media screen and (max-width: 1024px)
          order 1
          width 100%
          margin-bottom 8px
          display flex
          justify-content center
          align-items center
          > .editor_selection_box
            margin-top 5px
            display flex
            justify-content center
            align-items center
            button
              width 32px
              height 32px
              padding 5px
              border none
              outline none
              background-color #fff
              background-repeat no-repeat
              background-position center
              background-size 22px 22px
            button[item="filter"]
              background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/25ccaed0605813808dbc0badfe0b0b75211dd8951.png)
              &.selected
                background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/da16fc8b198a37bc92807282d6d4b6abd3cae52f1.png)
            button[item="adjustment"]
              background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/ab9c78606179f30512e674a654c71645933736e61.png)
              margin 0 16px
              &.selected
                background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/b25d52d1dbacaae890a4df3716362d8059235e161.png)
            button[item="crop"]
              background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/57472e0038a4ad9532a7d63a2644bec2a83d62bd1.png)
              &.selected
                background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/b4c6aaa243f693a4fac2068f6ecd9f090a7e81131.png)
      .div_editor_items_box
        display flex
        justify-content center
        align-items center
        box-sizing border-box
        width 400px
        @media screen and (max-width: 1024px)
          margin-bottom 5px
          order 0
          width 100%
          height auto
        > .editor_items_box
          display flex
          justify-content space-evenly
          align-items center
          width 100%
          height 100%
          margin 0 auto 5px auto
          box-sizing border-box
          .editor_items
            width 100%
            height 140px
            @media screen and (max-width: 1024px)
              height 160px
            .swiper-button-next
              width 9px
              height 20px
              padding 1px
              object-fit contain
              background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/7e773245eea1bb755286650d854f7489e5d3fc3e1.png)
              background-size contain
              right 24px
            .swiper-button-prev
              width 9px
              height 20px
              padding 1px
              object-fit contain
              background-image url(//s3.marpple.co/files/u_1187078/2021/1/original/a4866e24e672fc9e713c10b85386e738204b0c2f1.png)
              background-size contain
              left 24px
          .editor_items.filters
            flex-flow column
            position relative
            margin-top 40px
            @media screen and (max-width: 1024px)
              margin-top 24px
            .filter_items
              .filter_item_canvases
                width 308px
                padding 2px
                display flex
                @media screen and (min-width: 1025px)
                  padding 0 5px 6px 5px
                @media screen and (max-width: 1024px)
                  width 100%
                .filter_item
                  display flex
                  flex-direction column
                  justify-content center
                  align-items center
                .swiper-wrapper
                  .swiper-slide
                    width auto
                  span
                    height 14px
                    font-size 12px
                    line-height 1.17
                    color #868e96
                    &.selected
                      color #0157ff
                  .blue_box
                    position absolute
                    bottom -1px
                    width 72px
                    height 72px
                    background-color transparent
                    border-color transparent
                    border-radius 5px
                    @media screen and (max-width: 1024px)
                      width 64px
                      height 64px
                    &.selected
                      border 1px solid #0157ff
                      background-color rgb(1, 87, 255)
                    &.shadow.selected
                      background-color transparent
                      border-color transparent
                      box-shadow 0 0 6px 0 rgba(1, 87, 255, 0.3);
                  canvas.filter
                    cursor pointer
                    z-index 9
                    opacity 1
                    border-radius 4px
                    margin-top 10px
                    &.selected
                      opacity 0.8
              .filter_item_sliders
                width 304px
                margin 25px auto 0 auto
                @media screen and (max-width: 1024px)
                  width 80%
                  margin 33px auto 0 auto
                .filter_item_slider
                  display none
                  flex-flow column
                  justify-content center
                  align-items center
                  width 100%
                  input
                    outline none
                  span
                    width 100%
                    text-align center
                    color #000
                    font-size 14px
                    margin-top 8px
                  span[item="original"]
                    color #868e96
                    @media screen and (min-width: 1025px)
                      margin-top -10px
                    @media screen and (max-width: 1024px)
                      margin-top -7px
                  &.selected
                    display flex
          .editor_btn_slider_design
            position relative
            flex-flow column
            margin-top 40px
            @media screen and (max-width: 1024px)
              margin-top 24px
            .btns_container
              cursor pointer
              z-index 0
              width 308px
              height 110px
              padding 2px
              @media screen and (min-width: 1025px)
                padding 0 6px 6px 6px
              @media screen and (max-width: 1024px)
                width 100%
              .btn_item
                z-index 99
                width 70px
                display flex
                justify-content center
                flex-flow wrap
                align-content flex-start
                &.hidden
                  display none
                span
                  height 14px
                  font-size 12px
                  line-height 1.17
                  color #868e96
                  &.selected
                    color #0157ff
                .button
                  display flex
                  justify-content center
                  align-items center
                  box-sizing border-box
                  width 72px
                  height 72px
                  @media screen and (max-width: 1024px)
                    width 64px
                    height 64px
                  margin-top 10px
                  background-color #fff
                  background-repeat no-repeat
                  background-position center
                  background-size 22px 22px
                  outline none
                  border-radius 36px
                  border solid 1px #d5dbe0
                  img
                    width 22px
                    display none
                  img.btn_img_selected
                    display none
                  img.btn_img_normal
                    display block
                  &.selected
                    border solid 1px #0157ff
                    box-shadow 0 4px 16px 0 rgba(178, 215, 255, 0.5)
                    background-color #fff
                    & img.btn_img_selected
                      display block
                    & img.btn_img_normal
                      display none
                &.initializer
                  .button
                    background-image url(https://s3.marpple.co/files/u_1187078/2021/1/original/2728deaa8c2059ab6523174daf68aafeca6200271.png)
                  &:active
                    .button
                      background-image url(https://s3.marpple.co/files/u_1187078/2021/1/original/2f3b19421b0737362efe5b6921fe679acac120951.png)
                      border solid 1px #0157ff
                      box-shadow 0 4px 16px 0 rgba(178, 215, 255, 0.5)
                      background-color #fff
                    span
                      color #0157ff
            .slider_items
              width 304px
              margin 11px auto 0 auto
              @media screen and (max-width: 1024px)
                width 80%
              .slider_item
                display none
                flex-flow column
                justify-content center
                align-items center
                width 100%
                @media screen and (max-width: 1024px)
                  margin 5px 0
                input
                  outline none
                span
                  width 100%
                  text-align center
                  color #000
                  font-size 14px
                  margin-top 8px
                &.selected
                  display flex
      .controller
        @media screen and (min-width: 1025px)
          display flex
          justify-content center
          position absolute
          margin-bottom 16px
          width 400px
          bottom 0
          right 0
          button
            outline none
            border solid 1px #d5dbe0
            font-size 14px
            width 164px
            height 40px
            &[action="cancel"]
              background-color #fff
              margin-right 8px
            &[action="complete"]
              background-color #000
              color #fff
        @media screen and (max-width: 1024px)
          button
            position absolute
            border none
            outline none
            height 32px
            background-color #fff
            color #000
            font-size 14px
            bottom 8px
            &[action="cancel"]
              left 6px
            &[action="complete"]
              right 6px
    .editor_body
      .editor_items
        display none
      &[current_editor="filter"]
        .editor_items.filters
          display flex
        .canvas_box
          transition background-color 0.3s linear
          background-color #F8F9FA
      &[current_editor="adjustment"]
        .editor_items.adjustment
          display flex
        .canvas_box
          transition background-color 0.3s linear
          background-color #F8F9FA
      &[current_editor="crop"]
        .editor_items.crop
          display flex
        .canvas_box
          transition background-color 0.3s linear
          background-color rgb(10, 10, 10)