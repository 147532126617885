@import 'helper.styl'

&
  width: 100%;
  margin: 0 auto;
  padding: 40px;
  @media screen and (max-width 1024px)
    padding: 30px 20px 40px;

  .base-products
    plain-list()
    margin-left -10px
    @media screen and (max-width 1024px)
      display flex
      justify-content flex-start
      align-items flex-start
      flex-wrap wrap
      margin-left -8px
    > li
      position relative
      display inline-block
      width calc(20% - 10px)
      vertical-align top;
      margin-left 10px
      margin-bottom 32px
      cursor pointer
      text-align center
      color #000000
      @media screen and (max-width 1024px)
        flex-basis calc(33.33333% - 8px)
        width auto
        display block
        margin-left 8px
        margin-bottom 20px
      > *
        display block
      img
        width 100%
        margin-bottom 20px
        border-radius 10px
        @media screen and (max-width 1024px)
          border-radius 4px
          margin-bottom 10px
      .name
        font-size 16px
        margin-bottom 5px
        @media screen and (max-width 1024px)
          margin-top 10px
          margin-bottom 4px
          font-size 11px
          font-weight normal
        small
          display block
          font-size 14px
          margin 4px auto
      .price
        font-size 16px
        font-weight 600
        @media screen and (max-width 1024px)
          font-size 11px
