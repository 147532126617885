&
  .qna
    margin-top 32px
    padding 0 16px
    line-height 1.6

    article
      margin-bottom 40px

      h4
        margin 0 0 12px 0
        font-weight 600

      p, section
        margin 0 0 10px 0

      .with_list
        p
          margin 0 0 4px 0

      ul, ol
        padding-left 18px
        margin 4px 0

    .bold
      font-weight 600

    .faq_link
      margin 0
      a
        margin-left 4px
        text-decoration underline
        cursor pointer

@media screen and (min-width: 1025px)
  &
    .qna
      padding 40px 64px

      article
        margin-bottom 48px

        h4
          font-size 20px
