#creator_settings_menu
  position relative
  width 210px
  background #fff
  flex-shrink 0
  @media screen and (max-width: 1480px)
    width 210px

  @media screen and (max-width: 1024px)
    padding-top 0
    padding-bottom 0
    position absolute
    top 68px
    left -100%
    width 100%
    min-height 0
    z-index 10
    .iscroll_wrapper
      overflow hidden
      height 100%
    &[menu-open=true]
      left 0

  @media screen and (max-width: 600px)
    top 0
    left calc(-100%)
    width 100%

  .close-btn
    position absolute
    top 16px
    right 16px
    z-index 10
    .btn
      width 24px
      height 24px
      vertical-align middle
      font-size 30px
      font-weight 100
      color #141414
  .inner_menu_wrapper
    width 100%
    transform: translate3d(0,0,0);
  .menu_items
    plain-list()
    padding-top 20px
    //padding-bottom 40px

    .divider
      height 1px
      background-color #f2f2f2
      margin 10px 0

    > *
      font-size 14px
      padding 0
      [data-show=false]
        display none
      .menu
        cursor pointer
        display flex
        align-items center
        width 100%
        padding 14px 16px
        border-radius 4px
        position relative
        font-size 14px
        .solution_count
          min-width 20px
          height 20px
          background #3c97fe
          border-radius 18px
          display flex
          align-items center
          justify-content center
          color #fff
          font-size 12px
          margin-left 6px
          padding-bottom .5px
          &[data-solution_count="0"]
            display none
        span
          user-select none
        .submenu_icon
          font-size 12px
          width 12px
          height 12px
          margin-left auto
          transition transform 100ms linear

      ul
        padding 0
        list-style-type none
        .menu
          padding-left 48px
      .menu_dropdown
        position absolute
        opacity 0
      .sub_menu_items
        height 0
        overflow hidden
        li
          list-style none
      .menu_dropdown:checked ~
      .sub_menu_items
        height auto
      .menu
        .submenu_icon
          transform rotateZ(-90deg)
      .sub_menu
        user-select none
        cursor pointer
        display: flex;
        align-items: center;
        color: #a3a4a6;
        width 100%
        padding 12px 10px 12px 32px
        border-radius 4px
        position relative
        font-size 14px
        i
          font-size 20px
          width 20px
          height 20px
          margin-right 16px
        &[data-active="true"]
          color #000
        @media screen and (min-width: 1025px)
          &:hover
            color #000
  .lang
    margin 32px 0 0 32px
    padding 0
    list-style none
    li
      display inline-block
      margin-right 16px
      a
        color: #a3a4a6;
        &[data-active="true"]
          color #000
          text-decoration underline
      &:last-child
        margin-right 0
  .for-the-seller
    font-size 13px
    font-weight 500
    letter-spacing -0.3px
    color #a2a2a2
    padding 20px 20px 15px
  .menu-bottom
    padding 0 20px 12px
    letter-spacing -0.5px
    font-size 13px
    color #4e4e4e
    display flex
  .setting-menu-count
    display inline-block
    min-width 24px
    padding 2px 3px 1px
    margin-left 5px
    border-radius 9px
    color #fff
    font-size 12px
    text-align center
    background-color #3c89f9
  .menu-live-chat
    font-size 13px
    padding 12px
    border-radius 2px
    background-color #ebf3fe
    margin 20px 20px 0
    font-weight 600
    letter-spacing -0.28px
    color #3c89f9
    display flex
    align-items center
    justify-content center
  @media screen and (max-width: 1024px)
    .for-the-seller
    .menu-live-chat
      font-size 14px
    .menu-bottom
      font-size 14px
      padding 0 20px 14px
