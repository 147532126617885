@media screen and (max-width: 1024px)
  .don_frame[frame_name="maker.picker.bpcs"]
    .don_wrapper
      >.color
        .preview_title
          display none
        &[is_transparent="true"]
          .preview_title
            display block
        >.options
          display flex
          justify-content center
          align-items center
