.don_frame[frame_name="maker.in_out_pc"]
  >.don_wrapper
    width 85%
    height 85%
  .open_chooser
  .open_blue_line
    display none
  .don_page >.don_wrapper >.header
    height 58px
    display flex
    justify-content center
    align-items center
    .save
    .don_hide_frame
      display block
      position absolute
      border 1px solid #424140
      background #424140
      border-radius 5px
      color #fff
      width 50px
      height 31px
      top 12px
    .save
      right 20px
    .don_hide_frame
      left 20px
