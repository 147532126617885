html.maker_page[lang="en"]
html.maker_page[lang="jp"]
  .request_bg_removal
    display none !important
@media screen and (max-width: 1024px)
  .don-tab-body-i-scroll
    overflow hidden
    >.don_wrapper
      >.header
        z-index 2
      >.body
        z-index 1
    &__wrapper
      padding-bottom 40px
  .m_image_pattern_tab
    background #fff
    >.don_wrapper
      >.header
        height 48px
  .m_image_pattern
    &.default_pattern
      .option.block
        display block
      .controls.range_style
      .controls.type
        opacity 0.2
    .controls
      &.number
        z-index 2
        position relative
      &.range_style
        z-index 1
      &.type
        z-index 1
    .option.block
      display none
      position absolute
      left 0
      top 100%
      right 0
      width 100%
      height 160px
    .controls
      margin-bottom 20px
      height 32px
      display flex
      > .head
        line-height 32px
        flex-basis 56px
        html[lang="jp"] &
          flex-basis 75px
        margin-right 25px
        font-size 14px
        color color_black
      > .body
        display flex !important
        height 32px
        justify-content space-between
        width 100%
      &:nth-last-child(1)
        margin-bottom 0
      &.number
        .control.number
          position relative
          width 43%
          border 1px solid #dee2e6
          box-sizing border-box
          height 100%
          input
            height 100%
            background transparent
            text-align center
            font-size 14px
            border none
            width 100%
            opacity 1
            margin 0
            box-sizing border-box
            padding 0
          .minus
          .plus
            position absolute
            width 40px
            height 32px
          .minus
            top 0
            left 0
            background url(//s3.marpple.co/files/u_193535/2018/10/original/f_12363_1540961884802_iyGL0ruH8od3s0efb7xq.png) no-repeat 50% 50%
            background-size 10px
          .plus
            top 0
            right 0
            background url(//s3.marpple.co/files/u_193535/2018/10/original/f_12364_1540961884802_t3nsH1aBxUTv3G9RI5bv.png) no-repeat 50% 50%
            background-size 10px
        .control.locking
          width 30px
          background url(//s3.marpple.co/file/guest/2018/1/original/f_1485_1515057400983_W3nG6bCHran9JilRFB.svg) no-repeat 50% 50%
          background-size 14px
          cursor pointer
          &[locked="false"]
            background-image url(//s3.marpple.co/file/guest/2018/1/original/f_1632_1516773453472_inw5hFI0qnM5f5tXi5X2H.svg)
      &.range_style
        .control.number
          border 1px solid #dee2e6
          flex-basis 80px
          height 32px
        .value
          opacity 1 !important
          background-color #fff
          font-size 14px
          width 100%
          height 100%
          box-shadow none
          border none
          padding 0
          text-align center
          box-sizing border-box
        .control.range
          width 80%
          position relative
        .control.number
          margin-left 8px
      &.type
        .control.type.btn
          button
            padding 0
            font-size 14px
            outline none
            background-color #ffffff
            border solid 1px #d5dbe0
            width 31%
          button.selected
            color color_active

@media screen and (min-width: 1025px)
  .mp_maker.cv_object_selected.from_library
    #marpplizer
      .image_color.section
        display none
      .request_bg_removal.section
        display block
  .mp_maker
    .fit_to_print_width
    .m_image_pattern
    .request_bg_removal
      >.head
        margin-bottom 13px !important
        cursor pointer
        .option.check_box
          display inline-block
          vertical-align middle
          width 16px
          height 16px
          line-height 12px
          margin-right 8px
          background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_13773_1542340312547_B1sxK4u0da6HtS6qA7h4l5a.png) no-repeat 50% 50%
          background-size 16px
        &.checked
          .option.check_box
            background transparent url(//s3.marpple.co/files/u_193535/2018/11/original/f_13772_1542340312547_FoQ1mCFOcEzGsim0h.png) no-repeat 50% 50%
            background-size 16px
        .title
          vertical-align middle
          display inline-block
    .m_image_pattern
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button
        -webkit-appearance none
        margin 0
      &.default_pattern:not(.ready)
        .controls.range_style
        .controls.type
          display none !important
      .controls
        &.number
          z-index 2
          position relative
        &.range_style
          z-index 1
        &.type
          z-index 1
      .controls
        > .head
          margin-bottom 8px
          font-size 14px
          color color_black
        > .body
          display flex
          height 40px
          justify-content space-between
          width 100%
        &:nth-last-child(1)
          margin-bottom 0
        &.number
          >.head
            display none
          .control.locking
            width 30px
            background url(//s3.marpple.co/file/guest/2018/1/original/f_1485_1515057400983_W3nG6bCHran9JilRFB.svg) no-repeat 50% 50%
            background-size 14px
            cursor pointer
            &[locked="false"]
              background-image url(//s3.marpple.co/file/guest/2018/1/original/f_1632_1516773453472_inw5hFI0qnM5f5tXi5X2H.svg)
          .control.number
            position relative
            width 43%
            border 1px solid #dee2e6
            box-sizing border-box
            height 100%
            input
              height 100%
              background transparent
              text-align center
              font-size 14px
              border none
              width 100%
              opacity 1
              margin 0
              padding 0
            .minus
            .plus
              position absolute
              width 40px
              height 38px
            .minus
              top 0
              left 0
              background url(//s3.marpple.co/files/u_193535/2018/10/original/f_12363_1540961884802_iyGL0ruH8od3s0efb7xq.png) no-repeat 50% 50%
              background-size 10px
            .plus
              top 0
              right 0
              background url(//s3.marpple.co/files/u_193535/2018/10/original/f_12364_1540961884802_t3nsH1aBxUTv3G9RI5bv.png) no-repeat 50% 50%
              background-size 10px
        &.range_style
          >.head
            margin-bottom -7px !important
          .control.number
            border 1px solid #dee2e6
            flex-basis 80px
            height 40px
          .value
            right 20px
            font-size 14px
            padding 0
            text-align center
            background-color #fff
            width 100%
            height 100%
            box-shadow none
            border none
          .control.range
            width 80%
            position relative
          .control.number
            margin-left 15px
        &.type
          .control.type.btn
            button
              width 31%
              padding 0
              font-size 14px
              outline none
              background-color #ffffff
              border solid 1px #d5dbe0
            button.selected
              color color_active
