
html.maker_page
  .mp_maker[is_shade="true"]
    .cv_text_editor
      .press_color_list
      .maker-color-picker
        display none
      .m_image_pattern.section
        display none
  .cv_text_hidden_text_area
    background transparent
    position absolute
    border none
    color #000
    width 100%
    box-sizing border-box
    padding-left 10%
    padding-right 10%
    padding-top 15px
    padding-bottom 15px
    height 200px
    outline none
    z-index 10
    font-size 18px
    opacity 0
    &:focus
      outline none
  .don_frame[frame_name="m_keyboard"]
    .don_page
      background #fff
      opacity 0
      position absolute

html.maker_page
  .cv_text_editor
    .control.opacity.section
      display none !important
    .style[has_bold="false"]
      button.bold
        opacity 0.3
    .style
      button
        background-position 49% 50%, 200% 200%
        background-repeat no-repeat
        position relative
        vertical-align top
        padding 0
        border 1px solid transparent
        &:active
          background-color #ced4da
          border-color transparent
        &:focus
          outline none
        &.bold
          background-size 9px
          background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6015_1539320766618_K5zh2hCsEo2itJ4F3h4W7q.png), url(//s3.marpple.co/files/u_18052/2018/10/original/f_6014_1539320766618_J4cWQzDtr5Zy4Kcwux.png)
        &.italic
          background-size 8px
          background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6024_1539320766618_V4TE0o9CJih4aZSD6i9KP.png), url(//s3.marpple.co/files/u_18052/2018/10/original/f_6023_1539320766618_H3OyZiCqpzFOPq7zr.png)
        &.underline
          background-size 8px
          background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6029_1539320766618_l8S7v7G0af6uQH8uI8ZxG7V.png), url(//s3.marpple.co/files/u_18052/2018/10/original/f_6028_1539320766618_Nwxrn7w7W7EdNa3mO9cy.png)
        &.strike
          background-size 9px
          background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6026_1539320766618_k5m7TBc9y3lHf8Mzmn5x9x.png), url(//s3.marpple.co/files/u_18052/2018/10/original/f_6025_1539320766618_s5u1VTs1Qaii0oOR2SUE.png)
        &.left
          background-size 16px
          background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6017_1539320766618_i2u1J4cg9EZT3CeU7syRL.png), url(//s3.marpple.co/files/u_18052/2018/10/original/f_6020_1539320766618_PbvLH2Snpkaqc1STu.png)
        &.center
          background-size 16px
          background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6016_1539320766618_VM2KBiuUkz6lz7TI1wM.png), url(//s3.marpple.co/files/u_18052/2018/10/original/f_6018_1539320766618_r1K2ZWnJy3E2IcQRBt7W.png)
        &.right
          background-size 16px
          background-image url(//s3.marpple.co/files/u_18052/2018/10/original/f_6022_1539320766618_rb4ZTQfR0JyDt2r4iXa.png), url(//s3.marpple.co/files/u_18052/2018/10/original/f_6019_1539320766618_k7b8bciryD5HS1fk1bV2u.png)
      &[font_weight="bold"] button.bold
      &[font_style="italic"] button.italic
      &[underline="true"] button.underline
      &[linethrough="true"] button.strike
      &[text_align="left"] button.left
      &[text_align="center"] button.center
      &[text_align="right"] button.right
        background-position 200% 200%, 49% 50%
    .control.char_spacing
      input[type="number"]
        background url(//s3.marpple.co/file/guest/2017/11/original/f_942_1511790749677_b4hIqa7TmwJq1TK4W1fF.svg) no-repeat 6px 50%
    .control.line_height
      input[type="number"]
        background url(//s3.marpple.co/file/guest/2017/11/original/f_943_1511790765901_UM3dI5GItJlJ2cg7M1t8c.svg) no-repeat 8px 50%
    .input_number_row
      display flex
      justify-content space-between
      > div
        width 80px
    #charSpacing_function
      input[type="number"]
        background url(//s3.marpple.co/file/guest/2017/11/original/f_942_1511790749677_b4hIqa7TmwJq1TK4W1fF.svg) no-repeat 6px 50%
    #lineHeight_function
      input[type="number"]
        background url(//s3.marpple.co/file/guest/2017/11/original/f_943_1511790765901_UM3dI5GItJlJ2cg7M1t8c.svg) no-repeat 8px 50%
    #angle_function
      input[type="number"]
        text-align right
        padding-right 35px
      .number_wrapper
        &:after
          top 15px
          right 29px
          content ""
          width 2px
          height 2px
          border 1px solid black
          border-radius 50%
          position absolute


@media screen and (max-width: 1024px)
  html.maker_page[lang="jp"]
    .cv_text_editor
      .range_sty1
        >.head
          width 64px
  html.maker_page
    .cv_text_editor
      .don_page[page_name="m_text_char_page2"]
        &.is_carved_phonecase_product
          .check_box
            margin-right 0 !important
          .select_font_and_style .radio_box
          .maker_range_wrapper
            display none
        .tab_buttons
          display flex !important
      .control
        &.select_font_and_style
          >.head
            display none
      #charSpacing_function
        margin-bottom 16px
      >.don_wrapper
        >.header
          height 40px
      .don_page
        height 280px
        background #fff
      .don_page >.don_wrapper
        >.header
          height 48px
      .m_text.wrapper
        color #212529
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button
        -webkit-appearance none
        margin 0
      .m_font_change
        background #fff
        position relative
        height 232px
        > .body
          position relative
          margin-top 50px
          height 240px
          div
            box-sizing border-box
      .style
        display flex
        height 32px
        margin-bottom 24px
        > div
          display flex
          background-color #f8f9fa
        > .radio_box
          flex-grow 1.5
        > .check_box
          flex-grow 2
          margin-right 14px
        button:nth-last-child(1)
          &:after
            border none !important
        button
          flex-grow 1
          background-color transparent
          margin-right -1px
          &:after
            content ""
            height 10px
            position absolute
            top 10px
            right 0
            border-left 1px solid #d5dbe0
      .select_font
        margin-bottom 16px
        .font
          cursor pointer
        .arrow
          background transparent url(//s3.marpple.co/file/u_15/2018/7/original/f_5274_1531137521497_VF6f4lRDkA4DTihI1tX.svg) no-repeat 50% 50%
          background-size 6px
          width 10px
          height 10px
          position absolute
          top 9px
          right 16px
        .select
          cursor pointer
          position relative
          //border-radius 2px
          border solid 1px #d5dbe0
          background transparent
          text-align left
          color #495057
          height 30px
          font-size 14px
          padding 0 16px
          background-size 6px
          &:active
            background-color #ced4da
          img
            position absolute
            opacity 0.7
            top 8px
            height 14px
    .cv_text_editor
      .swiper-container.m_text_font_swiper_container
        width 100%
        height 100%
        .swiper-pagination
          bottom 15px
        .swiper-pagination-bullet
          background #343a40
          width 6px
          height 6px
          margin 0 5px
        .swiper-slide
          padding 15px 20px 0
          display flex
          flex-wrap wrap
          box-sizing border-box
          align-content flex-start
          >.option
            cursor pointer
            width 33%
            height 40px
            margin 3px 0
            box-sizing border-box
            padding 0 13px
            display: flex;
            align-items: center;
            img
              max-width 100%
              max-height 20px
              width auto
              margin 0 auto
            img.on
              display none
            img.off
              display block
          >.option.selected
            img.on
              display block
            img.off
              display none
  .don_tab[tab_name="m_text_color_page"]
    height 232px
    padding 16px 4% 0
    box-sizing border-box


@media screen and (min-width: 1025px)
  #maker_frame
    #select_fonts_app
      position absolute
      z-index 2
      .iscroll_wrapper
        box-sizing border-box
        overflow hidden
      .fonts_wrapper
        height 386px
        position absolute
        width 100%
        overflow hidden
        border solid 1px #d5dbe0
        border-top none
        background #fff
        box-sizing border-box
      .fonts
        width 100%
        .font
          padding 13px 10px 13px 16px
          height 14px
          img
            height 14px
          &:hover
            background #d5dbe0
        .font.selected
          .off
            display none
          .on
            display block
        .font:not(.selected)
          .off
            display block
          .on
            display none
  #marpplizer
    .cv_text_editor
      .select_for_old
        margin-top 100px
        display flex
        justify-content center
        > *
          width 130px
          height 40px
          cursor pointer
          border solid 1px #d5dbe0
          margin-right 8px
          text-align center
          display flex
          justify-content center
          align-items center
          &:first-child
            margin-right 16px
          &:hover
            color color_active
            border-color color_active
      .only_df
        margin-top 60px
        display none
        html#dream_factory &
          display block
        > *
          height 30px
          margin-bottom 10px
        .font, .color
          height 30px
          img
            vertical-align middle
          span
            margin-right 8px
          .background_color
            border 1px solid #ccc
        textarea
          width 100%
          font-size 16px
          height 200px
      input[type="number"]::-webkit-outer-spin-button,
      input[type="number"]::-webkit-inner-spin-button
        -webkit-appearance none
        margin 0
      .don_page
        background #fff
      .select_font
        margin-bottom 16px
        .font
          cursor pointer
        .arrow
          background transparent url(//s3.marpple.co/file/u_15/2018/7/original/f_5274_1531137521497_VF6f4lRDkA4DTihI1tX.svg) no-repeat 50% 50%
          background-size 6px
          width 10px
          height 10px
          position absolute
          top 16px
          right 16px
        .select
          cursor pointer
          border solid 1px #d5dbe0
          background transparent
          text-align left
          color #495057
          height 40px
          font-size 14px
          padding 0 16px
          background-size 6px
          display flex
          align-items center
          img
            opacity 0.7
            height 14px
  .cv_text_editor
    margin-bottom 8px
    .range_sty1
      > .number
        margin-left 16px
      input[type="number"]
        height 40px !important
    .control
      z-index 1
    .select_font_and_style
      z-index 2
      .select_font
        z-index 2
      .style
        z-index 1
    .select_font
      position relative
      margin-bottom 8px !important
      .arrow
        transform rotateZ(90deg)
      &.clicked
        .arrow
          transform rotateZ(-90deg)
    .control
      .head
        text-align left
    .style
      display flex
      justify-content space-between
      >div
        border 1px solid #d5dbe0
        box-sizing border-box
        display inline-block
        height 40px
      button
        width 52px
        height 38px
        background-color #fff
        box-sizing border-box
        position relative
        &:after
          content " "
          width 1px
          height 10px
          background-color #d5dbe0
          position absolute
          right 0
          top 50%
          transform translateY(-50%)
        &:last-child:after
          display none
