order-table-header =
  display flex
  font-size 14px
  font-weight 600
  box-sizing border-box
  border-bottom 1px solid #d5dbe0
  >span
    display flex
    align-items center
    justify-content center
    height 48px

body.order
  .prj_list
    visibility hidden
    &.infi_container
      visibility visible
  .header_title
    margin 0
    font-size 24px
    font-weight 600
    line-height 1.2
    position relative
    display none
    .point
      font-size 16px
      position absolute
      bottom 0
      right 0
      .point2
        margin-left 6px
        color #0157ff
    @media screen and (max-width 1024px)
      display none
      font-size 16px
      margin 24px 0 16px
      .point
        font-size 14px
  button.write_a_review
    background-color #fff
    padding 8px 16px
    margin 8px 0 0 0
    border-radius: 8px;
    border: solid 1px #ddd;
    color: #4e4e4e;
    span
      font-size 14px !important
    @media screen and (max-width 1024px)
      margin-top 0
      span
        font-size 13px !important
    span
      display inline-block
      vertical-align top
    &:before
      content ''
      width 10px
      height 10px
      margin-right 8px
      display inline-block
      background-image url(//s3.marpple.co/files/u_218933/2021/3/original/f201133f7118b44f7e934a08068189aaf20212941.svg)
      background-size 10px 10px


  button.btn_review_end
    background-color #fff
    padding 8px 16px
    margin 8px 0 0 0
    border-radius 8px
    border solid 1px #ddd
    color #4e4e4e
    span
      display inline-block
      vertical-align top
    &:before
      content ''
      width 10px
      height 10px
      margin-right 8px
      display inline-block
      background-image url(//s3.marpple.co/files/u_218933/2021/3/original/5c28f0e207d0abb92ea5abc0e5db32997c1bb31d1.svg)
      background-size 10px 10px

  button.btn_down_img
    display inline-block
    background-color #fff
    padding 8px 16px
    margin 8px 8px 0px 0
    border-radius 8px
    border solid 1px #ddd
    color #4e4e4e
    span
      font-size 14px !important
    @media screen and (max-width 1024px)
      margin-top 0
      span
        font-size 13px !important
    span
      display inline-block
      vertical-align top
    &:before
      content ''
      width 10px
      height 10px
      margin-right 8px
      display inline-block
      background-image url(//s3.marpple.co/files/u_218933/2021/3/original/211ccfb76c08c229b41994641a0c942dd5dc74661.svg)
      background-size 10px 10px
  #body
    .don_tab.order_list_tab
      font-weight normal
      .store-body
        display initial
      >.don_wrapper .order_body
        padding 16px 16px 80px 16px
        @media screen and (max-width 1024px)
          padding 0 24px 16px
        .empty_message
          height 50vh
          {flex-center}
          flex-direction column
          .img
            background svg-empty-order no-repeat center
            background-size contain
            height 80px
            width 80px
            margin-bottom 24px
          .message
            font-size 16px
            text-align center
            color #111
        .prj_item
          position relative
          padding 20px 0 24px
          border-radius 2px
          margin-top 24px
          margin-bottom 24px
          border-bottom 1px solid #f8f8f8
          &:first-child
            margin-top 0px
        .item_header
          margin-bottom 16px
          font-size 14px
          color #828282
          span
            position absolute
            top 0
            left 0
            font-size 15px
            font-weight normal
            font-stretch normal
            font-style normal
            line-height 1.4
            letter-spacing -0.2px
            color #111111
          span.no
            background url(//s3.marpple.co/files/u_14345/2021/5/original/518369230bb79633131ecc9dfaf2105e64df128a1.svg) no-repeat 100% 40%
            padding-right 21px
            background-size 15px 15px
          span:not(.no)
            right 0
            left auto
            color #a2a2a2
        .item_body
          display flex
          @media screen and (max-width 1024px)
            align-items center
          .thumb
            width 118px
            height 118px
            margin-right 24px
            background #f8f8f8
            .canvas_100
              display block
              width 100%
              height 100%
              > *
                transform scale(118/150)
                transform-origin 0 0
          .up_c_info
            font-size 16px
            font-weight normal
            font-stretch normal
            font-style normal
            line-height 1.38
            letter-spacing -0.2px
            color #111111
            @media screen and (max-width 1024px)
              font-size 14px
            .title
              width 380px
            .title >*
              margin-bottom 6px
            .price
              color #111
              .discount_price
                margin-right 8px
                color #ea291f
              .origin_price.discounted
                text-decoration line-through
                color #828282
            .status
              font-size 16px
              font-weight 600
              line-height 1.38
              letter-spacing -0.2px
              @media screen and (max-width 1024px)
                font-size 14px
              >div
                margin-top 8px
                .write_a_review
                  font-weight 400
                  text-decoration underline
    @media screen and (min-width 1025px)
      .don_tab.order_list_tab
        >.don_wrapper .order_body
          padding 0 0 120px 0
          min-height 424px
          .empty_message
            border none
          .prj_list
            min-height 38vh
            .table_header
              {order-table-header}
              display none
              span:nth-child(1)
                width 440px
                margin-right 32px
              span:nth-child(2)
                width 224px
                margin-right 32px
              span:nth-child(3)
                width 160px
                margin-right 32px
            .up_c_info
              display flex
              align-items center
              position relative
              width 100%
              >*
                position absolute
              .price
                width 100px
                left 41%
                text-align center
              .status
                text-align center
                width 160px
                left 62%
              .view_btn
                {flex-center-center}
                margin-top -3px
                right 0
                width 120px
                height: 46px;
                border-radius: 8px;
                border: solid 1px #ddd;
                background: #fff;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                letter-spacing: -0.2px;
                text-align: center;
                color: #4e4e4e;
                line-height: 46px;

    @media screen and (max-width 1024px)
      .don_tab.order_list_tab
        >.don_wrapper .order_body
          .prj_list
            .up_c_info
              .price
                margin-top 6px
              .status
                margin-top 6px

    .don_tab.order_detail_tab
      font-weight normal
      >.don_wrapper .tab_body
        &:not([prj_status="ordering"]):not([prj_status="before_print"]) .status_summary
          .controls >.cancel_order
            display none !important
        &[prj_status="before_print"] .status_summary
          .progress_line
            width calc(100% * 0.1)
        &[prj_status="printing"] .status_summary
          .progress_line
            width calc(100% * 0.27)
        &[prj_status="before_deliver"] .status_summary
          .progress_line
            width calc(100% * 0.52)
        &[prj_status="delivering"] .status_summary
          .progress_line
            width calc(100% * 0.75)
        &[prj_status="before_confirm"] .status_summary
          .progress_line
            width 100%
        &[prj_status="confirmed"] .status_summary
          .progress_line
            width 100%
        .card
          background-color #ffffff
          >.title
            font-size 20px
            font-weight 600
            color #111
            margin-bottom 16px
        .status_summary
          .progress
            padding-bottom 10px
            .status_title
              font-weight 600
              color #111
              font-size 20px
            .progress_bar
              position relative
              height 6px
              width 100%
              border-radius 4px
              background-color #e9ecef
              margin 24px 0
              .progress_line
                position absolute
                left 0
                top 0
                bottom 0
                border-radius 4px
                background-color #0e2ee2
                height 6px
            .status_labels
              {flex-center-between}
              font-size 12px
              color #111
          .info_and_controls
            .info
              font-size 12px
              color #111
              .order_number
                {flex-center-between}
                .tracking
                  text-decoration underline
                  color #0e2ee2
            .controls
              display flex
              .btn
                font-size 13px
                letter-spacing 1px
                background-color #ffffff
                border solid 1px #dddddd
                border-radius 8px
                text-align center
                padding 8px
                margin-left 8px
                position relative
                color #4e4e4e
                &:first-child
                  margin-left 0
                span
                  position relative
                  z-index 2
        .ordered_items
          @media screen and (max-width 1024px)
            margin-bottom 30px
          .up_c_item
            position relative
            padding 0
            border-top none !important
            .item_body_wrap2
              position relative
              display none
              @media screen and (max-width 1024px)
                margin-bottom 16px
                display block
              .product_price
                display none
              &[is_wrote="true"]
                display block
              &[is_wrote="true"]
                .product_price
                  position absolute
                  bottom 0
                  right 0
              &[is_review="true"]
                display block
                .product_price
                  position absolute
                  bottom 8px
                  right 0
        .bottom
          .text
            font-size 16px
            line-height 1.71
            padding 16px 0 32px
            @media screen and (max-width 1024px)
              font-size 14px
          html[collabo_type="line"] &
            .special_requests
              display none
          html[lang="kr"] &
            .shipping_address
              position relative
              &:after
                position absolute
                top 4px
                right 0
                content '택배'
                font-size 16px
                @media screen and (max-width 1024px)
                  top 18px
                  right 24px
              &[type="quick"]:after
                content '퀵배송'
              &[type="combined"]:after
                content '묶음배송'
              &[type="visit"]:after
                content '방문수령'
          .title
            @media screen and (max-width 1024px)
              margin-bottom 0 !important
          .payment_summary
          .projection_payments
            @media screen and (min-width 1025px)
              margin-bottom 40px
            @media screen and (max-width 1024px)
              .title
                margin-bottom 20px !important
            html[lang="kr"] &
              .total_quantity:after
                content '개'
            .title
              margin-bottom 16px
            .content
              font-size 16px
              margin-bottom 8px
              {flex-center-between}
              @media screen and (max-width 1024px)
                font-size 14px
                margin-bottom 6px
              &.method
                border-top 1px solid #d5dbe0
                margin-top 16px
                padding-top 16px
            .req_receipt
              display inline-block
              position relative
              cursor pointer
              border 1px solid #d5dbe0
              padding 1px 4px
              margin-left 9px
              font-size 12px
              outline none
              &:active
                border-color #000
          .projection_payments
            padding-bottom 30px
            @media screen and (max-width 1024px)
              padding-bottom 60px
            .title + .payment
              padding-top 0
            .payment
              padding 0
              &:last-child
                border-bottom none
              &[paid_at="null"] .paid_amount
              &:not([paid_at="null"]) .amount
                display none

    @media screen and (min-width 1025px)
      .don_tab.order_detail_tab
        >.don_wrapper .tab_body
          margin auto
          padding 0px 0 120px 0
          background-color #ffffff
          &[prj_status="before_print"] .status_summary
            .progress_line
              width calc(100% * 0.05)
          .title_and_summary
            margin-bottom 56px
          .status_summary
            padding-bottom 0px
            .progress
              .status_title
                text-align left
                font-size 22px
                margin-bottom 24px
            .info_and_controls
              padding-top 16px
              {flex-center-between}
              .info
                {flex-center-between}
                font-size 14px
                .bar
                  width 1px
                  height 10px
                  margin 0 8px
                  background-color #d5dbe0
              .controls
                .btn
                  cursor pointer
                  width 168px
          .ordered_items
            margin-bottom 10px
            >.up_c_list
              margin-top 16px
              .item_body_wrap1
                align-items center
              .table_header
                display none
              .up_c_info
                position relative
                display flex
                align-items center
                flex 1
                .title
                  width 380px
                .review_info
                  div
                    margin-top 8px
              .title_and_faces
                {flex-left-center}
                flex-direction column
                height 100%
                display flex
                font-size 16px
                flex (380/978)*100
                /*width (380/978)*100%*/
                span
                  font-size 15px
                  color #4e4e4e
                .up_id
                  color #828282
                  padding-bottom 6px
                .title
                  line-height 1.5
                  .pc_name
                    html:not(.creator_store) &
                      display none
                  a
                    &:hover
                      text-decoration underline
                      &:after
                        background #eee
              .sizes
                display flex
                flex (288/978)*100
                /*width (288/978)*100%*/
                margin 0
                .up_c_s_list
                  margin auto
                  max-width 100px
                  display flex
                  justify-content center
                  flex-direction column
                  height 100%
                .quantity
                  font-size 14px
                  {flex-center-right}
                  >span.qty
                    display none
                  >span.val
                    width 32px
                    height 24px
                    {flex-center-center}
                    background-color #ffffff
              .product_price.pc_v
                {flex-center}
                justify-content flex-end
                height 100%
                flex (100/978)*100
                /*width (100/978)*100%*/
          .projection_down_print
            html:not([collabo_type=""]) &
              display none
            form
              display inline-block
            button
              margin 0 10px 0 0
              padding 0
              text-decoration underline
              border 0 none
              background-color transparent

          .container.bottom
            margin-top 60px
            width 100%
            .card
              width 45%
              display inline-block
              vertical-align top
              >.title
                padding-bottom 16px
                border-bottom 1px solid #f8f8f8
            .special_requests
            .payment_summary
              margin-right 10%
            .projection_payments
            .payment_summary
              margin-top 16px
        html[collabo_type="line"] &
          .container.bottom
            .card
              margin-right 0 !important
              &.special_requests
                display none
              &.shipping_address
                float right


    @media screen and (max-width 1024px)
      .don_tab.order_detail_tab
        >.don_wrapper .tab_body
          .projection_down_print
            display none
          .card
            padding 16px 24px 0
          .title_and_summary
            padding-top 0
            padding-bottom 26px
          .status_summary
            padding 0
            .info_and_controls
              padding-top 24px
              .info
                .status_change_date
                  margin-bottom 8px
              .controls
                .btn
                  margin-top 16px
                  width 100%
          .up_c_item
            .title_and_faces
              .pc_name
                display none
              .up_id
                color #828282
              .title
                .pc_name
                  html:not(.creator_store) &
                    display none
                a
                  color #111
                  line-height 1.8
                  span
                    margin-right 6px
                  &:after
                    display inline-block
                    position relative
                    cursor pointer
                    border 1px solid #d5dbe0
                    padding 0px 4px
                    line-height 1.5
                    font-size 12px
                    content '재주문'
                    html.creator &
                      display none
            .title_and_faces *
            .sizes *
              font-size 13px
          .up_c_info
            width 100%
          .payment_summary
            padding-bottom 30px
            >div:last-child
              padding-bottom 16px

.don_frame.additional_or_change_pay_method
  > .don_wrapper
    max-width 475px !important
  .don_page
    > .don_wrapper
      > .header
        display none
  .section_header
    display none
  .bank_info
    margin 0 50px
    @media screen and (max-width 1024px)
      margin 0 24px
  .payment_type_container
    margin 0 auto
    padding-bottom 50px !important
    input[type="text"]
    select
      box-sizing border-box
      padding 16px 24px 14px
      border-radius 4px
      border solid 1px #dddddd
      width 100%
      font-size 16px
      line-height 1.38
      letter-spacing -0.2px
      margin-bottom 8px
      html[lang="kr"] &
        margin-bottom 16px
    select
      -webkit-appearance none
      -moz-appearance none
      cursor pointer
      background url(//s3.marpple.co/files/u_29089/2023/9/original/1d1b7ee16c40a0cd35d1a4945286c7c6598189ad1.svg) no-repeat
      background-position right 24px top 18px
      &:active
        {checkout-order-active-border}
      &:valid
        color #111
      &:focus
        {checkout-order-active-border}
    .payment_types
      margin 24px 50px 0
      @media screen and (max-width 1024px)
        margin 72px 24px 0
      button
        font-weight normal
        font-size 14px
        margin-top 0 !important
        margin-bottom 12px !important
        width calc(50% - 6px) !important
        margin-right 12px !important
        box-sizing border-box
        &:nth-child(2n)
          margin-right 0 !important
    .payment_type
      margin-top 24px
      display none !important
  .options
    padding-top 24px
    text-align center
    @media screen and (max-width 1024px)
      margin 0 24px


html:not([lang="kr"])
  body.order #body .don_tab.order_detail_tab >.don_wrapper .tab_body .ordered_items >.up_c_list .title_and_faces .title a:after
    content 'reorder' !important



html.print_receipt
  #header
    display none
  .info
    margin 10px 0 30px
    table
      width 100%
      border-collapse collapse
      th
      td
        padding 5px 2px
        vertical-align middle
        border 1px solid #999
      .seal
        margin-left 10px
        width 40px
        vertical-align middle
      .total
        td
          &.total_price
            font-size 16px
            font-weight 600
            text-align right
  .print_receipt_area
    padding 0 40px
    box-sizing border-box
    width 1100px
    input[type="text"]
      border 0 none
      background transparent
    .btn_print_area
      padding 10px 0
      text-align center
      background-color #eee
      @media print
        display none
    .item_body_wrap1
      display flex
    .up_c_item
      position relative
      padding 24px 0
      border-top none !important
      .thumb
        display inline-block
      .item_body_wrap2
        position relative
        &[is_wrote="true"]
          .product_price
            position absolute
            bottom 0
            right 0
        &[is_review="true"]
          .product_price
            position absolute
            bottom 8px
            right 0
      &:not(:last-child)
        border-bottom solid 1px #d5dbe0
    .up_c_list
      margin-top 16px
      border-top 1px solid #000000
      border-bottom 1px solid #d5dbe0
      .item_body_wrap1
        align-items center
      .table_header
        {order-table-header}
        span:nth-child(1)
          width 480px
          margin-right 32px
        span:nth-child(2)
          width 224px
          margin-right 32px
        span:nth-child(3)
          width 224px
      .up_c_info
        position relative
        display flex
        align-items center
        .faces
          > *
            display inline-block
        .origin
          text-decoration line-through
          margin-right 8px
          font-weight 300
          text-align right
          color #828282
        .price
        .product_price
          color #111
          .discount_price
            margin-right 8px
            color #ea291f
          .origin_price.discounted
            text-decoration line-through
            color #828282
      .title_and_faces
        {flex-left-center}
        flex-direction column
        width 300px
        height 100%
        display flex
        .title
          line-height 1.5
          a
            span
              margin-right 6px
            &:hover
              text-decoration underline
              &:after
                background #eee
            &:after
              display inline-block
              position relative
              cursor pointer
              border 1px solid #d5dbe0
              padding 0px 4px
              font-size 12px
              content '재주문'
      .sizes
        display flex
        width 200px
        margin 0
        .up_c_s_list
          margin auto
          max-width 100px
          display flex
          justify-content center
          flex-direction column
          height 100%
          .up_c_s_item
            .size
            .quantity
              display inline-block
        .quantity
          font-size 14px
          {flex-center-right}
          >span.qty
            display none
          >span.val
            width 32px
            height 24px
            {flex-center-center}
            background-color #ffffff
      .product_price.pc_v
        {flex-center}
        position absolute
        left 545px
        top 0
        height 100%
        width 224px

// order detail > return
.don_frame.order-return-request
  [data-show=false]
    display none !important

  .nothing
    text-align center
    color gray
    margin-top 100px

  .btn
    margin 0 5px
    display flex
    align-items center
    justify-content center
    right 0
    width 84px
    height 32px
    font-size 12px
    font-weight 600
    background-color #fff
    border solid 1px #d5dbe0

  .don_page.returnable_order_list

    .pb_order_list_table
      width 100%
      padding 30px

      tbody tr
        height 15rem

      td > div
        height 15rem
        display flex
        align-items center
        justify-content center

      .product_info img
        max-height 100px
        max-width 100px
        margin-right 30px

      .order_quantity
        .deleted
          text-decoration line-through
          color gray
          margin-left 5px
          opacity 0.8

      .order_feature
        flex-direction column
        position relative
        .exchange-text
          margin-top 40px
          font-size 0.9rem
          color gray
          position absolute
        .return_btns
          display flex


  .don_page.return_request
    .return_request_body
      padding 20px
      margin 0 auto

      .buttons
        display flex
        justify-content center
        .btn_request
          background-color #02BC77
          color white
        .btn_cancel
          background-color rgba(24,28,33,0.1)

      .title
        font-size 20px
        font-weight 600
        margin 0 0 2em 0

      .container
        //padding 20px
        //border-radius 2px
        //border 1px solid #dcdcdc
        //margin-bottom 20px

        padding 20px
        border-radius 2px
        border-top 1px solid #dcdcdc
        margin 20px 0

      .product_info_container
        .content
          display flex
          align-items center
          height 13rem

          .img
            width 50%
            text-align center
            height 100%
            img
              height 100%

          .product_text
            display inline-block
            font-size 15px
            margin 0 auto

      .quantity_container
        > div
          display flex
          align-items center
          font-size 20px
          justify-content center

        .minus, .plus
          width 30px
          height 30px
          display flex
          align-items center
          justify-content center
          border 1px solid #d5dbe0
          z-index 1
          &:focus
            outline none
            border solid 1px #0e2ee2

        .quantity_controller
          display flex
          .quantity
            text-align center
            width 30px
            font-size 15px
            border solid #d5dbe0
            border-width 1px 0
            z-index 0

        .sep
          margin 0 20px

      .reason_container
        .reason
          width 80%
          margin 0 auto
          font-size: 14px;
          border solid 1px #d5dbe0
          padding 8px 16px
          box-sizing border-box
          display block
          background-color: #fff;
          outline none
          -webkit-appearance none
          -moz-appearance none
          border-radius 2px
          background: url(//s3.marpple.co/file/u_15/2018/7/original/f_5268_1530864115648_X2nQ3VKQX1CpON4T0af7e.svg) no-repeat
          background-position right 16px top 16px
        .description
          width 80%
          height 100px
          margin 10px auto
          resize none
          box-sizing border-box
          display block
          border 1px solid #d5dbe0
          font-size 14px
          padding 10px
          &:focus
            outline none
            border solid 1px #0e2ee2


      .instruction_container
        ul
          list-style none

.don_frame.order-return-list
  .nothing
    text-align center
    color gray
    margin-top 100px
  .pb_return_list_table
    padding 30px
    width 100%
    thead
      tr th
        height 3rem
        border-bottom 1px solid #d5dbe0
    tbody
      td
        text-align center
      tr
        height 7rem

    .product_info
      display flex
      align-items center
      justify-content center
      img
        max-height 100px
        max-width 100px
        margin-right 30px
