.don_frame[frame_name='rotate_thumbnail']
  @media screen and (min-width: 1025px)
    >.don_wrapper
      width 600px
      height 500px !important
    #main
      button
        border none
        outline none
        background-color #212529
        color #fff
        display flex
        justify-content center
        align-items center
        font-size 14px
        height 26px
        padding 0 16px
      >.header
        position relative
        display flex
        justify-content center
        align-items center
        height 56px
        border-bottom 1px solid #dfdfdf
        .title
          font-size 18px
        .done
          position absolute
          right 20px
          top 50%
          transform translateY(-50%)
      >.body
        margin-top 20px
        >.option
          position relative
          display flex
          justify-content center
          align-items center
          padding 10px 0
        .swiper-button-prev
          background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_12418_1541061387939_Ck2SBQTwcRZmv7Mde.png)
          background-size 14px
          width 14px
          height 30px
          transform rotate(180deg)
        .swiper-button-next
          background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_12418_1541061387939_Ck2SBQTwcRZmv7Mde.png)
          background-size 14px
          width 14px
          height 30px
        .swiper-pagination
          display none
        &[product_faces_length="1"]
          .swiper-button-prev
          .swiper-button-next
            display none
        .canvas_wrapper
          text-align center
          .text
            display none
