.store-product-list-body
  @media screen and (max-width: 1024px)
    padding 0 20px
    width 100%
    box-sizing border-box
  @media screen and (min-width: 1025px)
    max-width 1120px
    margin 0 auto
    width 96%
  .store-categories
    position relative
    width 100%
    margin 0 0 40px 0
    box-sizing border-box
    font-size 14px
    font-weight bold
    &[data-has-banner="false"]
      margin 0 auto 40px
    &-list
      position absolute
      top 40px
      left 20px
      right 20px
      z-index 100
      background-color #ffffff
      list-style none
      margin 0
      padding 0
      > li
        display flex
        height 40px
        line-height 40px
        border 1px solid #dcdcdc
        border-top none
      > [data-active="false"] > a
        opacity 0.3
      a
        flex-grow 1
        margin-left 10px
        color #000000 !important
        span
          position relative
          top -1px
          margin-left 2px
          font-size 12px
    &-list.mobile-hidden
      display none
    &-display
      width 100%
      height 40px
      display flex
      border 1px solid #dcdcdc
      border-radius 2px
      box-sizing border-box
      &-label
        width 0
        flex-grow 1
        border-right 1px solid #dcdcdc
        display flex
        align-items center
        margin-left 10px
      &-button
        width 40px
        display flex
        align-items center
        justify-content center

    @media screen and (min-width: 1025px)
      position static
      padding 0
      margin 10px 0 60px 0
      font-size 18px
      &[data-has-banner="false"]
        margin 10px auto 30px
      &-list
        position static
        display flex
        justify-content center
        width 100%
        > li:not(:last-child)
          margin-right 40px
        > li
          border none
        a
          margin 0
      &-list.mobile-hidden
        display flex
      &-display
        display none
  .store-products
    width 100%
    min-height 400px
    list-style none
    margin 0
    padding 0

