.don_page.cart
  .shipping_group
    border-radius  8px
    border solid 1px #f2f2f2
    .shipping_group-name
      border-top-left-radius 8px
      border-top-right-radius 8px
      border solid 1px #f8f8f8
      background #f8f8f8
    .shipping_group-list
      > div
        border-bottom 1px solid #f2f2f2
    .shipping_group-price
      display flex
      justify-content space-between
      color #111
body.is_mobile
  .shipping_group
    margin-bottom 24px
    &:last-child
      margin-bottom 0
    .shipping_group-name
      padding 14px
    .shipping_group-list
      > div
        padding 14px 18px 18px
    .shipping_group-price
      padding 16px 0
      font-size 13px
      margin 0 16px
body:not(.is_mobile)
  .shipping_group
    margin-bottom 24px
    &:last-child
      margin-bottom 0
    .shipping_group-name
      padding 14px 18px
    .shipping_group-list
      > div
        padding 14px 18px 18px
    .shipping_group-price
      padding 18px
      font-size 14px
body.is_mobile
  #body
    .don_page.cart
      .don_tab >.don_wrapper
        .cart_body
          font-weight normal
          padding 0 0 56px
          .store_section
            margin-bottom 24px
            padding 18px 24px 0
            border-top 8px solid #f8f8f8
          .store_name
            display inline-flex
            margin-bottom 14px
            font-size 20px
            font-weight 600
            font-stretch normal
            font-style normal
            line-height 1.3
            letter-spacing -0.3px
            color #111111
            &::before {
              display block
              margin-right 9px
              width 18px
              background url(//s3.marpple.co/files/u_14345/2021/5/original/1535e404c1975484d2bc825c98e34b4b4c55187a1.svg) no-repeat 0 2px
              background-size 100%
              content ''
            }
          .up_c_list
            .up_c_item
              border-radius 2px
              &[data-is_digital_product="true"]
                .btn.edit
                  display none !important
          &:not(.empty_page)
            .empty_wrap
              display none
          &.empty_page
            height 60vh
            padding-bottom 80px
            display flex
            flex-direction column
            .up_list, .submits, .submit, .summary, .print_sheet
              display none
            .empty_wrap
              {flex-center-center}
              flex-direction column
              font-size 18px
              font-weight 600
              line-height 1.14
              color #4e4e4e
              img
                width 62px
                height 62px
                margin-bottom 12px
          .card_footer
            cursor pointer
            border-top 1px solid #d5dbe0
            padding 16px 0
            font-size 12px
            font-weight bold
            text-align center
            color color_active
            {flex-center-center}
            html.creator &
              display none
          .item_body
            .controls
              padding-top 10px
            .controls >.btn
              &.save_heart
                display none
              &.edit
                width 100%
                padding 8px 106px
                border-radius 16px
                border solid 1px #dddddd
                background #fff
                font-size 12px
                font-weight normal
                font-stretch normal
                font-style normal
                line-height 1.33
                letter-spacing -0.2px
                text-align center
                color #4e4e4e
              &.delete
                position absolute
                top -24px
                right 0
                overflow hidden
                text-indent -9999px
                background transparent url(//s3.marpple.co/files/u_14345/2021/5/original/b02d072813fd9adbd9268adcef8eedf8a8e4dee41.svg) no-repeat 50% 50%
                border 0 none
                width 13px
                height 13px
              &:active, &:focus
                outline none
            .up_c_s_item[is_not_stock="true"]
              &:after
                content " "
                position absolute
                text-align center
                top 50%
                width 300px
                transform translateY(-50%)
                left -50px
                right 0
                height 1px
                background-color color_gray3
              .quantity
                span
                  position relative
                  &:after
                    content "Sold Out"
                    html[lang="kr"] &
                      content "일시품절"
                    color color_gray3 !important
                    font-size 10px
                    margin-left 10px
                    width 80px
                    position absolute
                    left 100%
                    top 50%
                    transform translateY(-50%)
        .cart_footer
          .header
            display none
        .summary
          background-color #f8f8f8
          padding 28px 24px 24px
          html[lang="kr"] &
            .total_quantity:after
              content '개'
          span.discount_price
            color #ff6b6b
          .content
            font-size 15px
            font-weight normal
            font-stretch normal
            font-style normal
            line-height 1.27
            letter-spacing -0.2px
            color #4e4e4e
            {flex-center-between}
            margin-bottom 4px
            span:last-child
              color #111111
            &.final
              padding-top 14px
              margin-top 10px
              font-weight bold
              font-size 20px
              color #111111
              margin-bottom 0
              padding-bottom env(safe-area-inset-bottom)
              padding-bottom constant(safe-area-inset-bottom)
              html[not-safe-area="true"] &
                padding-bottom 0
              html.safe_area_inset_bottom &
                padding-bottom 34px

      .submits
        #checkout
          cursor pointer
          height 56px
          line-height 56px
          background-color #111111
          color #ffffff
          font-size 16px
          letter-spacing -0.2px
          text-align center
          padding-bottom env(safe-area-inset-bottom)
          padding-bottom constant(safe-area-inset-bottom)
          html[not-safe-area="true"] &
            padding-bottom 0
          html.safe_area_inset_bottom &
            padding-bottom 34px
          html[is_mobile="true"] &
            position fixed
            left 0
            bottom 0
            right 0
      html[is_mobile="true"] &
        .cart_body[data-length="1"]
          .summary
            position fixed
            bottom 56px
            left 0
            right 0

    .don_page.cart
      .store-body
        display initial
      .don_tab >.don_wrapper
        .cart_body
          &.empty_page
            .empty_wrap
              height 100%
          .header_title
            font-size 20px
            font-weight bold
            padding 40px 0 20px
            .count
              color color_gray3
              margin-left 4px
          .item_body
            >.item_body_wrap1
              >.up_c_info
                .sizes
                  .size
                    margin-right 6px
                  html[lang="jp"] & .quantity:before
                    content '数量: '
                    color color_gray3
                  html[lang="en"] & .quantity:before
                    content 'Qty: '
                    color color_gray3
                  html[lang="kr"] & .quantity:after
                    content '개'
            >.item_body_wrap2
              >.product_price
                {flex-center-right}

body:not(.is_mobile)
  #body
    .don_page.cart
      .don_tab >.don_wrapper
        .cart_body
          position relative
          max-width 708px
          font-weight normal
          padding 0 41% 56px 0
          .store_section
            padding 38px 0 18px
            &:first-child
              border 0 none
              padding-top 0
          .store_name
            display inline-flex
            margin-bottom 24px
            font-size 26px
            font-weight 600
            font-stretch normal
            font-style normal
            line-height 1
            letter-spacing -0.3px
            color #111111
            &::before {
              display block
              margin-right 10px
              width 22px
              background url(//s3.marpple.co/files/u_14345/2021/5/original/1535e404c1975484d2bc825c98e34b4b4c55187a1.svg) no-repeat 0 0
              background-size 100%
              content ''
            }
          .up_c_list
            .up_c_item
              padding 0 0 48px
              border-radius 2px
              &[data-is_digital_product="true"]
                .btn.edit
                  display none !important
              .up_c_info
                .sizes
                  .up_c_s_list
                    margin-right 142+10px
                    padding 14px 28px 12px
                    border-radius 8px
                    background-color #f8f8f8
                    font-size 14px
                    font-weight normal
                    font-style normal
                    line-height 1.43
                    letter-spacing -0.2px
                    text-align right
                    color #4e4e4e
          &:not(.empty_page)
            .empty_wrap
              display none
          &.empty_page
            height 60vh
            padding-bottom 80px
            display flex
            flex-direction column
            max-width none
            padding-right 0
            .up_list, .submits, .submit, .summary, .print_sheet, .cart_footer
              display none
            .empty_wrap
              {flex-center-center}
              flex-direction column
              font-size 24px
              font-weight 600
              line-height 1.14
              color #4e4e4e
              img
                width 80px
                height 80px
                margin-bottom 16px
          .card_footer
            cursor pointer
            border-top 1px solid #d5dbe0
            padding 16px 0
            font-size 12px
            font-weight 600
            text-align center
            color color_active
            {flex-center-center}
            html.creator &
              display none
          .item_body
            .controls >.btn
              &.save_heart
                display none
              &.edit
                position absolute
                bottom 0
                right 0
                width 140px
                padding 0
                height 46px
                border-radius 8px
                border solid 1px #dddddd
                background #fff
                font-size 14px
                font-weight normal
                font-style normal
                letter-spacing -0.2px
                text-align center
                color #4e4e4e
                line-height 46px
              &.delete
                position absolute
                top -24px
                right 0
                overflow hidden
                text-indent -9999px
                background transparent url(//s3.marpple.co/files/u_14345/2021/5/original/b02d072813fd9adbd9268adcef8eedf8a8e4dee41.svg) no-repeat 50% 50%
                background-size 16px 16px
                border 0 none
                width 16px
                height 16px
              &:active, &:focus
                outline none
            .up_c_s_item[is_not_stock="true"]
              &:after
                content " "
                position absolute
                text-align center
                top 50%
                width 300px
                transform translateY(-50%)
                left -50px
                right 0
                height 1px
                background-color color_gray3
              .quantity
                span
                  position relative
                  &:after
                    content "Sold Out"
                    html[lang="kr"] &
                      content "일시품절"
                    color color_gray3 !important
                    font-size 10px
                    margin-left 10px
                    width 80px
                    position absolute
                    left 100%
                    top 50%
                    transform translateY(-50%)
        .cart_footer
          position absolute !important
          top 0
          right 0
          width 35.66%

          .header
            margin-bottom 18px
            font-size 26px
            font-weight 600
            font-stretch normal
            font-style normal
            line-height 1.31
            letter-spacing -0.3px
            color #111111

        .summary
          background-color #f8f8f8
          padding 30px 28px 30px
          border-radius 8px
          html[lang="kr"] &
            .total_quantity:after
              content '개'
          span.discount_price
            color #ff6b6b
          .content
            font-size 18px
            font-weight normal
            font-stretch normal
            font-style normal
            line-height 1.33
            letter-spacing -0.2px
            color #4e4e4e
            {flex-center-between}
            margin-bottom 8px
            span:last-child
              color #111111
            &.final
              padding-top 14px
              margin-top 10px
              font-weight 600
              font-size 20px
              color #111111
              margin-bottom 0

      .submits
        #checkout
          cursor pointer
          margin-top 16px
          height 54px
          line-height 54px
          border-radius 8px
          background-color #111111
          font-size 18px
          font-weight 600
          font-style normal
          letter-spacing -0.2px
          text-align center
          color #ffffff

    .don_page.cart
      .store-body
        display initial
      .don_tab >.don_wrapper
        .cart_body
          min-height 600px
          &.empty_page
            .empty_wrap
              height 100%
          .header_title
            font-size 20px
            font-weight 600
            padding 40px 0 20px
            .count
              color color_gray3
              margin-left 4px
          .item_body
            >.item_body_wrap1
              >.up_c_info
                .sizes
                  .size
                    margin-right 6px
                  html[lang="jp"] & .quantity:before
                    content '数量: '
                    color color_gray3
                  html[lang="en"] & .quantity:before
                    content 'Qty: '
                    color color_gray3
                  html[lang="kr"] & .quantity:after
                    content '개'
            >.item_body_wrap2
              >.product_price
                {flex-center-right}

.don_frame.cart_modal
  box-sizing content-box

html.safe_area_inset_bottom
  body.is_mobile
    .don_frame.cart_modal[is_height="true"]
      padding-bottom 34px
