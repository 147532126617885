&
  display flow-root
  margin 0 auto
  width 100%
  padding 0 24px
  @media screen and (max-width 1024px)
    padding 0 20px 62px
    background #fff
    display block
  > h1
    position relative
    display block
    margin 0
    border-bottom 1px solid #eee
    span
      display block
      top 1px
      padding 12px 16px
      font-size 20px
      line-height 24px
      background transparent
      border 0
      position relative
      margin 0 auto
      text-align center
    i
      position absolute
      left 92%
      width 100px
      text-align left
      font-size 17px
      color #666
      font-weight normal
      font-style normal
    @media screen and (max-width 1024px)
      padding 40px 0
      border-bottom none
      span
        padding 0
        text-align left
        line-height normal


  .product-story-wrap
    margin 48px auto
    max-width 1120px
    @media screen and (max-width 1024px)
      padding 0
      margin 0
      input[type="radio"]
      input[type="checkbox"]
        -webkit-appearance auto
        transform translateY(-1px)
    .lang-selector
      margin-bottom 20px
      @media screen and (min-width 1025px)
        .title
          display none
    .product-story-radio-buttons
      text-align center
      margin-bottom 30px
      label
        display inline-flex
        align-items center
        margin 0 8px
        input
          position relative
          top -1px
          margin-right 4px
      @media screen and (max-width 1024px)
        text-align left
        margin-bottom 0
        label
          margin 0 20px 0 0
          font-size 14px
          line-height 1.57
          input
            top 0
            margin 0 10px 0 0
    .product-story-lang
      display none
      @media screen and (max-width 1024px)
        margin-bottom 40px
        [name^="title"]
          min-height 62px
        [name^="description"]
          min-height 128px

      &.selected
        display block
    .sns-section.form-item
      display none
      @media screen and (max-width 1024px)
        display block
        margin-bottom 0
    .story-section
      @media screen and (max-width 1024px)
        width auto
      > h4
        font-size 18px
        margin 0 0 10px
        @media screen and (max-width 1024px)
          display none
        img
          height 20px
        > span
          color #000
          opacity 0.3
          font-size 12px
          font-weight bold
          line-height 1.83
          letter-spacing -0.5px
          float right
          margin 0
      label
        display block
        cursor text
      textarea
      input
        border: 1px solid rgba(24,28,33,0.1);
        border-radius .25rem
        transition border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out
        outline none
        overflow hidden
        &:focus
          border-color: #3c97fe;
      textarea
        width 100%
        box-shadow none
        resize none
        margin 0
        height 0
        border-color transparent
        @media screen and (max-width 1024px)
          border solid 1px #ededed
          padding 9px 13px
          border-radius 2px
      input
        width 100%
        padding 8px
        font-size 14px
        height 40px
        border-radius 2px
        border solid 1px #ededed
        background-color #ffffff
        font-weight 500
        line-height 1.57
        letter-spacing -0.5px
        color #000
        opacity 0.3
      @media screen and (max-width 1024px)
        [name$="embed"]
          padding-left 36px
          background-size 18px 18px
          background-position 10px center
          background-repeat no-repeat
          opacity 1
          &::placeholder
            color #b2b2b2
        [name^="youtube"]
          background-image url(//s3.marpple.co/files/u_1504988/2021/5/original/4163bee15f575e66f35ca71135a5b302bc2eeccb7.png)
        [name^="ins"]
          background-image url(//s3.marpple.co/files/u_1504988/2021/5/original/6deafa526bd67814ffb08c669f39f20135e1d5a35.png)
        [name^="tw"]
          background-image url(//s3.marpple.co/files/u_1504988/2021/5/original/95a9e676437add07677e4243e4221f7a07b6dfba6.png)
        [name^="fb"]
          background-image url(//s3.marpple.co/files/u_1504988/2021/5/original/b1057dbbd53144d37fc334a790f1d6868275f9b84.png)
      .sns-wrap
        margin 8px 0 56px
        overflow-x hidden
        @media screen and (max-width 1024px)
          margin 10px 0 0
          > *
            margin-bottom 30px !important
          .ins-embed
            display none
      &.title-section
        width auto
        margin-bottom 30px
        @media screen and (max-width 1024px)
          margin-bottom 10px
        textarea
          text-align center
          font-size 40px
          font-weight 600
          line-height 1.24
          @media screen and (max-width 1024px)
            text-align left
            font-weight 500
            font-size 14px
            line-height 1.57
          &::placeholder
            font-size 37px
            font-weight 600
            color #b2b2b2
            @media screen and (max-width 1024px)
              font-weight 500
              font-size 14px
      &.description-section
        width auto
        margin-bottom 60px
        @media screen and (max-width 1024px)
          margin-bottom 0
        textarea
          text-align center
          font-size 16px
          line-height 1.41
          @media screen and (max-width 1024px)
            text-align left
            font-size 14px
            font-weight 500
            line-height 1.57
          &::placeholder
            font-size 16px
            font-weight 600
            color #b2b2b2
            @media screen and (max-width 1024px)
              font-weight 500
              font-size 14px
      textarea[name="title"]
        min-height 49px
        @media screen and (max-width 1024px)
          min-height 62px
      textarea[name="description"]
        min-height 27px
        @media screen and (max-width 1024px)
          min-height 130px
      &#image-section
        @media screen and (max-width 1024px)
          margin-top 40px
          width auto
        >h4
          width 500px
          margin 0 auto 10px
          @media screen and (max-width 1024px)
            width auto
            margin 0 0 12px

        .story-file-upload-pc
          width 500px
          margin 0 auto 54px
          position relative
          max-width 100%
          @media screen and (max-width 1024px)
            display none
            & *
              display none
          input
            cursor pointer
            width 500px
            max-width 100%
          label
            opacity 0
            position absolute
            top 0
            left 0
            right 0
            bottom 0
          .file_wrap
            width 100%
            height 40px
            border-radius 2px
            border solid 1px #dcdcdc
            background-color #ffffff
            box-sizing border-box
            display flex
            justify-content space-between
            align-items center
            .file-description
              margin 0 20px
              height 22px
              color #000
              opacity 0.3
              font-size 14px
              font-weight 500
              line-height 1.57
              letter-spacing -0.5px
            .file-button
              margin-right 7px
              padding 0 20px
              height 30px
              border-radius 2px
              border solid 1px #dcdcdc
              background-color #f5f5f5
              font-size 12px
              font-weight bold
              line-height 30px
              letter-spacing -0.5px
        .story-file-upload-mobile
          @media screen and (min-width 1025px)
            display none
            & *
              display none
          margin 0 auto 54px
          position relative
          display flex
          gap 6px
          max-width 100%
          img
            height 20px
            margin-left 10px
          input
            cursor pointer
            max-width 100%
            flex 1
          label
            opacity 0
            position absolute
            top 0
            left 0
            right 0
            bottom 0
          .file-button
            margin-right 7px
            padding 0 20px
            height 40px
            min-width 100px
            border-radius 2px
            border solid 1px #ededed
            background-color #ffffff
            font-size 12px
            font-weight bold
            line-height 30px
            letter-spacing -0.5px
          .file_wrap
            width 100%
            height 40px
            border-radius 2px
            border solid 1px #dcdcdc
            background-color #ffffff
            box-sizing border-box
            display flex
            align-items center
            .file-description
              margin 0 8px
              height 22px
              color #000
              opacity 0.3
              font-size 14px
              font-weight 500
              line-height 1.57
              letter-spacing -0.5px

        .product-story-image
          @media screen and (max-width 1024px)
            margin-bottom 10px
          .product-story-image-option
            position absolute
            top 0
            left 0
            bottom 0
            right 0
            width 100%
            background-color rgba(0,0,0, 0.5)
            display none
            .button-wrap
              position absolute
              top 0
              left 0
              bottom 0
              right 0
              width 100%
              height 36px
              margin auto
            @media screen and (max-width 1024px)
              .button-wrap
                height 72px
              .btn img
                display block
                margin 0 auto 10px
                width 24px
              .btn.btn-yellow
              .btn.btn-remove
                width 72px
                color #fff
                border none
                background transparent
          &:hover
            .product-story-image-option
              display block
