@media screen and (min-width: 1025px)
  body.maker_page
    .btm_menus
      display none
@media screen and (max-width: 1024px)
  body.maker_page
    .btm_menus
      >.btm_menu
        position fixed
        bottom -100px
        z-index 1
        left 0
        right 0
        padding-bottom 16px
        overflow hidden
        transition bottom 0.4s
        margin 0 auto
        opacity 1
        display flex
        justify-content center
        height 80px
        text-align center
        &.text_editor
        &.text_pattern_editor
        &.image_editor
          .circle_icon
            display inline-block
            position relative
        &.text_editor
        &.text_pattern_editor
          .circle_icon
            margin 0 6px
        &.text_editor
        &.text_pattern_editor
          .text
            width 85px
  body.maker_page
    //.mp_maker:not(.from_library)
    .mp_maker
      .btm_menu.image_editor
        .crop.circle_icon, .adjustment.circle_icon, .filter.circle_icon
          display none !important
        .circle_icon.hidden
          display none !important
    .mp_maker
      &.cv_object_selected:not(.delay_transition)
        &.cv_image:not(.from_library):not(.not_show_image_color) .btm_menu.image_editor:not(._from_library):not(._only_one)
        &.cv_image.from_library .btm_menu.image_editor._from_library
        &.cv_image.not_show_image_color .btm_menu.image_editor._only_one
        &.cv_pattern:not(.from_library):not(.not_show_image_color) .btm_menu.image_editor:not(._from_library):not(._only_one)
        &.cv_pattern.from_library .btm_menu.image_editor._from_library
        &.cv_pattern.not_show_image_color .btm_menu.image_editor._only_one
        &.cv_text .btm_menu.text_editor
        &.cv_pure_text .btm_menu.text_editor
        &.cv_text_image .btm_menu.text_editor
        &.cv_text_image_pattern .btm_menu.text_pattern_editor
        &.cv_old_text .btm_menu.text_editor
          bottom 0
          html.safe_area_inset_bottom &
            bottom 10px
      &.not_show_image_color
        .btm_menu.image_editor
          .color.circle_icon
            display none
      &.has_embro
        .btm_menus
          display none
      &.pinch_mode
        .btm_menus
          display none
    html.btm_menus_hide &
      .btm_menu.text_editor
        display none
