.don_frame.simple_modal_frame_style
  .don_page
    height 100%
    >.don_wrapper
      height 100%
      >.body
        height 100%
        >.don_tab
          height 100%
          >.don_wrapper
            height 100%
.don_page[page_name="/modules/SimpleModal/S/Mui/page.js"]
  height 100%
  >.don_wrapper
    height 100%
    >.body
      height 100%
      >.don_tab
        height 100%
        >.don_wrapper
          height 100%
.simple_modal
  height 100%
  .simple_modal_wrapper
    height 100%
    position relative
    .header
      height 100px
      position absolute
      top 0
      left 0
      right 0
      font-size 22px
      display flex
      align-items center
      justify-content center
    .body
      top 100px
      bottom 100px
      left 0
      right 0
      position absolute
    .footer
      height 100px
      position absolute
      bottom 0
      left 0
      right 0
      display flex
      align-items center
      padding 0 40px
      button
        flex 1
        height 40px
        font-size 16px
        display flex
        align-items center
        justify-content center
        background none
        border 1px solid #d5dbe0
        margin 0 8px
    .bg_removal_body
      box-sizing border-box
      text-align center
      height 100%
      padding-top 30px
      img
        max-width 100%
        max-height 90%
        object-fit contain
        width auto
      .content
        height 10%
        display flex
        justify-content center
        align-items center
        font-size 16px
