
html.creator
  .request_bg_removal
    display none !important
body.setting-products
  .mp_maker[data-is_user_studio="true"]
    .maker_menu
      .group, .ungroup
        opacity 0.2 !important
  #marpplizer
    .request_bg_removal.section
      display none
  #maker_frame
    &.cv_blue_line
      .circle_icon.open_blue_line
        .icon
          border 1px solid blue
    &.cv_print_item
      .circle_icon.cv_print_item
        .icon
          border 1px solid blue
  #maker_frame
    &.group .maker_menu .group
    &.cv_group .maker_menu .ungroup
    &.has_lock_obj .maker_menu .unlock
      opacity 1
    &.cv_object_selected
      .maker_menu
        .lock
        .trash
        .forward
        .backward
        .flip_h
        .flip_v
        .group_left
        .group_center_h
        .group_right
        .group_top
        .group_center_v
        .group_bottom
          opacity 1
    &.has_embro
    &[data-maker_type="MASKING_TAPE"]
      .maker_menu
        .flip_h
        .flip_v
          opacity 0.2
  @media screen and (min-width: 1025px)
    #maker_frame
      @media screen and (min-height: 841px)
        .circle_icon
          .icon
            width 64px
            height 64px
            box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.1)
          &.custom_user_btn .icon
            background-image url(//s3.marpple.co/files/u_1187078/2024/10/original/c9f1c98e09b11feb0870c9e06d9dc046447aea921.svg)
            background-size 30px
            background-position 50% 50%
          &.custom_asset_btn .icon
            background-image url(//s3.marpple.co/files/u_1187078/2024/10/original/c9f1c98e09b11feb0870c9e06d9dc046447aea921.svg)
            background-size 30px
            background-position 50% 50%
          &.add_img .icon
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14213_1542857353845_CgLRkP5nbTcq7T7v5kb.svg)
            background-size 30px
            background-position 50% 50%
          &.add_sticker2 .icon
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14211_1542857353845_iDdLywJ5awDPHM6U3J.svg)
            background-size 30px
            background-position 50% 50%
          &.add_text .icon
          &.add_cv_pure_text .icon
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14212_1542857353845_fJclBoTlb7hU0yy0mh.svg)
            background-size 30px
            background-position 50% 50%
          &.my_lib .icon
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14210_1542857353845_hU9DCCf1D0T3DJAXk9wn.svg)
            background-size 30px
            background-position 50% 50%
          &.add_sticker_line .icon
          &.add_sticker_custom .icon
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14211_1542857353845_iDdLywJ5awDPHM6U3J.svg)
            background-size 30px
            background-position 50% 50%
          &.load_all_pfs .icon
            background-color lawngreen
            background-image url(//s3.marpple.co/files/u_1187078/2023/7/original/f27679a2df4ab0b9cab011a70c5d88d932f4d0851.png)
            background-size 30px
            background-position 50% 50%
          &.open_chooser .icon
            background-image url(//s3.marpple.co/files/u_193535/2019/9/original/f_792489_1567668413302_Id3CoOg7aJ8rzfSdCP.png)
            background-size 30px
            background-position 50% 50%
          &.open_cv_background_marpplizer .icon
            background-image url(//s3.marpple.co/files/u_193535/2022/1/original/e3c9a52e62c3f71c61d40bda01f2a3bec34618ec1.svg)
            background-size 30px
            background-position 50% 50%
          &.share_j .icon
            background-image url(//s3.marpple.co/files/u_193535/2019/9/original/f_884045_1569314894216_Z8Cyyekvs7Tbv5zr6C5u.png)
            background-size 28px
            background-position 50% 50%
        .circle_icon:active, .circle_icon:hover, .circle_icon.drop
          .icon
            background-color color_active
            box-shadow 0 2px 10px 0 rgba(1, 87, 255, 0.3)
          .text
            color color_active
          &.add_text
          &.add_cv_pure_text
            .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14460_1542950695902_ATwL8kOX0TCe6LHLIH.svg)
              background-size 30px
              background-position 50% 50%
          &.add_sticker2
            .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14462_1542950763512_a8OHvwW6rTJliD2xk1S.svg)
              background-size 30px
              background-position 50% 50%
          &.my_lib
            .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14463_1542950763512_l9wGQcG3hOJbkN0B3s6z.svg)
              background-size 30px
              background-position 50% 50%
          &.custom_user_btn .icon
            background-image url(//s3.marpple.co/files/u_1187078/2024/10/original/c9f1c98e09b11feb0870c9e06d9dc046447aea921.svg)
            background-size 30px
            background-position 50% 50%
          &.custom_asset_btn .icon
            background-image url(//s3.marpple.co/files/u_1187078/2024/10/original/c9f1c98e09b11feb0870c9e06d9dc046447aea921.svg)
            background-size 30px
            background-position 50% 50%
          &.add_img
            .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14461_1542950695902_zfNG9m5Jf8W9RdfLe7Ol.svg)
              background-size 30px
              background-position 50% 50%
          &.add_sticker_line .icon
          &.add_sticker_custom .icon
            background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14462_1542950763512_a8OHvwW6rTJliD2xk1S.svg)
            background-size 30px
            background-position 50% 50%
          &.open_chooser .icon
            background-image url(//s3.marpple.co/files/u_193535/2019/9/original/f_792491_1567668413302_OHsEM0L8qF2L6WkUc7lz.png)
            background-size 30px
            background-position 50% 50%
          &.open_cv_background_marpplizer .icon
            background-image url(//s3.marpple.co/files/u_193535/2022/1/original/862c950efd6afd90150266f79d1214fc0c0f7cbf2.svg)
            background-size 30px
            background-position 50% 50%
          &.share_j .icon
            background-image url(//s3.marpple.co/files/u_193535/2019/9/original/f_884046_1569314894216_s9r1gomHFAsT4svC7S8B.png)
            background-size 28px
            background-position 50% 50%
      @media screen and (max-height: 840px)
        .mp_maker
          .circle_icon
            .icon
              width 45px
              height 45px
              box-shadow 0 2px 10px 0 rgba(0, 0, 0, 0.1)
            &.custom_user_btn .icon
              background-image url(//s3.marpple.co/files/u_1187078/2024/10/original/c9f1c98e09b11feb0870c9e06d9dc046447aea921.svg)
              background-size 30px
              background-position 50% 50%
            &.custom_asset_btn .icon
              background-image url(//s3.marpple.co/files/u_1187078/2024/10/original/c9f1c98e09b11feb0870c9e06d9dc046447aea921.svg)
              background-size 30px
              background-position 50% 50%
            &.add_img .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14213_1542857353845_CgLRkP5nbTcq7T7v5kb.svg)
              background-size 22px
              background-position 50% 50%
            &.my_lib .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14210_1542857353845_hU9DCCf1D0T3DJAXk9wn.svg)
              background-size 22px
              background-position 50% 50%
            &.add_sticker2 .icon
            &.worker_folder .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14211_1542857353845_iDdLywJ5awDPHM6U3J.svg)
              background-size 22px
              background-position 50% 50%
            &.add_text .icon
            &.add_cv_pure_text .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14212_1542857353845_fJclBoTlb7hU0yy0mh.svg)
              background-size 22px
              background-position 50% 50%
            &.add_sticker_line .icon
            &.add_sticker_custom .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14211_1542857353845_iDdLywJ5awDPHM6U3J.svg)
              background-size 22px
              background-position 50% 50%
            &.open_chooser .icon
              background-image url(//s3.marpple.co/file/u_15/2018/7/original/f_5278_1531140102200_P8VO0c7T8s7bR7e9QNa6pWm.svg)
              background-size 22px
              background-position 50% 50%
            &.open_cv_background_marpplizer .icon
              background-image url(//s3.marpple.co/files/u_193535/2022/1/original/e3c9a52e62c3f71c61d40bda01f2a3bec34618ec1.svg)
              background-size 22px
              background-position 50% 50%
            &.share_j .icon
              background-image url(//s3.marpple.co/files/u_193535/2019/9/original/f_884045_1569314894216_Z8Cyyekvs7Tbv5zr6C5u.png)
              background-size 22px
              background-position 50% 50%
          .circle_icon:active, .circle_icon:hover, .circle_icon.drop
            .icon
              background-color color_active
              box-shadow 0 2px 10px 0 rgba(1, 87, 255, 0.3)
            .text
              color color_active
            &.add_text
            &.add_cv_pure_text
              .icon
                background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14460_1542950695902_ATwL8kOX0TCe6LHLIH.svg)
                background-size 22px
                background-position 50% 50%
            &.add_sticker2
            &.worker_folder
              .icon
                background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14462_1542950763512_a8OHvwW6rTJliD2xk1S.svg)
                background-size 22px
                background-position 50% 50%
            &.my_lib
              .icon
                background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14463_1542950763512_l9wGQcG3hOJbkN0B3s6z.svg)
                background-size 22px
                background-position 50% 50%
            &.add_img
              .icon
                background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14461_1542950695902_zfNG9m5Jf8W9RdfLe7Ol.svg)
                background-size 22px
                background-position 50% 50%
            &.add_sticker_line .icon
            &.add_sticker_custom .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_14462_1542950763512_a8OHvwW6rTJliD2xk1S.svg)
              background-size 22px
              background-position 50% 50%
            &.open_chooser .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_15051_1543293065268_D0FG2p0TUK2m2KWe8tQ1O5m.png)
              background-size 22px
              background-position 50% 50%
            &.open_cv_background_marpplizer .icon
              background-image url(//s3.marpple.co/files/u_193535/2022/1/original/862c950efd6afd90150266f79d1214fc0c0f7cbf2.svg)
              background-size 22px
              background-position 50% 50%
  @media screen and (min-width: 1025px)
    @media screen and (max-height: 600px)
      .mp_maker
        #maker
          .btn_tip
            top 80px !important
        .select_face
          top 80px !important
    .don_frame[frame_name="mp.product.detail"]
      >.don_wrapper >.header
        display none !important
    .mp_maker
      .circle_icon
        margin-bottom 36px
        cursor pointer
        position relative
        &.add_sticker2
          html.creator-settings &
            display none
        &:nth-last-child(1)
          margin-bottom 24px
        .count
          html[my_image_count="0"] &
            opacity 0
          opacity 1
          transition opacity 0.3s
          position: absolute;
          background: #000;
          border-radius 20px
          color: #fff;
          padding: 0 3px;
          height 20px
          line-height 20px
          min-width 14px
          text-align center
          z-index: 104;
          font-size: 10px;
          top: -3px;
          right: 0;
        .icon
          background-color #ffffff
          z-index 100
          position: relative
          background-repeat no-repeat
          border-radius 50%
          border 1px solid transparent
        .text
          position absolute
          left 50%
          box-sizing border-box
          transform translateX(-50%)
          text-align center
          color color_black
          height 21px
          line-height 21px
          width 120px
          html[lang="kr"] &
            font-size 12px
          html[lang="en"] &
            font-size 14px
          html[lang="jp"] &
            font-size 12px
            line-height 32px
      .decoration_menu_for_pc
        .circle_icon
          &.add_sticker_line
          &.share_j
          &.add_sticker_custom
          &.loc_optimization_btn
            display none
    .mp_maker
      .select_face
        right 500px
        top 105px
      .board
        right 460px !important

      .decoration_menu_for_pc
        padding 0
        position absolute
        //right 45px
        right 40px
        top 54%
        margin 0
        transform translateY(-50%)
        z-index 1
        opacity 1
        transition opacity 0.3s

  @media screen and (max-width: 1024px)
    .mp_maker
      #marpplizer
      #marpple_layer
        display none
      .product_menu
        .product_icon
          transition transform 0.3s
          .icon
            background-image url(//s3.marpple.co/file/u_15/2018/7/original/f_5278_1531140102200_P8VO0c7T8s7bR7e9QNa6pWm.svg)
          &:active
            .icon
              background-image url(//s3.marpple.co/files/u_193535/2018/11/original/f_15051_1543293065268_D0FG2p0TUK2m2KWe8tQ1O5m.png)
        .main_icon
          .icon
            background-size 24px
            background-position 11px 11px
          &:active
            .icon
              background-color color_active
            .text
              color color_active
        .zoom_in_1
          bottom 0
          opacity 0
          //display none
          transition opacity 0.1s
          .icon
            background-image url(//s3.marpple.co/files/u_193535/2019/8/original/f_267772_1564670356370_Ht9m5a7kLC9dSiqm2lor.png)
            background-color #fff
          html[zoomed="true"] &
            display block
            opacity 1;
        .zoom_in_1:active, .zoom_in_1:hover
          .icon
            background-color color_active
            background-image url(//s3.marpple.co/files/u_193535/2019/8/original/f_637967_1564970450033_XklQbt8Unzs4MP6w1h6D.png)


@media screen and (min-width: 1025px)
  html.all_faces_mode
    .maker_menu:after
      z-index 2
      opacity 0.85

@media screen and (max-width: 1024px)
  .mp_maker
    .maker_menu
      transition transform 0.5s
  html.maker_page:not(.maker_editor_mode)
    .maker_menu
      opacity 0
      transform translateY(-70px)
  html.maker_editor_mode
    .maker_menu
      opacity 1
      transform translateY(0px)
  html.maker_page.all_faces_mode
    .maker_menu
      transform translateY(-50px) !important
  html.maker_page.maker_menu_just_go_out
    .maker_menu
      transform translateY(-50px) !important



