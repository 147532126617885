.studio-pc-info
  &__bg
    z-index 999
    position fixed
    top 0
    left 0
    bottom 0
    right 0
    background-color rgba(0,0,0,.3)
    display flex
    align-items center
    justify-content center
  &__content
    position relative
    border-radius 6px
    margin 168px 0 0
    &--popup
      margin 0 40px
      padding 30px 28px
      background-color #3c89f9
  &__title
    margin 0 0 24px
    font-size 18px
    font-weight 600
    line-height 1.5
    text-align center
    &--white
      color #fff
  &__img
    img
      width 240px
  &__description
    margin 23px 0 0
    font-weight 500
    line-height 1.36
    text-align center
    &--white
      color #fff
