#don_frame_screen
  top 0
  left 0
  right 0
  bottom 0
  position fixed
  width 100%
  height 100%
  background transparent
  z-index 2147483647
.don_frame
.don_page
.don_tab
  display none
  &[hide_frame_button_type=""]
    >.don_wrapper
      >.header
        .don_hide_frame
          display none

  >.don_wrapper
    position relative
  &.is_show
    display block
.don_frame
.don_page
  >.don_wrapper
    >.header
      user-select none
      -webkit-tap-highlight-color rgba(0,0,0,0)
      *
        -user-select none
        -webkit-tap-highlight-color rgba(0,0,0,0)
      >.don_back_page
        z-index 3
      >.title
        z-index 1
      >.tab_buttons
        z-index 1
      >.don_hide_frame
        z-index 3
.don_frame[frame_index="0"]
  .don_hide_frame
    display none !important
.don_frame
  >.don_wrapper
    height 100%
    >.body
      height 100%
  .don_hide_frame
  .don_back_page
  .tab_button
    padding 0
    margin 0
    border 0
    cursor pointer
  &.no_header[hide_frame_button_type="V"]
  &.no_header[hide_frame_button_type="v"]
    >.don_wrapper
      >.body
        z-index 1 !important
        box-shadow 0 2px 7px 2px #aaaa
      >.header
        z-index 2 !important
        .title
          display none
  &[hide_frame_button_type="V"]
  &[hide_frame_button_type="v"]
    >.don_wrapper >.body
      z-index 2
    >.don_wrapper >.header
      z-index 1
      background transparent
      .title
        box-shadow 0 2px 7px 2px #aaaa
        position absolute
        top 30px
        min-height 30px
        width 100%
        background white
      .don_hide_frame
        transform translateX(-50%)
        left 50%
        position absolute
        width 50px
        height 22px
        text-align center
        border none
        top 9px
        box-shadow: 0 -1px 10px 0px #aaaa
        border-radius 2px 2px 0 0
        background #fff url("//s3.marpple.co/file/u_18053/2018/7/original/f_5654_1532605972210_fSO2BpOAb5g7y7S4Afgr.png") no-repeat 50% 54%
        background-size 10px 6px
        &:focus
          outline none
        span
          color transparent
        span:after
          background #fff
          width 100px
          height 10px
          bottom -9px
          position absolute
          left -24px
          content ""
  .don_page
    >.don_wrapper
      >.header .title
        font-size 16px
        font-weight bold
        color color_black
    &[tab_length="0"]
    &[tab_length="1"]
      >.don_wrapper
        >.header
          .tab_buttons
            display none
    &[page_index="0"]
      >.don_wrapper
        >.header
          .don_back_page
            display none
    >.don_wrapper
      >.header
        position relative
        background #fff
        top 0
.infi_container
  opacity 0
  &.completed
    transition opacity .2s ease-in-out
    opacity 1
  .infi_view_more
    position absolute
    bottom 0
    left 0
    right 0
    &:before
      content ""
      background linear-gradient(to bottom, rgba(0,0,0,0), #fff 95%, #fff)
      display block
      height 200px
    button
      box-sizing border-box !important
      font-weight normal
      font-size 14px
      color #4e4e4e
      border 1px solid #ddd
      border-radius 8px
      background #fff
      padding 0
      margin 0
      width 100%
      height 48px
  .infi_wrapper
    .infi_items
      >div.is_hide
        display none !important
