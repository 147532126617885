.m_number_pad_page
  background #fff
  height 272px
  >.don_wrapper
    >.header
      height 48px
    >.body .number_pad
      max-width 400px
      padding 16px 0
      display flex
      justify-content center
      >.head
        width 80px
        display flex
        flex-direction column
        justify-content center
        margin-right 14%
      .blank, .up, .down
        width 100%
        text-align center
      .up
        background url(//s3.marpple.co/file/guest/2018/1/original/f_1539_1515743166941_LNwoi5gCswkeW5LaL.svg) no-repeat 50% 10px
        &:active
          background-image url(//s3.marpple.co/file/guest/2018/1/original/f_1548_1516264212620_OA5y3c4eNp6nRqq3uMvD.svg)
      .down
        background url(//s3.marpple.co/file/guest/2018/1/original/f_1538_1515743157822_e4XU3mc3l2Dr1AbZ8U4R7E4X.svg) no-repeat 50% 25px
        &:active
          background-image url(//s3.marpple.co/file/guest/2018/1/original/f_1547_1516264207927_KGcBOxnuifd2r0yHN.svg)
      .up, .down
        height 40px
        background-size 10px
      .blank
        height 30px
        line-height 30px
        box-sizing border-box
        border-radius 2px
        border solid 1px #dee2e6
        font-size 14px
        color #343a40
      >.key_pad
        width 168px
        display flex
        flex-wrap wrap
      //.key_box
      //.key_box:nth-child(3n)
      //  margin-right 0
      .key_box
        padding 8px 12px
      .key
        background-color #f5f5f5
        box-sizing border-box
        border 1px solid #f5f5f5
        border-radius 50%
        text-align center
        line-height 32px
        width 32px
        height 32px
        font-size 12px
        color #343a40
      .key_box:active
        .key
          background-color #ced4da
          border solid 1px #ced4da
      .option
        border none
        background #fff
        font-size 12px
        text-align center
        color #343a40
        line-height 32px
        width 32px
        height 32px